import { SET_AVATAR_LOADER } from "../actions/actionTypes";

const initialState = {
    avatarLoader: true,
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SET_AVATAR_LOADER: {
      return { ...state, avatarLoader: payload };
    }
    default:
      return state;
  }
};
