import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const Select = (props) => {
    const { options, onChange, value, inputValue, onInputChange, label, className, id } = props;
    return (
      <Autocomplete
        autoHighlight
        className={className}
        id={id}
        sx={{ width: 300, height: 40, }}
        onChange={onChange}
        options={options}
        value={value}
        inputValue={inputValue}
        onInputChange={onInputChange}
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} label={id}>
            {option.src && <img
              loading="lazy"
              width="20"
              src={option.src}
              alt=""
            />}
            {option.label}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            size='small'
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      />
    );
  }

export default Select;
