import React, { useState } from 'react';
import { ShoppingCartIcon, TrashIcon, CheckCircleIcon, XIcon } from '@heroicons/react/outline';
import { motion } from 'framer-motion';
import Snackbar from '@mui/material/Snackbar';
import Button from 'components/Button';
import HalfCard from "components/HalfCard";
import SizeSelector from './SizeSelector';
import MixPanel from 'appAnalytics/mixPanel';
import { EVENT_ACTIONS, EVENT_CATEGORIES } from 'appAnalytics/eventCategories';
import { IFRAME_ACTIONS } from 'actions/IframeActions';

function Cart({ addToCart, setAddToCart, product, fit, cartDetails = {}, cartLoaderObj, setCartLoaderObj, isFirstTimeUser }) {
    const { variants = [], counterVariants, product_path, product_counter_part, productTitle, productCounterTitle, options = [], productSlug, productCounterPartSlug,
        sellingPrice, price, discount, productCounterPrice, productCounterSellingPrice, productCounterDiscount,
        shoesSlug, shoesVariants = [], shoesTitle, shoesImage, shoesSellingPrice, shoesPrice, shoesDiscount,
    } = product;
    const { normalFit } = fit[product_path] || {};
    const { normalFit: counterNormalFit } = fit[product_counter_part] || {};
    const isMultiOptions = !!variants?.[0]?.option2;
    const showProduct = variants?.length > 0;
    const showCounterProduct = !isMultiOptions && counterVariants?.length > 0;
    const showShoes = shoesVariants?.length > 0;
    // const showAllItemsAdd = !isMultiOptions && variants?.length > 0 && counterVariants?.length > 0;
    const showAllItemsAdd = true;
    const showOnlyMainAction = isMultiOptions || (showProduct && !showCounterProduct);
    const mainActionLabel = showOnlyMainAction ? "Add To Cart" : "Add All Items to Cart";
    const productImage = product?.productImage || '';
    const productCounterImage = product.productCounterImage || '';
    const showProductAllSizes = isFirstTimeUser || !normalFit;
    const showCounterProductAllSizes = isFirstTimeUser || !counterNormalFit;
    const getRecommendedVariants = (variants = [], fitObj = {}, isMultiOptions = false) => {
        const { normalFit, tightFit, looseFit } = fitObj;
        const { normalFit: counterNormalFit, tightFit: counterTightFit, looseFit: counterLooseFit } = fit[product_counter_part];
        let recommendedVariants = { normalFit: [], tightFit: [], looseFit: [] };
        const showAllSizes = isFirstTimeUser || !normalFit;
        if (showAllSizes) {
            recommendedVariants.normalFit = variants.map((variant, index) => {
                return { ...variant, index };
            });
        } else {
            variants?.forEach(variant => {
                if (variant.option1 === normalFit) {
                    const index = recommendedVariants.normalFit.length;
                    if (isMultiOptions) {
                        if ([counterTightFit, counterNormalFit, counterLooseFit].indexOf(variant.option2) > -1) {
                            recommendedVariants.normalFit.push({ ...variant, index });
                        }
                    } else {
                        recommendedVariants.normalFit.push({ ...variant, index });
                    }
                }
                if (variant.option1 === tightFit) {
                    const index = recommendedVariants.tightFit.length;
                    if (isMultiOptions) {
                        if ([counterTightFit, counterNormalFit, counterLooseFit].indexOf(variant.option2) > -1) {
                            recommendedVariants.tightFit.push({ ...variant, index });
                        }
                    } else {
                        recommendedVariants.tightFit.push({ ...variant, index });
                    }
                }
                if (variant.option1 === looseFit) {
                    const index = recommendedVariants.looseFit.length;
                    if (isMultiOptions) {
                        if ([counterTightFit, counterNormalFit, counterLooseFit].indexOf(variant.option2) > -1) {
                            recommendedVariants.looseFit.push({ ...variant, index });
                        }
                    } else {
                        recommendedVariants.looseFit.push({ ...variant, index });
                    }
                }
            });
        }
        return recommendedVariants;
    }
    const getSelectedVariantValues = (cartDetails, variants) => {
        let selectedVariant = null;
        let selectedVariantSize = '';
        let selectedIndex = -1;
        Object.keys(variants).forEach(fittingKey => {
            const cartItem = (cartDetails?.items || []).find(item => {
                selectedIndex = variants[fittingKey].findIndex(variant => variant.id === item.id);
                if (selectedIndex > -1) {
                    selectedVariant = variants[fittingKey][selectedIndex];
                    selectedVariantSize = fittingKey;
                    return true;
                }
            });
            if (cartItem) {
                return true;
            }
        });
        return { selectedVariantSize, selectedIndex };
    }
    const getDefaultVariant = (variants, selectedKey, selectedIndex) => {
        if (selectedIndex > -1) {
            return { ...variants[selectedKey][selectedIndex] };
        }
        let availableIndex = -1;
        if (variants.normalFit?.length > 0) {
            availableIndex = variants.normalFit.findIndex(variant => {
                if (isMultiOptions) {
                    return variant.available && variant.option2 === fit[product_counter_part]['normalFit'];
                } else {
                    return variant.available;
                }
            });
            if (availableIndex > -1) {
                return { ...variants.normalFit[availableIndex], index: availableIndex };
            }
        }
        const variantKey = Object.keys(variants).find(key => {
            let index = variants[key].findIndex(variant => variant.available);
            if (index > -1) {
                availableIndex = index;
                return true;
            };
        });
        return { ...variants?.[variantKey]?.[availableIndex], index: availableIndex };
    }
    const recommendedVariants = getRecommendedVariants(variants, fit[product_path], isMultiOptions);
    const recommendedCounterVariants = getRecommendedVariants(counterVariants, fit[product_counter_part]);
    const recommendedShoesVariants = getRecommendedVariants(shoesVariants, {});
    const [selectedProductSize, setSelectedProductSize] = useState(getDefaultVariant(recommendedVariants));
    const [selectedCounterProductSize, setSelectedCounterProductSize] = useState(getDefaultVariant(recommendedCounterVariants));
    const [selectedShoesSize, setSelectedShoesSize] = useState(getDefaultVariant(recommendedShoesVariants));
    const cartActionHandler = (type) => {
        let newSelectedVariant = selectedProductSize;
        let productName = productTitle;
        switch (type) {
            case 'product':
                newSelectedVariant = selectedProductSize;
                break;
            case 'counterProduct':
                newSelectedVariant = selectedCounterProductSize;
                productName = productCounterTitle;
                break;
            case 'shoes':
                newSelectedVariant = selectedShoesSize;
                productName = shoesTitle;
                break;
            default:
                break;
        }
        setCartLoaderObj({ ...cartLoaderObj, [type]: true, message: '' });
        if (newSelectedVariant?.id && newSelectedVariant?.available) {
            MixPanel.buttonClicked(EVENT_CATEGORIES.CART, EVENT_ACTIONS.ADD_TO_CART,
                {
                    productName,
                    variantId: newSelectedVariant.id,
                    option1: newSelectedVariant.option1,
                    option2: newSelectedVariant.option2,
                });
            // }
            window.parent.postMessage({ message: IFRAME_ACTIONS.ADD_CART_ITEM, value: { id: newSelectedVariant.id, type } }, '*');
        }
    }
    const addAllItems = () => {
        let items = [];
        if (selectedProductSize?.available) {
            items.push({ id: selectedProductSize.id, quantity: 1, type: 'product' });
        }
        if (selectedCounterProductSize?.available) {
            items.push({ id: selectedCounterProductSize.id, quantity: 1, type: 'counterProduct' });
        }
        if(selectedShoesSize?.available) {
            items.push({ id: selectedShoesSize.id, quantity: 1, type: 'shoes' });
        }
        if (items.length > 0) {
            if (items.length === 1) {
                MixPanel.buttonClicked(EVENT_CATEGORIES.CART, EVENT_ACTIONS.ADD_TO_CART,
                    {
                        productName: productTitle,
                        variantId: items[0].id,
                        option1: selectedProductSize.option1,
                        option2: selectedProductSize.option2,
                    });
            } else {
                MixPanel.buttonClicked(EVENT_CATEGORIES.CART, EVENT_ACTIONS.ADD_ALL_ITEMS,
                    {
                        items,
                    }
                );
            }
            setCartLoaderObj({ ...cartLoaderObj, 'allItems': true });
            window.parent.postMessage({ message: IFRAME_ACTIONS.ADD_ALL_ITEMS, value: { items, type: 'allItems' } }, '*');
        }
    }

    const openProduct = (slug) => {
        if (slug) {
            window.parent.postMessage({ message: IFRAME_ACTIONS.OPEN_PRODUCT, value: slug }, '*');
        }
    }

    // const removeAllItems = () => {
    //     let items = [];
    //     if (selectedVariant) {
    //         items.push(selectedVariant);
    //     }
    //     if (selectedCounterVariant) {
    //         items.push(selectedCounterVariant)
    //     }
    //     if (items.length > 0) {
    //         setCartLoaderObj({ ...cartLoaderObj, 'allItems': true });
    //         MixPanel.buttonClicked(EVENT_CATEGORIES.CART, EVENT_ACTIONS.REMOVE_ALL_ITEMS,
    //             {
    //                 productName: productTitle,
    //                 items,
    //             }
    //         );
    //         window.parent.postMessage({ message: 'REMOVE_ALL_ITEMS', value: { items, type: 'allItems' } }, '*');
    //     }
    // }
    return (
        <HalfCard
            visible={addToCart && (variants?.length > 0 || counterVariants?.length > 0 || shoesVariants?.length > 0)}
            key={'addToCart'}
            onClose={() => setAddToCart(false)}
            showCloseButton={true}
            showHeader={true}
            title={"Products"}
            whiteBackground={true}
            closeOnBackgroundClick={true}
        >
            <div className='Dopplr__CartSection'>
                {showProduct && <div className='Dopplr__CartSection__Card'>
                    {productTitle && <div className='Dopplr__CartSection__Card__ProductTitle Dopplr__CartSection__Card__Link' onClick={() => openProduct(productSlug)}>
                        {productTitle}
                    </div>}
                    <div className='Dopplr__CartSection__Card__ProductContent'>
                        <div className={`Dopplr__CartSection__Card__Image ${isMultiOptions ? 'multiOptions' : ''}`}>
                            <img src={productImage} alt="product" />
                        </div>
                        <div style={{ width: '100%' }}>
                            <div className="Dopplr__CartSection__Card__Content">
                                {!isMultiOptions && !showProductAllSizes && <div className='Dopplr__CartSection__Card__RecommendedSize'>Recommended size: <span>{normalFit}</span></div>}
                                <SizeSelector
                                    showAllSizes={showProductAllSizes}
                                    isMultiOptions={isMultiOptions}
                                    options={options}
                                    fit={fit}
                                    productPath={product_path}
                                    productCounterPart={product_counter_part}
                                    selectedProductSize={selectedProductSize}
                                    recommendedVariants={recommendedVariants}
                                    setSelectedProductSize={setSelectedProductSize}
                                />
                            </div>
                            <div className='Dopplr__CartSection__Card__Bottom'>
                                {sellingPrice && <div className='Dopplr__CartSection__Card__Prices'>
                                    <div className='Dopplr__CartSection__Card__SellingPrice'>Rs {sellingPrice}</div>
                                    {(Number(discount) > 0) && <div className='Dopplr__CartSection__Card__DiscountApplied'>
                                        <div className='Dopplr__CartSection__Card__Mrp'>Rs {price}</div>
                                        <div className='Dopplr__CartSection__Card__Discount'>({discount} %off)</div>
                                    </div>}
                                </div>}
                                {!showOnlyMainAction && <div className='Dopplr__CartSection__Card__Action'>
                                    <Button disabled={selectedShoesSize?.index === -1} isLoading={cartLoaderObj?.['product']} type={'black-white'} className={`FloatingButton`} onClick={() => cartActionHandler('product')}>
                                        <div className='Dopplr__CartSection__Card__Action__Content'>
                                            <ShoppingCartIcon style={{ height: '1.2em', width: '1.2em', marginRight: '0.5em' }} />
                                            <span>Add to cart</span>
                                        </div>
                                    </Button>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>}
                {showCounterProduct && <div className='Dopplr__CartSection__Card'>
                    {productCounterTitle && <div className={`Dopplr__CartSection__Card__ProductTitle Dopplr__CartSection__Card__Link`} onClick={() => openProduct(productCounterPartSlug)}>
                        {productCounterTitle}
                    </div>}
                    <div className='Dopplr__CartSection__Card__ProductContent'>
                        <div className={`Dopplr__CartSection__Card__Image ${isMultiOptions ? 'multiOptions' : ''}`}>
                            <img src={productCounterImage} alt="product" />
                        </div>
                        <div style={{ width: '100%' }}>
                            <div className="Dopplr__CartSection__Card__Content">
                                {!showCounterProductAllSizes && <div className='Dopplr__CartSection__Card__RecommendedSize'>Recommended size: <span>{counterNormalFit}</span></div>}
                                <SizeSelector
                                    showAllSizes={showCounterProductAllSizes}
                                    fit={fit}
                                    productPath={product_counter_part}
                                    selectedProductSize={selectedCounterProductSize}
                                    recommendedVariants={recommendedCounterVariants}
                                    productType={'counterProduct'}
                                    setSelectedProductSize={setSelectedCounterProductSize}
                                />
                            </div>
                            <div className='Dopplr__CartSection__Card__Bottom'>
                                {productCounterPrice && <div className='Dopplr__CartSection__Card__Prices'>
                                    <div className='Dopplr__CartSection__Card__SellingPrice'>Rs {productCounterSellingPrice}</div>
                                    {Number(productCounterDiscount) > 0 && <div className='Dopplr__CartSection__Card__DiscountApplied'>
                                        <div className='Dopplr__CartSection__Card__Mrp'>Rs {productCounterPrice}</div>
                                        <div className='Dopplr__CartSection__Card__Discount'>({productCounterDiscount} %off)</div>
                                    </div>}
                                </div>}
                                <div className='Dopplr__CartSection__Card__Action'>
                                    <Button disabled={selectedCounterProductSize?.index === -1} isLoading={cartLoaderObj?.['counterProduct']} type={'black-white'} className={`FloatingButton`} onClick={() => cartActionHandler('counterProduct')}>
                                        <div className='Dopplr__CartSection__Card__Action__Content'>
                                            <ShoppingCartIcon style={{ height: '1.2em', width: '1.2em', marginRight: '0.5em' }} />
                                            <span>Add to cart</span>
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
                {showShoes && <div className='Dopplr__CartSection__Card'>
                    {shoesTitle && <div className='Dopplr__CartSection__Card__ProductTitle Dopplr__CartSection__Card__Link' onClick={() => openProduct(shoesSlug)}>
                        {shoesTitle}
                    </div>}
                    <div className='Dopplr__CartSection__Card__ProductContent'>
                        <div className={`Dopplr__CartSection__Card__Image`}>
                            <img src={shoesImage} alt="product" />
                        </div>
                        <div style={{ width: '100%' }}>
                            <div className="Dopplr__CartSection__Card__Content">
                                <SizeSelector
                                    showAllSizes={true}
                                    isMultiOptions={false}
                                    options={options}
                                    fit={fit}
                                    productPath={product_path}
                                    productCounterPart={product_counter_part}
                                    selectedProductSize={selectedShoesSize}
                                    recommendedVariants={recommendedShoesVariants}
                                    setSelectedProductSize={setSelectedShoesSize}
                                />
                            </div>
                            <div className='Dopplr__CartSection__Card__Bottom'>
                                {shoesSellingPrice && <div className='Dopplr__CartSection__Card__Prices'>
                                    <div className='Dopplr__CartSection__Card__SellingPrice'>Rs {shoesSellingPrice}</div>
                                    {(Number(shoesDiscount) > 0) && <div className='Dopplr__CartSection__Card__DiscountApplied'>
                                        <div className='Dopplr__CartSection__Card__Mrp'>Rs {shoesPrice}</div>
                                        <div className='Dopplr__CartSection__Card__Discount'>({shoesDiscount} %off)</div>
                                    </div>}
                                </div>}
                                {!showOnlyMainAction && <div className='Dopplr__CartSection__Card__Action'>
                                    <Button disabled={selectedProductSize?.index === -1} isLoading={cartLoaderObj?.['shoes']} type={'black-white'} className={`FloatingButton`} onClick={() => cartActionHandler('shoes')}>
                                        <div className='Dopplr__CartSection__Card__Action__Content'>
                                            <ShoppingCartIcon style={{ height: '1.2em', width: '1.2em', marginRight: '0.5em' }} />
                                            <span>Add to cart</span>
                                        </div>
                                    </Button>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>}
                {showAllItemsAdd && <div>
                    <Button isLoading={cartLoaderObj?.['allItems']} type={'black-white'} onClick={() => addAllItems()}>
                        <>
                            <ShoppingCartIcon style={{ height: '1em', width: '1em', marginRight: '0.5em' }} />
                            <span>{mainActionLabel}</span>
                        </>
                    </Button>
                    {/* <div className='Dopplr__CartSection__Action__CartCountContainer'>
                        <Button type="white-black" className='Dopplr__CartSection__Action__CartIcon'>
                            <ShoppingCartIcon style={{ width: '1.6em' }} />
                        </Button>
                        {itemCount > 0 && <div className='Dopplr__CartSection__Action__CartCount'>
                            {itemCount}
                        </div>}
                    </div> */}
                </div>}
                <Snackbar
                    key={"message"}
                    // anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={!!cartLoaderObj?.message}
                    autoHideDuration={1500}
                    onClose={() => setCartLoaderObj({ ...cartLoaderObj, message: '' })}
                    TransitionProps={{ onExited: () => setCartLoaderObj({ ...cartLoaderObj, message: '' }) }}
                    message={cartLoaderObj?.message || ''}
                    sx={{ bottom: 60, right: '1em', left: 'auto', fontSize: '0.8em' }}
                />
            </div>
        </HalfCard>
    )
}

export default Cart;
