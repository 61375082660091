import React, { useState, useEffect } from "react";
import "./IframeSection.scss";
import DopplrPlugin from "DopplrScript";
import { motion } from 'framer-motion';
import { isTouchFriendly } from "utils";
import isEmpty from 'lodash/isEmpty';
import { ChevronDoubleLeftIcon } from '@heroicons/react/outline';
import { IFRAME_ACTIONS } from "actions/IframeActions";

const IframeSection = (props) => {
  const [showPlugin, togglePluginView] = useState(undefined);
  const [dopplrPlugin, setDopplrPlugin] = useState(null);
  const baseUrl = process.env.REACT_APP_DEBUG_MODE === 'true' ? "http://localhost:3000" : `${window.location.origin}`;
  const onLoad = () => {
    const frame = document.getElementById("myFrame");
    frame.contentWindow.postMessage(
      { message: "getAppData", value: props.selectedProduct },
      `${window.location.origin}/dopplr-avatar`
    );
  }
  const recieveMessage = (event) => {
    const { message } = event?.data || {};
    switch (message) {
      case IFRAME_ACTIONS.CLOSE_IFRAME: {
        togglePlugin(false);
        break;
      }
      default: break;
    }
  }

  useEffect(() => {
    if (!isEmpty(props.selectedProduct)) {
      if (!dopplrPlugin) {
        const { company_id, sku, brand } = props.selectedProduct
        const data = company_id && sku ? { company_id, sku } : props.selectedProduct;
        const options = {
          productData: data,
          domain: brand,
          productList: props.productList,
        }
        const dopplrPlugin = new DopplrPlugin(`${baseUrl}/dopplr-plugin/`, '#dopplr-wrapper', options);
        setDopplrPlugin(dopplrPlugin);
      } else {
        dopplrPlugin.sendData(props.selectedProduct, props.productList);
      }
      window.addEventListener("message", recieveMessage, false)
    } else {
    }
  }, [props.selectedProduct]);

  const togglePlugin = (val) => {
    togglePluginView(val);
    dopplrPlugin?.togglePluginView(val);
  }

  return (
    <>
      <div
        className={`IframeSection ${showPlugin && showPlugin === true
          ? "show"
          : showPlugin === undefined
            ? ""
            : "hide"
          } ${isTouchFriendly ? "mobile" : ''}`}
      >
        <div id="dopplr-wrapper">
          {/* {isTouchFriendly && <motion.div
            animate={showPlugin ? "open" : "closed"}
            variants={{
              open: { display: 'block', x: '-50%', y: 0, transition: { duration: 0.5 } },
              closed: { display: 'none', x: '-200%', y: 0, transition: { duration: 0.5 } }
            }}
            className="Dopplr__DoubleArrowLeft"
            onClick={() => togglePluginView(!showPlugin)}
          >

            <ChevronDoubleLeftIcon className={`${showPlugin ? 'show' : ''}`} style={{ width: '60%' }} />
          </motion.div>} */}
        </div>
        {/* {!isEmpty(props.selectedProduct) && <AppModule selectedProduct={props.selectedProduct} showHeader={isTouchFriendly} onClose={() => togglePluginView(!showPlugin)} />} */}
        {!isTouchFriendly && <button
          className="DopplrPluginButton"
          onClick={() => togglePlugin(!showPlugin)}
        >
          <div style={{ position: "relative", top: "-10px" }}>
            {showPlugin ? "Hide" : "Show"}
          </div>
        </button>}
      </div>
      {isTouchFriendly && !showPlugin && <button
        className="DopplrPluginButtonMobile"
        onClick={() => togglePlugin(!showPlugin)}
      >
        <div>
          {showPlugin ? "Hide" : "Show"}
        </div>
      </button>}
    </>
  );
};

export default IframeSection;
