import React, { useEffect, useState } from 'react';
import Button from 'components/Button';
import FloatingButtonBar from 'components/FloatingButtonBar';
import { Chip, List, ListItem, ListItemButton, ListItemText, Stack } from '@mui/material';
import BackButton from 'components/BackButton';
import { ACTION_TYPES, MEASUREMENTS_TYPE } from 'utils/constants';
import { getDefaultMeasurement } from 'utils/babylonUtils';
import BottomBar from 'components/BottomBar/BottomBar';
import { EVENT_ACTIONS, EVENT_CATEGORIES } from 'appAnalytics/eventCategories';
import MixPanel from "appAnalytics/mixPanel";

const STEP = {
    PICK_BRAND: 1,
    PICK_SIZE: 2,
}

function BrandMeasurement(props) {
    const [step, setStep] = useState(1);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [sizeIndex, setSizeIndex] = useState(0);
    const { setActiveStepIndex, relatedBrands = {}, garmentType, gender, loader = false, actionType = ACTION_TYPES.NONE } = props;
    useEffect(() => {
        if (selectedBrand) {
            // const index = selectedBrand[garmentType].findIndex(brand => brand.);
            setSizeIndex(0);
        }
    }, [selectedBrand]);

    const onBrandSelect = (brandObj) => {
        setSelectedBrand(brandObj);
        MixPanel.buttonClicked(EVENT_CATEGORIES.PICK_BRAND, EVENT_ACTIONS.SELECTED_BRAND, { selectedBrand: brandObj, actionType });
    }

    const backHandler = () => {
        if (step === STEP.PICK_SIZE) {
            setSelectedBrand(selectedBrand);
            setStep(STEP.PICK_BRAND);
            setActiveStepIndex(1);
            MixPanel.buttonClicked(EVENT_CATEGORIES.PICK_SIZE, EVENT_ACTIONS.BACK_BUTTON, { actionType });
        } else {
            MixPanel.buttonClicked(EVENT_CATEGORIES.PICK_BRAND, EVENT_ACTIONS.BACK_BUTTON, { actionType });
            props.backHandler();
        }
    }

    const nextHandler = () => {
        if (step === STEP.PICK_BRAND) {
            if (!selectedBrand) {
                return;
            }
            setActiveStepIndex(2);
            // props.selectBrand(selectedBrand);
            MixPanel.buttonClicked(EVENT_CATEGORIES.PICK_BRAND, EVENT_ACTIONS.PICK_BRAND_NEXT, { selectedBrand, actionType });
            setStep(STEP.PICK_SIZE);
        } else {
            const { chest, waist, hip } = selectedBrand;
            const selectedSize = selectedBrand[garmentType][sizeIndex];
            const { height, weight } = getDefaultMeasurement(gender)
            const payload = {
                chest: chest[sizeIndex],
                waist: waist[sizeIndex],
                hip: hip[sizeIndex],
                brandName: selectedBrand?.name || '',
                bodySize: selectedSize,
                height,
                weight,
                measurementType: MEASUREMENTS_TYPE.BRAND,
            }
            MixPanel.buttonClicked(EVENT_CATEGORIES.PICK_SIZE, EVENT_ACTIONS.BRAND_MEASUREMENTS, { measurements: {...payload, measurementType: EVENT_ACTIONS.BRAND_MEASUREMENTS}, actionType });
            props.nextHandler(payload, MEASUREMENTS_TYPE.BRAND);
        }
    }

    const renderBrandSelections = () => {
        if (step === STEP.PICK_BRAND) {
            const genderRelatedBrands = relatedBrands?.[gender] || [];
            return <div>
                {/* <div>Tell us your</div> */}
                <div className='Measurements__Heading'>Pick a brand you wear</div>
                <List className='Measurements__List' sx={{ width: '100%', bgcolor: 'background.paper', paddingBottom: '5em', maxHeight: '300px', overflowY: 'scroll' }}>
                    {genderRelatedBrands.map((brandObj) => {
                        const { name } = brandObj;
                        return <ListItemButton
                            sx={{
                                borderBottom: '1px solid #CCCCCC',
                                '&.Mui-selected': {
                                    backgroundColor: '#333333',
                                    color: '#fff',
                                    '&:hover': {
                                        backgroundColor: '#333333',
                                        color: '#fff',
                                    }
                                }
                            }}
                            selected={selectedBrand?.name === brandObj.name}
                            alignItems="flex-start" onClick={() => onBrandSelect(brandObj)}>
                            <ListItemText primary={name} sx={{ paddingLeft: '0.5em' }} />
                        </ListItemButton>
                    })}
                </List>
            </div>
        } else {
            const sizes = selectedBrand[garmentType] || [];
            return <div>
                <div style={{ padding: '1em' }}>
                    {selectedBrand.name !== 'Others' && <div className='Dopplr_Customize__Content'>Great choice, you wear {selectedBrand?.name || ''}</div>}
                    <div className='Dopplr_Customize__Heading'>Select the size that fits you well</div>
                </div>
                <div className='Measurements__SizeContainer'>
                    {sizes.length > 0 && <div className='Measurements__SizeItem'>
                        {/* <div className='Measurements__SizeLabel Dopplr_Customize__Heading'>Top</div> */}
                        <Stack direction="row" spacing={1}>
                            {sizes.map((size, index) => {
                                return <Chip
                                    key={size}
                                    label={size}
                                    size="small"
                                    onClick={() => setSizeIndex(index)}
                                    variant={"outlined"}
                                    sx={{
                                        color: sizeIndex === index ? '#fff' : '#000',
                                        height: '45px',
                                        minWidth: '40px',
                                        maxWidth: '40px',
                                        fontSize: '0.8em',
                                        backgroundColor: sizeIndex === index ? '#333333' : 'white',
                                        '&:hover': {
                                            backgroundColor: sizeIndex === index ? '#333333 !important' : 'white !important',
                                            color: sizeIndex === index ? '#fff !important' : '#000 !important',
                                        }
                                    }} />
                            })}
                        </Stack>
                    </div>}
                </div>
            </div>
        }
    }
    return (
        <>
            <div style={{ padding: '0.5em 1em', zIndex: 1 }}>
                <BackButton onClick={() => backHandler()} />
            </div>
            <div className='Dopplr_Customize__Title'>
                <div className='Dopplr_Customize__Heading'>Don't know your</div>
                <div className='Dopplr_Customize__Heading'>Measurements?</div>
                <div className='Dopplr_Customize__Text'>No problem, we've got you!</div>
            </div>
            <BottomBar keyName={step} className='Measurements'>
                {renderBrandSelections()}
            </BottomBar>
            {<FloatingButtonBar style={{ background: 'none' }}>
                <Button type='black-white' onClick={nextHandler} disabled={!selectedBrand} isLoading={loader}>
                    Next
                </Button>
            </FloatingButtonBar>}
        </>
    )
}

export default BrandMeasurement
