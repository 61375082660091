import React from 'react'
import { XIcon } from '@heroicons/react/outline';
import NoSkybox from "assets/icons/none.svg";
import './SkyboxListComponent.scss';
import MixPanel from 'appAnalytics/mixPanel';
import { EVENT_ACTIONS, EVENT_CATEGORIES } from 'appAnalytics/eventCategories';
import { useHorizontalScroll } from 'customHooks';


const SkyboxListComponent = ({showEnvironments, toggleBackground, skyboxList, selectedSkybox, setSelectedSkybox, eventCategory}) => {
    const scrollRef = useHorizontalScroll();
    return (
        <div className={`Dopplr__SkyboxWrapper${showEnvironments ? ' show' : ''}`}>
            <div className='Dopplr__SkyboxWrapper__ToggleButton' onClick={() => {
                MixPanel.buttonClicked(eventCategory, EVENT_ACTIONS.HIDE_BACKGROUND);
                toggleBackground(!showEnvironments)
            }}>
                <XIcon className={`${showEnvironments ? 'show' : ''}`} style={{ width: '60%' }} />
            </div>
            <div className='Dopplr__SkyboxWrapper__Title'>Let's see how you look in different settings</div>
            <div ref={scrollRef} className='Dopplr__SkyboxWrapper__List'>
                {skyboxList.map(skybox => {
                    const { thumbnailImage, id } = skybox;
                    const selected = selectedSkybox && id === selectedSkybox.id;
                    return <div key={id} className={`Dopplr__SkyboxWrapper__List__Skybox__Item ${selected ? ' selected' : 'overlay'}`} onClick={() => {
                        MixPanel.buttonClicked(eventCategory, EVENT_ACTIONS.BACKGROUND_CHANGE, { skybox });
                        setSelectedSkybox(skybox)
                    }}>
                        <img className='Dopplr__SkyboxWrapper__List__Skybox__Thumbnail' src={thumbnailImage ? thumbnailImage : NoSkybox} alt="" />
                    </div>
                })}
            </div>
        </div>
    )
}

export default SkyboxListComponent