import React, { useContext, useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actionsCreator } from "redux/actions/actionsCreator";
import { auth, googleProvider, facebookProvider, twitterProvider } from "../firebase";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { proxyAPI, userServiceAPI } from "api";
import { v4 as uuidv4 } from 'uuid';
import { useParams } from "react-router-dom";
import { IFRAME_ACTIONS } from "actions/IframeActions";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}
const mapStateToProps = ({ avatarReducer = {} }) => ({
  loadedScene: get(avatarReducer, 'loadedScene'),
});
export function AuthProvider({ children, anonymousLogin, localRoute }) {
  // const [currentUser, setCurrentUser] = useState();
  // const location = useLocation();
  const { loadedScene } = useSelector(
    mapStateToProps
  );
  let { domain } = useParams();
  const allowGuestLoginRef = useRef();
  const unsubscribeRef = useRef();
  allowGuestLoginRef.current = anonymousLogin;
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function signInWithRedirectGoogle() {
    return auth.signInWithRedirect(googleProvider);
  }

  function signInWithGoogle() {
    return auth.signInWithPopup(googleProvider);
  }
  function signInWithFacebook() {
    return auth.signInWithPopup(facebookProvider);
  }
  function signInWithTwitter() {
    return auth.signInWithPopup(twitterProvider);
  }

  function logout() {
    allowGuestLoginRef.current = false;
    window.parent.postMessage({ message: IFRAME_ACTIONS.SET_USER_DATA }, '*');
    return auth.signOut();
  }

  function signInWithCustomToken(token) {
    return auth.signInWithCustomToken(token);
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  // function updateEmail(email) {
  //   return currentUser.updateEmail(email);
  // }

  // function updatePassword(password) {
  //   return currentUser.updatePassword(password);
  // }

  const setAuthCookie = async token => {
    dispatch(actionsCreator.SET_TOKEN(`Bearer ${token}`))
    // await proxyAPI.setAuthCookie({ token });
  };
  const setUID = async uid => {
    dispatch(actionsCreator.SET_UID(uid))
    // await proxyAPI.setAuthCookie({ token });
  };

  const setSession = session => {
    dispatch(actionsCreator.SET_SESSION(session));
  }

  function getProvider(providerId) {
    switch (providerId) {
      case googleProvider.PROVIDER_ID:
        return googleProvider;
      case facebookProvider.PROVIDER_ID:
        return facebookProvider;
      default:
        throw new Error(`No provider implemented for ${providerId}`);
    }
  }

  const supportedPopupSignInMethods = () => {
    const supportedPopupSignInMethods = [
      googleProvider.PROVIDER_ID,
      facebookProvider.PROVIDER_ID,
    ];
  }

  // const linkProvider = async (email, credential) => {
  //   try {
  //     const providers = await auth.fetchProvidersForEmail(email);
  //     const providerId = providers.find(p => supportedPopupSignInMethods.includes(p));
  //     if (!providerId) {
  //       throw new Error(`Your account is linked to a provider that isn't supported.`);
  //     }
  //     const linkedProvider = getProvider(providerId);
  //     const response = auth.signInWithPopup(linkedProvider);
  //     response.user.linkWithCredential(credential);
  //   } catch(e) {
  //     throw e;
  //   }
  // }

  const getUserData = async ({ uat, isAnonymous, uid }) => {
    try {
      const response = await userServiceAPI.getAvatarData({ Authorization: `Bearer ${uat}` });
      let userData = response.data;
      let imageUrl = get(userData, 'userProfile.userAvatar.imageUrl', '');
      loadedScene && loadedScene.dispose();
      dispatch(actionsCreator.SET_LOADED_SCENE(null));
      dispatch(actionsCreator.SET_AVATAR_DATA(userData));
      dispatch(actionsCreator.SET_AVATAR_IMAGE(imageUrl));
      dispatch(actionsCreator.SET_API_IMAGE(imageUrl));
      dispatch(actionsCreator.SET_IS_ANONYMOUS(isAnonymous));
      const aid = isEmpty(userData?.userProfile?.userAvatar) ? '' : userData?.userProfile?.id;
      window.parent.postMessage({ message: IFRAME_ACTIONS.SET_USER_DATA, value: { uat, isAnonymous, uid, aid } }, '*');
      setLoading(false);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const authStateHandler = (userData) => {
    let { uat = '', isAnonymous, uid = '', oldUID = '', aid = '', session = '' } = userData || {};
    let providerId = 'login';
    if (!uid) {
      uid = `DopplrGuest-${uuidv4()}`;
      providerId = 'anonymous';
    }
    isAnonymous = !uid || (uid && uid.includes('DopplrGuest'));
    unsubscribeRef.current = auth.onAuthStateChanged(async (user) => {
      const isAccessTokenValid = (uid && uid === user?.uid) || localRoute;
      if (!isEmpty(user) && isAccessTokenValid) {
        user.getIdToken(true).then(async token => {
          // console.log("firebase auth flow");
          setAuthCookie(token);
          if (localRoute) {
            uid = user.uid;
            setUID(user.uid);
          } else {
            setUID(uid);
          }
          session && setSession(session);
          // setCurrentUser(user);
          const isOldUserAnonymous = oldUID && oldUID.includes('DopplrGuest');
          if (isOldUserAnonymous && aid) {
            try {
              await userServiceAPI.transerAvatar({ anonymousUserId: aid });
            } catch (e) {
              console.error(e);
              getUserData({ uat: token, isAnonymous: isEmpty(userData) ? user.isAnonymous : isAnonymous, uid });
            }
          }
          getUserData({ uat: token, isAnonymous: isEmpty(userData) ? user.isAnonymous : isAnonymous, uid });
        });
      } else if (isAccessTokenValid && allowGuestLoginRef.current) {
        // console.log("local storage flow");
        setAuthCookie(uat);
        setUID(uid);
        session && setSession(session);
        getUserData({ uat, isAnonymous, uid });
      } else {
        if (allowGuestLoginRef.current) {
          //create token and save in localStorage;
          // await loginAnonymously();
          try {
            const response = await proxyAPI.createToken({ uid, brand: domain, providerId });
            if (response.data) {
              await signInWithCustomToken(response.data);
              // console.log("Custom token created", response.data);
              return;
              // const user = userResponse?.user
              // user?.getIdToken(true).then(async token => {
              //   setAuthCookie(token);
              //   getUserData(token, true, uid);
              // });
            }
          } catch (e) {
            console.log("Error in creating custom token", e);
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      }
    });
  };

  const recieveMessage = (event) => {
    const { message, value } = event.data || {};
    switch (message) {
      case 'USER_DATA': {
        let { uat = '', uid = '', oldUID = '', aid = '', session = '' } = JSON.parse(value || '{}');
        const isAnonymous = !uid || (uid && uid.includes('DopplrGuest'));
        authStateHandler({ uat, uid, isAnonymous, domain, oldUID, aid, session });
        break;
      }
      default: {
        break;
      }
    }
  }

  useEffect(() => {
    if (localRoute) {
      authStateHandler();
    } else {
      window.parent.postMessage({ message: IFRAME_ACTIONS.IFRAME_LOADED }, "*");
      window.addEventListener("message", recieveMessage, false);
    }
    return () => {
      window.removeEventListener("message", recieveMessage);
    }
  }, []);

  const value = {
    // currentUser,
    login,
    signup,
    logout,
    resetPassword,
    // updateEmail,
    // updatePassword,
    signInWithGoogle,
    signInWithRedirectGoogle,
    signInWithFacebook,
    signInWithTwitter,
    signInWithCustomToken,
    // linkProvider,
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
