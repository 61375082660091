export const EVENT_CATEGORIES = {
    DEFAULT_AVATAR: "Default_Avatar",
    PERSONAL_AVATAR: "Personal_Avatar",
    PERSONAL_AVATAR_FACE: "Personal_Avatar_Face", 
    AVATAAR_CREATION: "Avatar_Creation",
    HEATMAP: "Heatmap",
    CART: "Cart",
    TAKE_PHOTO: "Take_Photo",
    PICK_FACE: "Pick_Face",
    HEIGHT_WEIGHT: "Height_Weight_Measurements",
    OTHER_MEASUREMENTS: "Other_Measurements",
    BACKGROUND: "Select_Background",
    CAMERA_MODE: "Select_Camera_Mode",
    HAIR: "Select_Hair",
    VIEW_MODE: "View_Mode",
    PICK_BRAND: "Pick_Brand",
    SELECT_SIZE: "Select_Size",
    VIEW_FIT: "View_Fit",
    VIEW_3D: "View_In_3d",
    CLOSE_3D: "Close_3d_View",
    LIKE_YOUR_SELFIE: "Like_Your_Selfie",
    SHAPE_SELECTION: "Select_Body_Shape",
    AVATAR_SUCCESSFULLY_CREATED: "Avatar_Successfully_Created",
    ASSET_VIEWER: "ASSET_VIEWER",
    SANDBOX: "SANDBOX",
}

export const EVENT_ACTIONS = {
    CUSTOMIZE: "Customize",
    VIEW_3D: "Vieweing_3D_model",
    ZOOM: "Fabric_Viewed",
    MEASUREMENTS_SENT: "Measurements_Sent",
    SELECT_FROM_SAMPLES: "Select_From_Samples",
    SAMPLE_PHOTO_SELECTED: "Use_Sample_Photo",
    PHOTO_UPLOADED: "Photo_Uploaded",
    PHOTO_SELECTED: "Use_Uploaded_Photo",
    PREDICTED_MEASUREMENTS: "Predicted_Measurements",
    CUSTOMER_MEASUREMENTS: "Customer_Measurements",
    BRAND_MEASUREMENTS: "Brand_Measurements",
    TUNE_AVATAR_MEASUREMENTS: "Shape_Selection_Measurements",
    PERSONALISE_OPTION_CLOSED: "Personalise Options Closed",
    PERSONALISE_OPTION_OPENED: "Personalise Options Opened",
    PERSONALISE_SHOW: "Personalise_Shown",
    PERSONALISE_HIDE: "Personalise_Closed",
    HEATMAP_SHOW: "HeatMap_Shown",
    HEATMAP_HIDE: "HeatMap_Closed",
    PRODUCT_VIEWED: "Product_Viewed",
    ROTATE: "Rotate",
    RESET: "Reset",
    BACKGROUND_CHANGE: "Background_Change",
    HAIRSTYLE_CHANGE: "Hairstyle_Change",
    CART_OPENED: "Cart_Opened",
    CART_CLOSED: "Cart_Closed",
    ADD_TO_CART: "Add_To_Cart",
    REMOVE_CART_ITEM: "Remove_Cart_Item",
    ADD_ALL_ITEMS: "Add_All_Items",
    REMOVE_ALL_ITEMS: "Remove_all_items",
    CART_ITEM_SIZE_CHANGED: "Cart_Item_Size_Changed",
    SIZE_CHANGED: "Size_Changed",
    KNOW_YOUR_SIZE: "Know_Your_Size",
    TRY_IN_3D: "Try_In_3d",
    COMPLETE_THE_LOOK: "Complete_The_Look",
    PRODUCT_CLOSED: "Product_Closed",
    UPLOAD_SELFIE: "Upload_Selfie",
    RETAKE_SELFIE: "Retake_Selfie",
    BACK_BUTTON: "Back_Button",
    MEASUREMENTS_NEXT: "Measurements_Next",
    PICK_BRAND_NEXT: "Pick_Brand_Next",
    SKIP: "Skip",
    SELECTED_BRAND: "Selected_Brand",
    SHOW_BACKGROUND: "Show_Background",
    HIDE_BACKGROUND: "Hide_Background",
    SHOW_HAIRSTYLES: "Show_Hairstyles",
    HIDE_HAIRSTYLES: "Hide_HairStyles",
    VIEW_MODE_ON: "View_Mode_On",
    VIEW_MODE_OFF: "View_Mode_Off",
    FACE_EDIT: "Face_Edit",
    MEASUREMENTS_EDIT: "Measurements_Edit",
    HEATMAP_PRODUCT_TOGGLE: "Heatmap_Product_toggle",
    HEATMAP_SIZE_CHANGED: "Heatmap_Size_Changed",
    SHOW_CAMERA_MODE: "Show_Camera_Mode",
    HIDE_CAMERA_MODE: "Hide_Camera_Mode",
    CAMERA_MODE_CHANGE: "Camera_Mode_Changed",
    SELECT_SHAPE: "Shape_Selected",
    CATALOGLIST_OPENED: "CatalogList_Opened",
    CATALOGLIST_CLOSED: "CatalogList_Closed",
    NEW_PRODUCT_SELECTED: "New_Product_Selected",
    FEED_MEASUREMENTS: "Feed_Measurements",
}