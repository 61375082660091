import React, { useRef, useEffect, useState } from "react";
import { Scene, Engine } from "@babylonjs/core";
import Loader from "components/Loader";


export const SceneComponent = props => {
  const reactCanvas = useRef(null);
  const {
    antialias,
    engineOptions,
    adaptToDeviceRatio = true,
    sceneOptions,
    onRender,
    onSceneReady,
    setLoader,
    loader,
    skyBoxLoader,
    brand,
    ...rest
  } = props;

  class CustomLoadingScreen {
    displayLoadingUI = () => {
      setLoader(true);
    };
    hideLoadingUI = () => {
      setLoader(false);
    }
  }
  const preventParentScoll = evt => {
    evt.preventDefault();
  }

  useEffect(() => {
    var engine = new Engine(
      reactCanvas.current,
      true,
      engineOptions,
      adaptToDeviceRatio
    );
    engine.useReverseDepthBuffer = true;
    engine.loadingScreen = new CustomLoadingScreen()
    engine.compatibilityMode = false;

    var scene = new Scene(engine, sceneOptions);
    if (scene.isReady()) {
      onSceneReady(scene);
    } else {
      scene.onReadyObservable.addOnce(scene => props.onSceneReady(scene));
    }
    engine.runRenderLoop(() => {
      if (typeof onRender == "function") {
        onRender(scene);
      }
      scene.render();
    });
    const resizeCanvas = () => {
      scene.getEngine().resize();
    };
    if (window) {
      window.addEventListener("resize", resizeCanvas);
    }
    reactCanvas?.current?.addEventListener('wheel', preventParentScoll);
    return () => {
      reactCanvas?.current?.removeEventListener('wheel', preventParentScoll)
      scene.getEngine().dispose();
      if (window) {
        window.removeEventListener("resize", resizeCanvas);
      }
    };
  }, []);

  return (
    <div style={{ display: "block", width: "100%", height: "100%", overflow: "hidden" }}>
      {(loader || skyBoxLoader) && <Loader progress={props.progress} brand={brand} />}
      <canvas ref={reactCanvas} {...rest}></canvas>
    </div>
  );
};

export default SceneComponent;
