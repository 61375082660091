import React, { useState } from "react";
import './InstructionImages.scss';
import BlurryPhotos from 'assets/icons/instructions/BlurryPhotos.svg';
import Glasses from 'assets/icons/instructions/Glasses.svg';
import HairOnFace from 'assets/icons/instructions/HairOnFace.svg';
import Hats from 'assets/icons/instructions/Hats.svg';
import Headsets from 'assets/icons/instructions/Headsets.svg';
import Laugh from 'assets/icons/instructions/Laugh.svg';
import ShadowsOnFace from 'assets/icons/instructions/ShadowsOnFace.svg';
import TiltedFace from 'assets/icons/instructions/TiltedFace.svg';

const Instructions = [
    {
        src: BlurryPhotos,
        text: 'Blurry Photos'
    },
    {
        src: ShadowsOnFace,
        text: 'Shadows on Face',
    },
    {
        src: TiltedFace,
        text: 'Tilted Face',
    },
    {
        src: HairOnFace,
        text: 'Hair on Face',
    },
    {
        src: Laugh,
        text: 'Laugh',
    },
    {
        src: Hats,
        text: 'Hats',
    },
    {
        src: Glasses,
        text: 'Glasses',
    },
    {
        src: Headsets,
        text: 'Headsets',
    },
]
const InstructionImages = () => {
    return (
        <>
        <div className="Dopplr_InstructionImages__Title">DONT</div>
        <div className={`Dopplr_InstructionImages`}>
            {Instructions.map((instruction, index) => {
                const { src, text } = instruction;
                return <div key={index} className="Dopplr_InstructionImages__Image">
                    <img src={src} alt={text} />
                    <div className="Dopplr_InstructionImages__Text">{text}</div>
                </div>
            })
            }
        </div>
        </>
    );
};
export default InstructionImages;
