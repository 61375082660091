import React from 'react'
import './BubbleLoader.scss';

const BubbleLoader = ({ color }) => {
    return (
        <div className="Dopplr__BubbleLoader">
            <span className="Bubble Dopplr__BubbleLoader__Bubble1" style={{ background: color }}></span>
            <span className="Bubble Dopplr__BubbleLoader__Bubble2" style={{ background: color }}></span>
            <span className="Bubble Dopplr__BubbleLoader__Bubble3" style={{ background: color }}></span>
        </div>
    )
}

export default BubbleLoader;
