import * as BABYLON from "@babylonjs/core/Legacy/legacy";
import Hair_EmissiveImage_Female from 'assets/images/Hair_Emission.jpeg';
import Hair_OpacityImage_Female from 'assets/images/Hair_Opacity.jpeg';

export const getXCoordinate = (vertexID) => { return vertexID * 3 }

export const getYCoordinate = (vertexID) => { return vertexID * 3 + 1 }

export const getZCoordinate = (vertexID) => { return vertexID * 3 + 2 }

export const getCoordinate = (vertexID) => {

    const x = getXCoordinate(vertexID);
    const y = getYCoordinate(vertexID);
    const z = getZCoordinate(vertexID);
    return { x, y, z }
}

export const getMidPoint = (indices, vertices) => {
    let midBase = new BABYLON.Vector3(0, 0, 0);
    let vertexID = 0
    const indicesLen = indices.length;
    for (var index = 0; index < indicesLen; index++) {
        vertexID = indices[index];
        const { x, y, z } = getCoordinate(vertexID);
        midBase.x += vertices[x];
        midBase.y += vertices[y];
        midBase.z += vertices[z];
    }
    midBase.x = midBase.x / indicesLen;
    midBase.y = midBase.y / indicesLen;
    midBase.z = midBase.z / indicesLen;
    return midBase;
}
export const changeHairProperties = (scene, hairMesh, hairColorHexString, gender) => {
    if (gender === "female") {
        let newHairMat = new BABYLON.PBRMaterial("newHairMat", scene);
        newHairMat.metallic = 0;
        newHairMat.roughness = 0.70;
        newHairMat.indexOfRefraction = 1.17;
        newHairMat.metallicF0Factor = 0.34;
        newHairMat.albedoColor = BABYLON.Color3.FromHexString("#000000").toLinearSpace();

        newHairMat.emissiveTexture = new BABYLON.Texture(Hair_EmissiveImage_Female, scene, false, false);
        newHairMat.emissiveColor = BABYLON.Color3.FromHexString(hairColorHexString).toLinearSpace();


        const opacityTexture = new BABYLON.Texture(Hair_OpacityImage_Female, scene, false, false);
        opacityTexture.getAlphaFromRGB = true;
        newHairMat.albedoTexture = null;
        newHairMat.opacityTexture = opacityTexture;

        newHairMat.backFaceCulling = false;

        hairMesh.material = newHairMat;
    } else {
        let newHairMat = hairMesh.material; //new BABYLON.PBRMaterial("newHairMat", scene);
        newHairMat.metallic = 0;
        newHairMat.roughness = 0.70;
        newHairMat.indexOfRefraction = 1.17;
        newHairMat.metallicF0Factor = 0.34;
        newHairMat.albedoColor = BABYLON.Color3.FromHexString(hairColorHexString).toLinearSpace();

        newHairMat.transparencyMode = null; // Not Defined -> null, Opaque -> 0, Alpha Test -> 1, Alpha Blend -> 2
        newHairMat.albedoTexture.hasAlpha = true;
        newHairMat.useAlphaFromAlbedoTexture = true;

        newHairMat.backFaceCulling = false;

        hairMesh.material = newHairMat;
    }
}


export const getDefaultMeasurement = (productGender) => {
    /* To set default measurements if user has not added any measurements.*/
    const defaultPayload = {
        "female": {
            unit: "cm",
            gender: "female",
            userAvatar: {
                chest: 92,
                hip: 99,
                waist: 73,
                highHip: 73,
            },
            weight: 60,
            height: 165,
            defaultAvatar: true,
        },
        'male': {
            unit: "cm",
            gender: "male",
            userAvatar: {
                chest: 103,
                hip: 102,
                highHip: 92,
                waist: 92
            },
            weight: 69,
            height: 178,
            defaultAvatar: true,
        }
    };
    return defaultPayload[productGender];
};

export const getVertexPositionFromID = (vertexID, verticesPositionsArray) => {
    let x = verticesPositionsArray[vertexID * 3]
    let y = verticesPositionsArray[vertexID * 3 + 1]
    let z = verticesPositionsArray[vertexID * 3 + 2]
    let vertex = new BABYLON.Vector3(-x, y, z);
    return vertex
}
