import requestMaker from "lib";

export const createAvatar = formData => {
  const baseQuery = `/proxy/ast/ca`;
  return requestMaker(baseQuery, formData, {
    "Content-type": "multipart/form-data"
  });
};

export const uploadQRImage = formData => {
  const baseQuery = `/proxy/ast/uqi`;
  return requestMaker(baseQuery, formData, {
    "Content-type": "multipart/form-data"
  });
};

export const getAvatarData = (headers = {}) => {
  const baseQuery = `/proxy/prv/us/gad`;
  return requestMaker(baseQuery, { params: {}, payload: {} }, headers);
};
export const updateUserData = ({ payload }) => {
  const baseQuery = `/proxy/prv/us/uud`;
  const params = {};
  return requestMaker(baseQuery, { params, payload });
};

export const getJobStatus = (params, headers) => {
  const baseQuery = `/proxy/prv/us/jb`;
  const payload = {};
  return requestMaker(baseQuery, { params, payload }, headers);
};
export const createJob = (payload) => {
  const baseQuery = `/proxy/prv/us/cj`;
  const params = {};
  return requestMaker(baseQuery, { params, payload });
};

export const markJobCompleted = (payload) => {
  const baseQuery = `/proxy/prv/us/mjc`;
  const params = {};
  return requestMaker(baseQuery, { params, payload });
};

export const transerAvatar = (payload) => {
  const baseQuery = `/proxy/prv/us/ta`;
  const params = {};
  return requestMaker(baseQuery, { params, payload });
};
