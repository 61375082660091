
import { h } from 'preact';

function SVGPointerTF(props) {
  return (
    <svg
      className={props.className}
      width="106px"
      height="36px"
      viewBox="0 0 106 36"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>arrows</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-135.000000, -211.000000)" fill="#FFFFFF">
          <g transform="translate(135.000000, 135.000000)">
            <g transform="translate(0.000000, 76.000000)">
              <path
                d="M106,1.1226812 L106,34.8773188 C106,35.1534612 105.776142,35.3773188 105.5,35.3773188 C105.376479,35.3773188 105.25733,35.331597 105.165518,35.2489659 L86.8258824,18.7432941 C86.4153724,18.3738351 86.382094,17.7415453 86.751553,17.3310353 C86.7750092,17.3049728 86.7998199,17.2801621 86.8258824,17.2567059 L105.165518,0.751034129 C105.370773,0.56630462 105.686918,0.582943826 105.871647,0.788198837 C105.954278,0.88001119 106,0.999160314 106,1.1226812 Z"
              />
              <path
                d="M20,1.1226812 L20,34.8773188 C20,35.1534612 19.7761424,35.3773188 19.5,35.3773188 C19.3764791,35.3773188 19.25733,35.331597 19.1655176,35.2489659 L0.825882385,18.7432941 C0.415372363,18.3738351 0.382093951,17.7415453 0.75155297,17.3310353 C0.775009184,17.3049728 0.799819925,17.2801621 0.825882385,17.2567059 L19.1655176,0.751034129 C19.3707726,0.56630462 19.6869176,0.582943826 19.8716471,0.788198837 C19.9542782,0.88001119 20,0.999160314 20,1.1226812 Z"
                transform="translate(10.000000, 18.000000) scale(-1, 1) translate(-10.000000, -18.000000) "
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
/* eslint-enable react/no-unknown-property */

export default SVGPointerTF;
