import React from 'react'
import { XIcon } from '@heroicons/react/outline';
import { HAIR_STYLES } from 'configs/RendererConfig';
import NoSkybox from "assets/icons/none.svg";
import isEmpty from 'lodash/isEmpty';
import './HairStylesList.scss';
import MixPanel from 'appAnalytics/mixPanel';
import { EVENT_ACTIONS, EVENT_CATEGORIES } from 'appAnalytics/eventCategories';

const HairStylesList = ({ showHairSelector, toggleHairs, selectedHairStyle, hairStyleKeys, product, setSelectedHairStyle, gender, eventCategory }) => {
    if (isEmpty(hairStyleKeys)) {
        return null;
    }
    return (
        <div className={`Dopplr__HairStylesWrapper${showHairSelector ? ' show' : ''}`}>
            <div className='Dopplr__HairStylesWrapper__ToggleButton' onClick={() => {
                MixPanel.buttonClicked(eventCategory, EVENT_ACTIONS.HIDE_HAIRSTYLES);
                toggleHairs(!showHairSelector)
            }}>
                <XIcon className={`${showHairSelector ? 'show' : ''}`} style={{ width: '60%' }} />
            </div>
            <div className='Dopplr__HairStylesWrapper__Title'>
                Let's see how you look in different hair styles
            </div>
            <div className='Dopplr__HairStylesWrapper__List'>
                {hairStyleKeys.map(hairStyleKey => {
                    const hairStyleObj = HAIR_STYLES[gender][hairStyleKey]
                    const { thumbnailImage } = hairStyleObj;
                    const selected = selectedHairStyle.name === hairStyleKey;
                    return <div key={hairStyleKey} className={`Dopplr__HairStylesWrapper__List__Skybox__Item ${selected ? ' selected' : 'overlay'}`} onClick={() => {
                        MixPanel.buttonClicked(eventCategory, EVENT_ACTIONS.HAIRSTYLE_CHANGE, { selectedHairStyle: hairStyleKey });
                        setSelectedHairStyle(hairStyleObj)
                    }}>
                        {thumbnailImage ? <img className='Dopplr__HairStylesWrapper__List__Skybox__Thumbnail' src={thumbnailImage} alt="" /> :
                            <div className='text'>DEFAULT</div>
                        }
                    </div>
                })}
            </div>
        </div>
    )
}

export default HairStylesList