import { styled } from '@mui/material/styles';
import { Tooltip as MuiTooltip, tooltipClasses } from '@mui/material';

const Tooltip = styled(({ className, ...props }) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'white',
    border: '1px',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '0.7em',
    color: 'black',
    backgroundColor: 'white',
    borderRadius: '0.5em',
    border: 'none',
    boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.5)'
  },
  [`& .${tooltipClasses.tooltipPlacementBottom} .${tooltipClasses.arrow}`]: {
    marginTop: '-1em !important',
  },
  [`& .${tooltipClasses.tooltipPlacementTop} .${tooltipClasses.arrow}`]: {
    marginBottom: '-1em !important',
  },
  [`& .${tooltipClasses.tooltipPlacementRight} .${tooltipClasses.arrow}`]: {
    marginRight: '-1em !important',
  }
}));

export default Tooltip;