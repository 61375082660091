import { all, takeLatest, call, put } from "redux-saga/effects";
import { userServiceAPI } from "api";
import { FETCH_AVATAR } from "redux/actions/actionTypes";
import { actionsCreator } from "redux/actions/actionsCreator";

function* fetchAvatar(action) {
  try {
    const { data } = yield call(userServiceAPI.getAvatarData);
    yield put(actionsCreator.SET_AVATAR_DATA({...data}));
  } catch (error) {
    console.log(error);
  }
}

export default function* avatarSagas() {
  yield takeLatest(FETCH_AVATAR, fetchAvatar);
}
