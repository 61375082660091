import React from 'react'
import './BasicInfo.scss';

const BasicInfo = ({name, gender}) => {
    return (
        <div className="BasicInfo">
            <div className="BasicInfo__Title">Basic Information</div>
            <div className="BasicInfo__Description">
                <div className="BasicInfo__Description__Row">
                    <div className="BasicInfo__Description__Label">Name</div>
                    <div className="BasicInfo__Description__Value">{name}</div>
                </div>
                <div className="BasicInfo__Description__Row">
                    <div className="BasicInfo__Description__Label">Gender</div>
                    <div className="BasicInfo__Description__Value">{gender}</div>
                </div>
            </div>
        </div>
    )
}

export default BasicInfo
