import React from 'react'
import './Avatar.scss';

const Avatar = ({name}) => {
    return (
        <div className="Avatar">
            <div className="Avatar__Image"><img src={""} /></div>
            <div className="Avatar__Name">Name</div>
        </div>
    )
}

export default Avatar
