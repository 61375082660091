import React from 'react';


const StepProgress = ({ activeStepIndex }) => {
    return (<div className='Dopplr_StepProgress'>
        {[0, 1, 2].map((stepIndex) => {
            const isStepActive = stepIndex <= activeStepIndex;
            return <div className={`Dopplr_StepProgress__Step ${isStepActive ? 'active' : ''}`} key={stepIndex} />
        })}
    </div>
    )
}

export default StepProgress
