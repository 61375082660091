import React, { useState, useEffect, useRef } from 'react';
import * as BABYLON from "@babylonjs/core/Legacy/legacy";
import { ArcRotateCamera, Vector3, SceneLoader } from "@babylonjs/core";
import { Animation } from "@babylonjs/core/Animations/animation";
import { CubicEase, EasingFunction } from "@babylonjs/core/Animations/easing";
import { Color3, ShadowGenerator } from "@babylonjs/core";
import "@babylonjs/loaders/OBJ";
import { proxyAPI, catalogAPI } from "api";
import * as dat from 'dat.gui';

import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

import SceneComponent from "containers/babylon/SceneComponent";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { CameraActionBar } from 'components/CameraActionBar';
import { RENDERER_CONFIG, MEASUREMENTS, CLOTHES, BODY_IDENTIFIER, CAMERA_MODE_ASSET } from "../../configs/RendererConfig.js";
import { ASSETS_KEYLIST, BRANDS } from "utils/constants";
import { isTouchFriendly, isIpad, isIos } from "utils";
import { changeHairProperties } from 'utils/babylonUtils.js';
import { adidasProductList, abfrlV2ProductList, abfrlProductList, cluster1ProductList, cluster2ProductList, SANProductList, ANANTAProductList, thessProductList, pumaProductList, fabIndiaList, nicobarProductList, ccclubProductList, cavaProductList, virgioProductList, forevernewProductList, beyondExtremesProductList, testProductList, beingHumanProductListQA, beingHumansProductList, rareRabbitProductList, fashorProductList, superKicksProductList, fefProductList, burgerBaeProductList } from 'containers/adidasProducts/AdidasProducts.js';
import { skyboxConfig } from 'configs/SkyboxConfig.js';
import NoSkybox from "assets/icons/none.svg";
import SkyBoxWhite from "assets/icons/skyboxIconWhite.png";
import SkyBoxBlack from "assets/icons/skyboxIconBlack.png";
import "./AssetViewer.scss";
import Loader from 'components/Loader/Loader.js';
import ShadowImage from "assets/images/Shadow1.png";
import snapHeadToBodyNewPose from '../babylon/avatarLogic/SnappingLogic.js';
import Switch from '@mui/material/Switch';
import { useParams } from 'react-router-dom';
import { EVENT_CATEGORIES } from 'appAnalytics/eventCategories.js';
import { set } from 'lodash';
const productList = [...adidasProductList, ...abfrlV2ProductList, ...abfrlProductList, ...cluster1ProductList, ...cluster2ProductList, ...SANProductList, ...ANANTAProductList, ...thessProductList, ...pumaProductList, ...fabIndiaList, ...nicobarProductList, ...ccclubProductList, ...cavaProductList, ...beyondExtremesProductList, ...virgioProductList, ...forevernewProductList, ...testProductList, ...beingHumanProductListQA, ...beingHumansProductList, ...rareRabbitProductList, ...fashorProductList, ...superKicksProductList, ...fefProductList, ...burgerBaeProductList]

const faceVertices = [2477, 3284, 3287, 3288, 3289, 3292, 3293, 3295, 3297, 3301, 11826, 11827, 11828, 11831, 11832, 11819, 11892, 11891, 11893, 11890, 11889, 11888, 12366, 11641, 11640, 11639, 11638, 11637, 11636, 11635, 11571, 11570, 11569, 11562, 11561, 11560, 3299, 2415, 2468, 2464, 2465, 2466, 2471, 2473, 2474]
const bodyVertices = [6297, 6298, 6299, 6301, 6300, 6317, 6315, 6314, 6312, 6311, 6262, 6263, 6264, 6265, 6266, 6267, 6268, 6269, 6270, 6271, 6272, 6273, 6275, 6275, 6276, 6277, 6278, 6279, 6280, 6281, 6282, 6283, 6284, 6285, 6286, 6287, 6288, 6289, 6290, 6291, 6292, 6293, 6294, 6295, 6296]
const bodyVerticesPlus1 = [6290, 6289, 6288, 6313, 6287, 6314, 6286, 6315, 6285, 6316, 6284, 6317, 6318, 6283, 6319, 6282, 6281, 6280, 6320, 6279, 6321, 6278, 6277, 6303, 6302, 6304, 6301, 6305, 6300, 6299, 6298, 6306, 6297, 6307, 6296, 6308, 6309, 6295, 6310, 6294, 6311, 6293, 6292, 6312, 6291];

const Sandbox = () => {
  const [sceneLoading, setSceneLoading] = useState(true);
  const [productOptions, setProductOptions] = useState([]);
  const [brandOption, setBrandOption] = useState(null);
  const [bodyOptions, setBodyOptions] = useState(null);
  const [brandInput, setBrandInput] = useState('');
  const [productInput, setProductInput] = useState('');
  const [cameraMode, setCameraMode] = useState(CAMERA_MODE_ASSET.Body);
  const [isCameraActionOpened, setCameraActionOpened] = useState(false);
  const [hideBody, setHideBody] = useState(false);
  // const [bodyTypeInput, setBodyTypeInput] = useState('');
  const [selectedProductOption, setSelectedProductOption] = useState(null);
  const [selectedBodyTypeOption, setSelectedBodyTypeOption] = useState(null);
  const [assetsObject, setAssetsObject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [productsLoading, setProductsLoading] = useState(true);
  const [notFound, setShowNotFound] = useState(false);
  const [showEnvironments, toggleEnvironment] = useState(false);
  const [skyboxList, setSkyboxList] = useState([]);
  const [selectedSkybox, setSelectedSkybox] = useState({});
  const [sceneObj, setSceneObj] = useState({});
  const [loadedGUI, setloadedGUI] = useState(null);
  let bodyNeckPosition = null;
  const sceneRef = useRef();
  sceneRef.current = sceneObj;
  const { brandId } = useParams();

  useEffect(() => {
    const brand = BRANDS.find(brand => brand.value === brandId);
    if (brand) {
      setBrandOption(brand);
      fetchProducts(brand.value, "");
      const bodyOptions = Object.values(BODY_IDENTIFIER).map(shape => {
        return { label: shape, value: shape }
      })
      setBodyOptions(bodyOptions);
      setSelectedBodyTypeOption(bodyOptions[0]);
    } else {
      setShowNotFound(true);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(sceneObj)) {
      Object.keys(assetsObject).map(assetKey => {
        if (sceneObj[assetKey]) {
          moveActiveCamera(sceneObj[assetKey], cameraMode);
        }
      })
    }
  }, [cameraMode]);

  const toggleBodyMesh = (scene, hideBody) => {
    const bodyMesh = scene && scene.getMeshByName(`avatarBody_${selectedProductOption.avatar}`);
    const headMesh = scene && scene.getMeshByName(`head_${selectedProductOption.avatar}`);
    const shoesMesh = scene && scene.getMeshByName(`shoes_${selectedProductOption.shoes ? `${selectedProductOption.shoes}_` : ''}${selectedProductOption.avatar}`)
    bodyMesh && bodyMesh.setEnabled(hideBody);
    headMesh && headMesh.setEnabled(hideBody);
    shoesMesh && shoesMesh.setEnabled(hideBody);
  }

  useEffect(() => {
    if (!isEmpty(sceneObj)) {
      Object.keys(assetsObject).map(assetKey => {
        if (sceneObj[assetKey]) {
          toggleBodyMesh(sceneObj[assetKey], !hideBody);
        }
      })
    }
  }, [hideBody]);

  useEffect(() => {
    if (!isEmpty(selectedBodyTypeOption) && !isEmpty(selectedProductOption)) {
      fetchAssets();
    }
  }, [selectedBodyTypeOption, selectedProductOption]);

  useEffect(() => {
    if (!isEmpty(selectedSkybox) && !isEmpty(sceneObj)) {
      Object.keys(assetsObject).map(assetKey => {
        if (sceneObj[assetKey]) {
          createEnvironment(sceneObj[assetKey], selectedSkybox, true);
        }
      })
      // createGUI(sceneObj[Object.keys(assetsObject)[1]], selectedSkybox)
    }
  }, [selectedSkybox, sceneObj])

  const fetchProducts = async (brand, product) => {
    try {
      const params = { company_id: brand };
      setProductsLoading(true);
      const response = await catalogAPI.getProducts(params);
      let products = get(response, 'data.response', []).filter(product => product.company_id === brand).map((product) => {
        return { ...product, brand: product.company_id, label: `${product.sku}`, value: product.product_path }
      });
      if (products && products.length === 0) {
        products = productList.filter(product => product.brand === brand).map((product) => {
          return { ...product, label: `${product.id} ${product.product_path}`, value: product.product_path }
        });
      }
      setProductOptions(products);
      setProductsLoading(false);
    } catch (e) {
      console.log(e);
      setProductsLoading(false);
    }
  }

  const fetchAssets = async () => {
    try {
      //api call to get all the assets based on selectedProductOption and brand
      setLoading(true)
      const { gender, product_path, product_counter_part, brand, avatar, shoes } = selectedProductOption;
      let bodyType = selectedBodyTypeOption.value
      if (gender === 'female' && bodyType === 'triangle') {
        bodyType = 'apple';
      }
      const clothPayload = {
        gender,
        product_path,
        product_counter_part,
        brand,
        avatar,
        shoes,
        bodyType,
        bodySize: product_path.includes("zipper") ? 'medium' : 'small',
      };
      const clothUrlResponse = await proxyAPI.getAllAssets(clothPayload);
      setAssetsObject(clothUrlResponse.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    };
  }

  const createGroundForShadow = (scene) => {
    const groundMesh = scene.getMeshByName("ground")
    if (groundMesh) { groundMesh.dispose() }

    const ground = BABYLON.MeshBuilder.CreateGround("ground", { height: 1, width: 1, subdivisions: 4 });
    ground.position = new BABYLON.Vector3(0, 0, 0)
    ground.scaling.x = 0.55;
    ground.scaling.z = 0.55;

    const groundMat = new BABYLON.StandardMaterial("groundMat", scene);
    groundMat.diffuseTexture = new BABYLON.Texture(ShadowImage, scene);
    groundMat.roughness = 1.0
    groundMat.diffuseTexture.hasAlpha = true;
    groundMat.useAlphaFromDiffuseTexture = true;
    groundMat.backFaceCulling = true;
    groundMat.alpha = 0.8;
    groundMat.specularColor = new BABYLON.Color3(0, 0, 0);
    ground.material = groundMat;
  }

  const ImportBodyModel = async (scene, assetKey) => {
    const assetsObj = assetsObject[assetKey]
    const key = Object.keys(assetsObj || {}).find(key => assetsObj && assetsObj[key] && key === 'bodyUrl');
    console.time("BodyDownload" + "");
    createGroundForShadow(scene);
    let rootUrl = '';
    if (assetKey) {
      rootUrl = assetsObj[key];
    }
    // let rootUrl = get(avatarAssets, "avatarUrl", "");
    try {
      const result = await SceneLoader.ImportMeshAsync(
        null,
        `${rootUrl}`,
        // "medium_apple_plus1.glb",
        "",
        scene,
        function (evt) {
          // onProgress
          let loadedPercent = 0;
          if (evt.lengthComputable) {
            loadedPercent = ((evt.loaded * 100) / evt.total).toFixed();
          } else {
            // TOFIX:: get the file size from config
            //get the size of file to calculate loaded percent
            let dlCount = evt.loaded / 1790368;
            loadedPercent = Math.floor(dlCount * 100.0);
          }
        });
      // Result has meshes, particleSystems, skeletons, animationGroups and transformNodes
      const onBodyDownloaded = function (
        meshes,
        skeletons,
        animationGroups,
        scene,
      ) {
        meshes[0].name = `avatarBody_${selectedProductOption.avatar}`;
        meshes.forEach(m => {
          m.alwaysSelectAsActiveMesh = true;

        });

        var headMaterial = scene.getMaterialByName(RENDERER_CONFIG.HEAD_MATERIAL_NAME);
        if (headMaterial) {
          var clonedMaterial = headMaterial.clone();
          clonedMaterial.metallic = 0;
          clonedMaterial.roughness = 1;
          // clonedMaterial.forceIrradianceInFragment = true;
          clonedMaterial.environmentIntensity = 0.8;
          // clonedMaterial.reflectionTexture = hdrTexture;
          clonedMaterial.cameraExposure = 1;
          clonedMaterial.cameraContrast = 1.5;
          // clonedMaterial.disableLighting = true;
          // clonedMaterial.albedoColor = new Color3(0.95, 0.95, 0.95);

          const bodyBumpTexture = new BABYLON.Texture("/bodyMaps/Skin_texture_Normal.png", scene)
          meshes.forEach(mesh => {
            if (selectedProductOption.avatar == 'v14' || selectedProductOption.avatar == 'v18') {
              if (mesh.material != null && mesh.material.bumpTexture != null) {
                clonedMaterial.environmentIntensity = 1;
                clonedMaterial.bumpTexture = mesh.material.bumpTexture;
                clonedMaterial.bumpTexture.level = 0.68;
                clonedMaterial.roughness = 0.80;
                clonedMaterial.indexOfRefraction = 1.4500;
                // clonedMaterial.clearCoat.isEnabled = true;
                // clonedMaterial.clearCoat.intensity=0.6400;
                // clonedMaterial.clearCoat.roughness=0.4500;
                // clonedMaterial.clearCoat.indexOfRefraction=1.5000;
                // clonedMaterial.sheen.isEnabled = true;
                // clonedMaterial.sheen.intensity = 0.1600;
                // clonedMaterial.sheen.roughness= 0.1400;

                headMaterial.roughness = 0.68;
                headMaterial.indexOfRefraction = 1.4500;
                headMaterial.roughness = 0.80;
                // headMaterial.clearCoat.isEnabled = true;
                // headMaterial.clearCoat.intensity=0.1600;
                // headMaterial.clearCoat.roughness=0.5300;
                // headMaterial.clearCoat.indexOfRefraction=1.5000;
                // headMaterial.sheen.isEnabled = true;
                // headMaterial.sheen.intensity = 0.0500;
                // headMaterial.sheen.roughness= 0.6200;
              }
            }
            if (mesh.name == "nail") {
              mesh.material.albedoColor = new BABYLON.Color3(230.0 / 255.0, 174.0 / 255.0, 174.0 / 255).toLinearSpace();
            } else {
              mesh.material = clonedMaterial;
            }
            // mesh.material.bumpTexture = bodyBumpTexture;
            // mesh.material.bumpTexture.uScale = 35.0;
            // mesh.material.bumpTexture.vScale = 35.0;
            //Change rendering group to not conflict with the skybox
            mesh.renderingGroupId = 1;
          });
        }
        const bodyMesh = meshes[1];
        bodyMesh.name = `bodyMesh_${selectedProductOption.avatar}`;
        if (bodyMesh) {
          // snapHeadtoBody(bodyMesh, scene);
          bodyNeckPosition = snapHeadToBodyNewPose(bodyMesh, scene, selectedProductOption);
          // clipHair(scene);
          preventCollisions(bodyMesh);
        }
      };

      onBodyDownloaded(
        result.meshes,
        result.skeletons,
        result.animationGroups,
        scene,
      );
    } catch (error) {
      console.error("error occured in importing body", error)
    };

  };

  const clipHair = (scene) => {
    let meshToClip = scene.getMeshByName(`hair_${selectedProductOption.gender}_${selectedProductOption.avatar}`);
    let plane1 = null;
    let plane2 = null;
    const isAngledPose = (selectedProductOption.avatar === "v15") ? true : false;

    if (bodyNeckPosition) {
      const yShift = isAngledPose ? -0.03 : 0.01
      const rectifiedNeckPosition = new BABYLON.Vector3(-bodyNeckPosition.x, bodyNeckPosition.y + yShift, bodyNeckPosition.z - 0.02)
      plane1 = BABYLON.Plane.FromPositionAndNormal(rectifiedNeckPosition, new BABYLON.Vector3(0, -1, 0.8))
      plane2 = BABYLON.Plane.FromPositionAndNormal(rectifiedNeckPosition, new BABYLON.Vector3(0, -1, -0.6))
    }
    if (meshToClip) {
      // meshToClip.material.backFaceCulling = false
      let meshToClipClone = meshToClip.clone()

      meshToClip.onBeforeRenderObservable.add(function () {
        scene.clipPlane3 = plane1
      })
      meshToClip.onAfterRenderObservable.add(function () {
        scene.clipPlane3 = null
      })
      meshToClipClone.onBeforeRenderObservable.add(function () {
        scene.clipPlane4 = plane2
      })
      meshToClipClone.onAfterRenderObservable.add(function () {
        scene.clipPlane4 = null
      })
    }
  }

  const onHeadDownloaded = function (
    meshes,
    skeletons,
    animationGroups,
    scene,
    arcCamera,
    hdrTexture
  ) {
    const headMaterialClone = scene
      .getMaterialByName(RENDERER_CONFIG.HEAD_MATERIAL_NAME)
      .clone();
    headMaterialClone.name = "headMaterialClone";
    headMaterialClone.metallic = 0;
    headMaterialClone.roughness = 1;
    headMaterialClone.environmentIntensity = 0.8;
    // meshes[1].material = headMaterialClone;
    meshes.forEach(mesh => {
      mesh.renderingGroupId = 1;
      // if(mesh.material!=null)
      // mesh.material.wireframe = true;
    });
    // clipHair(scene);
  };

  const ImportHeadModel = async (scene, assetKey) => {
    try {
      const avatarFaceUrl = selectedProductOption && selectedProductOption.gender === 'male' ? `/${RENDERER_CONFIG.ASSETVIEWER_HEAD_MODEL_FILE_NAME}` : `/${RENDERER_CONFIG.ASSETVIEWER_HEAD_MODEL_FEMALE}`;
      const result = await SceneLoader.ImportMeshAsync(
        "",
        avatarFaceUrl,
        "",
        scene,
        function (evt) {
          // onProgress
          let loadedPercent = 0;
          if (evt.lengthComputable) {
            loadedPercent = ((evt.loaded * 100) / evt.total).toFixed();
          } else {
            // TOFIX:: get the file size from config
            //get the size of file to calculate loaded percent
            let dlCount = evt.loaded / 789312;
            loadedPercent = Math.floor(dlCount * 100.0);
          }
        }
      );
      result.meshes[0].name = `head_${selectedProductOption.avatar}`;
      const faceMesh = scene.getMeshByName("mesh");
      const hairMesh = scene.getMeshByName("haircut_generated");
      if (faceMesh) {
        faceMesh.name = `face_${selectedProductOption.gender}_${selectedProductOption.avatar}`;
      }
      if (hairMesh) {
        hairMesh.name = `hair_${selectedProductOption.gender}_${selectedProductOption.avatar}`;
        // hairMesh.material.transparencyMode = 1; // Alpha Test -> 1, Opaque -> 0, Alpha Blend -> 2
        // hairMesh.material.needDepthPrePass = true;

        changeHairProperties(scene, hairMesh, "#281806", selectedProductOption.gender);
      }
      onHeadDownloaded(
        result.meshes,
        result.skeletons,
        result.animationGroups,
        scene
      )
      await ImportBodyModel(scene, assetKey);
    } catch (error) {
      console.error("error occured in head import", error)
    }
  };

  const ImportClothModel = async (scene, assetKey) => {
    const assetsObj = assetsObject[assetKey]
    const assetsKeys = Object.keys(assetsObj || {}).filter(key => assetsObj && assetsObj[key] && key !== 'bodyUrl');
    if (assetsKeys && assetsKeys.length > 0) {
      try {
        const assetsList = assetsKeys.map(key => assetsObj[key]);
        const promiseList = assetsList.map(assetUrl => {
          return SceneLoader.ImportMeshAsync("", "", assetUrl, scene);
        })
        const assetResults = await Promise.all(promiseList);
        assetResults.forEach((result, index) => {
          const meshes = result.meshes;
          const assetKey = assetsKeys[index];
          const meshName = assetKey === 'shoeUrl' ? `shoes_${selectedProductOption.shoes ? `${selectedProductOption.shoes}_` : ''}${selectedProductOption.avatar}` : `clothAsset_${selectedProductOption[ASSETS_KEYLIST[assetsKeys[index]]]}_${selectedProductOption.avatar}`;
          meshes[0].name = meshName;
          if (selectedProductOption.brand != 'puma' && meshName.includes("shoes")) {
            const leftLogoMesh = scene.getMeshByName("Retopo_puma .018");
            if (leftLogoMesh != null) {
              leftLogoMesh.setEnabled(false);
            }
            const rightLogoMesh = scene.getMeshByName("Retopo_puma .017")
            if (rightLogoMesh != null) {
              rightLogoMesh.setEnabled(false);
            }
          }

          const { sharpMaterialsList } = selectedProductOption;
          meshes.forEach(mesh => {
            if (mesh.material) {

              if (sharpMaterialsList && sharpMaterialsList.includes(mesh.material.name)) {
                // These materials are included in sharpMaterialsList, so no change
              }
              else {
                mesh.material.transparencyMode = null; // Not Defined -> null, Opaque -> 0, Alpha Test -> 1, Alpha Blend -> 2
                mesh.material.hasAlpha = true;
                mesh.material.useAlphaFromAlbedoTexture = true;
              }

              // Enhancing bumptextures for clothes
              if (mesh.material.bumpTexture != null) {
                mesh.material.bumpTexture.level = 1.5;
              }
            }

            mesh.renderingGroupId = 1;
          });
          // if (assetKey === 'clothUrl' || assetKey === 'bottomUrl' ) {
          // scene.materials.forEach((material) => {
          //   if (material != null && material.name !== "70022460 Plain Weave SUB_FRONT_2830" && material.bumpTexture != null)
          //     material.bumpTexture.level = 2;
          // });
        });
      } catch (error) {
        console.error("error occured in cloth import", error);
      }
    }
    await ImportHeadModel(scene, assetKey);
  }

  const createArcRotateCamera = scene => {
    let canvas = scene.getEngine().getRenderingCanvas();
    const camera = new ArcRotateCamera(
      "camera",
      Math.PI / 2,
      -Math.PI / 2,
      3,
      new Vector3(0, 0, 0),
      scene
    );
    camera.attachControl(canvas, true);

    const x = 0.018261507153511047, y = 0.8, z = 0.01276637613773346;
    const verticalAngleLimitBottom = 90; // [90 degrees, so that camera doesn't go below the dome]
    // const verticalAngleLimitTop = 20    // [20 degrees, so that camera doesn't go inside the body]

    // Set some basic camera settings
    camera.minZ = -10; // clip at 1 meter
    camera.maxZ = 100;
    if (!(isTouchFriendly && isIpad)) {
      camera.zoomToMouseLocation = true;
      camera.wheelDeltaPercentage = 0.05;
    }
    camera.panningSensibility = 0; // how fast do you pan, set to 0 if you don't want to allow panning

    // camera.panningAxis = new Vector3(1, 0, 1) // pan along ground
    // camera.panningOriginTarget = Vector3.Zero() // where does the panning distance limit originate from
    // camera.panningDistanceLimit = 100 // how far can you pan from the origin

    camera.allowUpsideDown = false; // don't allow zooming inverted
    camera.lowerRadiusLimit = 0.0; // how close can you zoom
    camera.upperRadiusLimit = 3.0; // how far out can you zoom
    // camera.lowerBetaLimit = 0.9; // how high can you move the camera
    camera.upperBetaLimit = verticalAngleLimitBottom * Math.PI / 180; // how low down can you move the camera


    camera.setTarget(new Vector3(x, y, z));
    camera.setPosition(new BABYLON.Vector3(1.2, 0.95, 2.5));    // arcCamera.setPosition(new BABYLON.Vector3(0, 1.6, 3));
    // camera.upperBetaLimit = (verticalAngleLimitBottom) * Math.PI / 180;
    // arcCamera.lowerBetaLimit = (verticalAngleLimitTop) * Math.PI / 180;

    camera.checkCollisions = true; // make the camera collide with meshes
    const radiusOnAllAxes = 0.2;
    camera.collisionRadius = new Vector3(radiusOnAllAxes, radiusOnAllAxes, radiusOnAllAxes); // how close can the camera go to collision mesh (bodyMesh)

    camera.wheelPrecision = 80;
    camera.pinchPrecision = 80;

    return camera;
  };

  const createLightSetup = (scene) => {
    let ambientLight = new BABYLON.HemisphericLight("ambientLight", new BABYLON.Vector3(0, 1, 0), scene);
    ambientLight.intensity = 1.0;
    ambientLight && ambientLight.setEnabled(false);

    let keyLight = new BABYLON.DirectionalLight("directionalKeyLight", new BABYLON.Vector3(-1, 0, -1), scene);
    keyLight.intensity = 1.5;
    let fillLight = new BABYLON.DirectionalLight("directionalFillLight", new BABYLON.Vector3(1, 0, -1), scene);
    fillLight.intensity = 1.0;
    let backLight = new BABYLON.DirectionalLight("directionalBackLight", new BABYLON.Vector3(0, 0, 1), scene);
    backLight.intensity = 1.5;

    let pointLight1 = new BABYLON.PointLight("pointLight1", new BABYLON.Vector3(-0.5, 2.0, 1.5), scene);
    pointLight1.intensity = 12.0
    pointLight1.diffuse = new BABYLON.Color3(255 / 255, 250 / 255, 218 / 255);
    let pointLight2 = new BABYLON.PointLight("pointLight2", new BABYLON.Vector3(0.5, 1.5, 0.75), scene);
    pointLight2.intensity = 3.0
    pointLight2.diffuse = new BABYLON.Color3(255 / 255, 250 / 255, 218 / 255);
    let pointLight3 = new BABYLON.PointLight("pointLight3", new BABYLON.Vector3(0, 1.5, -1), scene);
    pointLight3.intensity = 8
    pointLight3.diffuse = new BABYLON.Color3(255 / 255, 250 / 255, 218 / 255);
    let pointLight4 = new BABYLON.PointLight("pointLight4", new BABYLON.Vector3(-0.5, 0.5, 1.5), scene);
    pointLight4.intensity = 5
    pointLight4.diffuse = new BABYLON.Color3(255 / 255, 250 / 255, 218 / 255);
    pointLight1 && pointLight1.setEnabled(false);
    pointLight2 && pointLight2.setEnabled(false);
    pointLight3 && pointLight3.setEnabled(false);
    pointLight4 && pointLight4.setEnabled(false);

  }
  const generateShadows = (scene, shadowType, lightTypeUsedForShadow) => {
    // if shadowType is static, we use a simple shadow image below the avatar
    // if shadowType is dynamic, we create the shadows in realtime on dome floor

    if (shadowType === "static" || lightTypeUsedForShadow === null) {
      createGroundForShadow(scene)
      return
    }
    const ground = scene.getMeshByName("ground")
    if (ground) { ground.dispose() }

    // This is where we generate shadow and make the dome receive it
    let shadowList = []
    scene.meshes.forEach((mesh) => {
      if (mesh.name === "floor" || mesh.name === "Floor" || mesh.name === "dome" || mesh.name.includes("Sketchup.008") || mesh.name.includes('Sphere')) {
        mesh.receiveShadows = true;
      }
      else if (!mesh._geometry || mesh.name === "hdrSkyBox" || mesh.id === "__root__") {
      }
      else {
        mesh.receiveShadows = false;
        shadowList.push(mesh);
      }
    })

    // Shadow
    if (lightTypeUsedForShadow === "directional") {
      let shadowLight = scene.getLightByName("directionalKeyLight");
      shadowLight.shadowMaxZ = 6.0;
      shadowLight.shadowMinZ = 0;
      shadowLight.renderingGroupId = 1;
      var shadowGenerator = new BABYLON.ShadowGenerator(1024, shadowLight);
      shadowGenerator.getShadowMap().renderList.push(...shadowList);
      shadowGenerator.setDarkness(0.5);
      shadowGenerator.enableSoftTransparentShadow = true;
      shadowGenerator.transparencyShadow = true;
      shadowGenerator.useContactHardeningShadow = true;
      shadowGenerator.bias = 0.000001;
    }
    else {
      let shadowLight = scene.getLightByName("pointLight2");
      var shadowGenerator = new BABYLON.ShadowGenerator(1024, shadowLight);
      shadowGenerator.getShadowMap().renderList.push(...shadowList);
      shadowGenerator.enableSoftTransparentShadow = true;
      shadowGenerator.transparencyShadow = true;
      shadowGenerator.useContactHardeningShadow = true;
      shadowGenerator.bias = 0.001;
    }
  }

  const preventCollisions = (bodyMesh) => {
    bodyMesh.checkCollisions = true;
  }

  const setCamera = scene => {
    const arcCamera = createArcRotateCamera(scene);
    const x = 0.018261507153511047, y = 0.8, z = 0.01276637613773346;
    arcCamera.setTarget(new Vector3(x, y, z));
    arcCamera.setPosition(new BABYLON.Vector3(0, 1.6, 3));
    var hdrTexture;
    createLightSetup(scene);
    var engine = scene.getEngine();
    var width = engine.getRenderWidth();
    var height = engine.getRenderHeight();
    var ratio = width / height;
    var orthoMeasure = 12;

    //reset orthographic ratio when canvas is resized
    engine.onResizeObservable.add(function () {
      if (
        engine.getRenderWidth() !== width ||
        engine.getRenderHeight() !== height
      ) {
        width = engine.getRenderWidth();
        height = engine.getRenderHeight();
        ratio = width / height;
        arcCamera.orthoLeft = ratio * orthoMeasure * -1;
        arcCamera.orthoRight = ratio * orthoMeasure;
      }
    });
    return {
      arcCamera: arcCamera,
      // shadowGenerator: shadowGenerator,
      hdrTexture: hdrTexture
    };
  };
  const resetCamera = (scene) => {
    // const { gender } = selectedProductOption;
    moveActiveCamera(scene, CAMERA_MODE_ASSET.Body);
  }


  const createAnimation = ({ property, from, to }) => {
    const FRAMES_PER_SECOND = 60;
    const ease = new CubicEase();
    ease.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);

    const animation = Animation.CreateAnimation(
      property,
      Animation.ANIMATIONTYPE_FLOAT,
      FRAMES_PER_SECOND,
      ease
    );
    animation.setKeys([
      {
        frame: 0,
        value: from,
      },
      {
        frame: 100,
        value: to,
      },
    ]);

    return animation;
  }

  const moveActiveCamera = (scene, config) => {
    const { target, ...properties } = config;
    const arcCamera = scene.activeCamera;
    const SPEED_RATIO = 4;
    const LOOP_MODE = false;
    const FROM_FRAME = 0;
    const TO_FRAME = 100;
    Object.keys(properties).forEach(property => {
      let animationObj = {
        property,
        from: arcCamera[property],
        to: config[property]
      };
      if (property === 'alpha') {
        animationObj['from'] = ((arcCamera.alpha % 6.28) + 6.28) % 6.28;
      }
      arcCamera.animations.push(createAnimation(animationObj));
    });
    if (target) {
      if ("x" in target) {
        arcCamera.animations.push(createAnimation({
          property: "target.x",
          from: arcCamera.target.x,
          to: target.x,
        }))
      }
      if ("y" in target) {
        arcCamera.animations.push(createAnimation({
          property: "target.y",
          from: arcCamera.target.y,
          to: target.y,
        }))
      }
      if ("z" in target) {
        arcCamera.animations.push(createAnimation({
          property: "target.z",
          from: arcCamera.target.z,
          to: target.z,
        }))
      }
    }
    scene.beginAnimation(arcCamera, FROM_FRAME, TO_FRAME, LOOP_MODE, SPEED_RATIO);
  }

  const getSkyboxConfig = (brand) => {
    if (brand && skyboxConfig[brand]) {
      return skyboxConfig[brand];
    }
    return skyboxConfig['default']
  }



  const createEnvironment = async (scene, selectedSkybox, showLoader) => {
    if (!isEmpty(selectedSkybox)) {
      scene.debugLayer.hide();
      // scene.getEngine().displayLoadingUI();
      const { product_path } = selectedProductOption;
      const selectedSkyboxConfig = get(selectedSkybox, 'productList', []).find(config => config.product_path === product_path);
      const {
        skyboxSettings,
        domeSettings,
        lightSettings,
      } = selectedSkyboxConfig ? selectedSkyboxConfig : selectedSkybox.default ? selectedSkybox.default : selectedSkybox;
      const { id, acesEnabled } = selectedSkybox
      if (scene.getMeshByName('hdrSkyBox')) {
        scene.getMeshByName('hdrSkyBox').dispose();
        scene.environmentTexture = null;
      }
      if (skyboxSettings.pathFor6Images) {
        const texture = new BABYLON.CubeTexture(skyboxSettings.pathFor6Images, scene);
        scene.environmentTexture = texture;
        scene.environmentTexture.rotationY = skyboxSettings.rotation;
        scene.createDefaultSkybox(texture, true, 100);
        scene.environmentIntensity = skyboxSettings.intensity;
        scene.imageProcessingConfiguration.exposure = skyboxSettings.exposure || skyboxSettings.intensity;
        scene.imageProcessingConfiguration.contrast = skyboxSettings.contrast || skyboxSettings.intensity;
      }
      scene.imageProcessingConfiguration.toneMappingEnabled = true;
      scene.imageProcessingConfiguration.toneMappingType = acesEnabled ? BABYLON.ImageProcessingConfiguration.TONEMAPPING_ACES : BABYLON.ImageProcessingConfiguration.TONEMAPPING_STANDARD;
      let keyLight = scene.getLightByName("directionalKeyLight");
      let fillLight = scene.getLightByName("directionalFillLight");
      let backLight = scene.getLightByName("directionalBackLight");
      let pointLight1 = scene.getLightByName('pointLight1');
      let pointLight2 = scene.getLightByName('pointLight2');
      let pointLight3 = scene.getLightByName('pointLight3');
      let pointLight4 = scene.getLightByName('pointLight4');
      let ambientLight = scene.getLightByName("ambientLight");
      let lightUsedForShadow = keyLight;

      scene.meshes.forEach((mesh) => {
        if (mesh.name && mesh.name.includes("domeParent")) {
          mesh.setEnabled(false)
          mesh.dispose()
        }
      })
      let shadowType = "static"
      if (domeSettings.domePath && domeSettings.domePath !== "") {
        shadowType = "dynamic"
        try {
          const result = await SceneLoader.ImportMeshAsync(
            "",
            domeSettings.domePath,
            "",
            scene
          );
          const changeableItemMaterialName = domeSettings.changeableItemMaterialName;
          const changeableItemAlbedoColor = selectedProductOption.changeableItemAlbedoColor;
          const changeableItemEmissiveColor = selectedProductOption.changeableItemEmissiveColor;

          result.meshes.forEach((mesh) => {
            mesh.isPickable = false;
            if (mesh.name.includes("shrub")) {
              mesh.renderingGroupId = 1;
            }

            if (selectedProductOption.brand != 'cava' && (mesh.name.includes("CAVA Logo (Small)") || mesh.name.includes("CCAVA Logo (Small).001"))) {
              mesh.dispose();
            }
            if (mesh.name == "G-__555564_primitive0") {
              mesh.dispose();
            }
            if (mesh.name == "G-__555564.001" || mesh.name == "4 pain door#1.001_primitive1" || mesh.name == "4 pain window#3.004_primitive0") {
              mesh.material.albedoColor = new BABYLON.Color3(1, 1, 1).toLinearSpace();
            }
            if (mesh.material != null && mesh.material.name.includes("Orangery_Glass")) {
              mesh.material.alpha = 0.36;
              mesh.material.transparencyMode = null;
              mesh.material.alphaMode = BABYLON.Engine.ALPHA_ADD;
            }

            if (mesh.name.includes("dome") || mesh.name.includes("Sphere")) {
              mesh.material.albedoColor = BABYLON.Color3.FromHexString(domeSettings.domeMaterialAlbedoColor);
            }
            else if (mesh.name.includes("floor") || mesh.name.includes("Floor")) {
              mesh.material.albedoColor = BABYLON.Color3.FromHexString(domeSettings.floorMaterialAlbedoColor);
            }
            else if (mesh.name.includes("column hall.002")) {
              mesh.material.albedoColor = BABYLON.Color3.FromHexString(domeSettings.columnMaterialAlbedoColor);
              mesh.material.emissiveColor = BABYLON.Color3.FromHexString(domeSettings.columnMaterialEmissiveColor);
            }
            else if (mesh.name.includes("3bf33993")) {
              mesh.material.emissiveColor = BABYLON.Color3.FromHexString(domeSettings.columnMaterialEmissiveColor);
            }
            else if (mesh.name.includes("Sketchup.008")) {
              mesh.visibility = 0.35;
            }

            if (changeableItemMaterialName && changeableItemAlbedoColor && changeableItemEmissiveColor && mesh.material && mesh.material.name === changeableItemMaterialName) {
              mesh.material.albedoColor = BABYLON.Color3.FromHexString(changeableItemAlbedoColor);
              mesh.material.emissiveColor = BABYLON.Color3.FromHexString(changeableItemEmissiveColor);
            }
          })

          result.meshes[0].name = "domeParent";
          result.meshes[0].scaling = new BABYLON.Vector3(-1.2, 1.2, 1.2);
          result.meshes[0].rotation = new BABYLON.Vector3(0.0, 0.0, 0.0);
          // result.meshes[0].position.y = -0.02;
          // if (result.meshes[1]) { result.meshes[1].position.y = -0.02; }
          // if (result.meshes[2]) { result.meshes[2].position.y = -0.02; }
        } catch (e) {
          console.log(e);
        }
      } else {
        shadowType = "static"
      }
      let lightTypeUsedForShadow = lightSettings.type;

      if (lightSettings.type === "directional") {
        ambientLight && ambientLight.setEnabled(false);
        keyLight && keyLight.setEnabled(true);
        backLight && backLight.setEnabled(true);
        fillLight && fillLight.setEnabled(true);

        const keyLightSettings = lightSettings.directionalLightSettings.keyLightSettings;
        const keyLightPosition = new BABYLON.Vector3(keyLightSettings.position.x, keyLightSettings.position.y, keyLightSettings.position.z);
        const keyLightDirection = new BABYLON.Vector3(keyLightSettings.direction.x, keyLightSettings.direction.y, keyLightSettings.direction.z);
        keyLight.intensity = keyLightSettings.intensity;
        keyLight.position = keyLightPosition;
        keyLight.direction = keyLightDirection;

        const fillLightSettings = lightSettings.directionalLightSettings.fillLightSettings;
        const fillLightPosition = new BABYLON.Vector3(fillLightSettings.position.x, fillLightSettings.position.y, fillLightSettings.position.z);
        const fillLightDirection = new BABYLON.Vector3(fillLightSettings.direction.x, fillLightSettings.direction.y, fillLightSettings.direction.z);
        fillLight.intensity = fillLightSettings.intensity;
        fillLight.position = fillLightPosition;
        fillLight.direction = fillLightDirection;

        const backLightSettings = lightSettings.directionalLightSettings.backLightSettings;
        const backLightPosition = new BABYLON.Vector3(backLightSettings.position.x, backLightSettings.position.y, backLightSettings.position.z);
        const backLightDirection = new BABYLON.Vector3(backLightSettings.direction.x, backLightSettings.direction.y, backLightSettings.direction.z);
        backLight.intensity = backLightSettings.intensity;
        backLight.position = backLightPosition;
        backLight.direction = backLightDirection;

        pointLight1 && pointLight1.setEnabled(false);
        pointLight2 && pointLight2.setEnabled(false);
        pointLight3 && pointLight3.setEnabled(false);
        pointLight4 && pointLight4.setEnabled(false);
      }
      else if (lightSettings.type === "point") {
        shadowType = "dynamic"
        ambientLight && ambientLight.setEnabled(false);
        keyLight && keyLight.setEnabled(false);
        backLight && backLight.setEnabled(false);
        fillLight && fillLight.setEnabled(false);
        pointLight1 && pointLight1.setEnabled(true);
        pointLight2 && pointLight2.setEnabled(true);
        pointLight3 && pointLight3.setEnabled(true);
        pointLight4 && pointLight4.setEnabled(true);

        const pointLight1Settings = lightSettings.pointLightSettings.pointLight1Settings;
        const pointLight1Position = new BABYLON.Vector3(pointLight1Settings.position.x, pointLight1Settings.position.y, pointLight1Settings.position.z);
        pointLight1.intensity = pointLight1Settings.intensity;
        pointLight1.position = pointLight1Position;
        pointLight1.diffuse = BABYLON.Color3.FromHexString(pointLight1Settings.diffuseColor);

        const pointLight2Settings = lightSettings.pointLightSettings.pointLight2Settings;
        const pointLight2Position = new BABYLON.Vector3(pointLight2Settings.position.x, pointLight2Settings.position.y, pointLight2Settings.position.z);
        pointLight2.intensity = pointLight2Settings.intensity;
        pointLight2.position = pointLight2Position;
        pointLight2.diffuse = BABYLON.Color3.FromHexString(pointLight2Settings.diffuseColor);

        const pointLight3Settings = lightSettings.pointLightSettings.pointLight3Settings;
        const pointLight3Position = new BABYLON.Vector3(pointLight3Settings.position.x, pointLight3Settings.position.y, pointLight3Settings.position.z);
        pointLight3.intensity = pointLight3Settings.intensity;
        pointLight3.position = pointLight3Position;
        pointLight3.diffuse = BABYLON.Color3.FromHexString(pointLight3Settings.diffuseColor);

        const pointLight4Settings = lightSettings.pointLightSettings.pointLight4Settings;
        const pointLight4Position = new BABYLON.Vector3(pointLight4Settings.position.x, pointLight4Settings.position.y, pointLight4Settings.position.z);
        pointLight4.intensity = pointLight4Settings.intensity;
        pointLight4.position = pointLight4Position;
        pointLight4.diffuse = BABYLON.Color3.FromHexString(pointLight4Settings.diffuseColor);
      }
      else if (lightSettings.type === "pointAmbient") {
        shadowType = "dynamic"
        ambientLight && ambientLight.setEnabled(true);
        keyLight && keyLight.setEnabled(false);
        backLight && backLight.setEnabled(false);
        fillLight && fillLight.setEnabled(false);
        pointLight1 && pointLight1.setEnabled(true);
        pointLight2 && pointLight2.setEnabled(true);
        pointLight3 && pointLight3.setEnabled(true);
        pointLight4 && pointLight4.setEnabled(false);

        const pointLight1Settings = lightSettings.pointLightSettings.pointLight1Settings;
        const pointLight1Position = new BABYLON.Vector3(pointLight1Settings.position.x, pointLight1Settings.position.y, pointLight1Settings.position.z);
        pointLight1.intensity = pointLight1Settings.intensity;
        pointLight1.position = pointLight1Position;
        pointLight1.diffuse = BABYLON.Color3.FromHexString(pointLight1Settings.diffuseColor);

        const pointLight2Settings = lightSettings.pointLightSettings.pointLight2Settings;
        const pointLight2Position = new BABYLON.Vector3(pointLight2Settings.position.x, pointLight2Settings.position.y, pointLight2Settings.position.z);
        pointLight2.intensity = pointLight2Settings.intensity;
        pointLight2.position = pointLight2Position;
        pointLight2.diffuse = BABYLON.Color3.FromHexString(pointLight2Settings.diffuseColor);

        const pointLight3Settings = lightSettings.pointLightSettings.pointLight3Settings;
        const pointLight3Position = new BABYLON.Vector3(pointLight3Settings.position.x, pointLight3Settings.position.y, pointLight3Settings.position.z);
        pointLight3.intensity = pointLight3Settings.intensity;
        pointLight3.position = pointLight3Position;
        pointLight3.diffuse = BABYLON.Color3.FromHexString(pointLight3Settings.diffuseColor);

        const ambientLightSettings = lightSettings.pointLightSettings.pointLight4Settings;
        const ambientLightPosition = new BABYLON.Vector3(ambientLightSettings.position.x, ambientLightSettings.position.y, ambientLightSettings.position.z);
        ambientLight.intensity = ambientLightSettings.intensity;
        ambientLight.position = ambientLightPosition;
        ambientLight.diffuse = BABYLON.Color3.FromHexString(ambientLightSettings.diffuseColor);
        ambientLight.specular = BABYLON.Color3.FromHexString(ambientLightSettings.diffuseColor);
        ambientLight.groundColor = BABYLON.Color3.FromHexString(ambientLightSettings.diffuseColor);
      }
      generateShadows(scene, shadowType, lightTypeUsedForShadow);
      setTimeout(() => {
        scene.getEngine().hideLoadingUI()
        // scene.debugLayer.show()
      }, 300)
    }
  }



  const createScene = (scene, assetKey) => {
    scene.getEngine().displayLoadingUI();
    setCamera(scene, assetKey);
    resetCamera(scene);
    ImportClothModel(scene, assetKey);
    scene.executeWhenReady(se => {
      //Used to stop the loader
      const sceneObject = { ...sceneRef.current, [assetKey]: scene };
      setHideBody(false);
      setSceneObj(sceneObject)
      scene.clearColor = new BABYLON.Color3(1, 1, 1);
      const brand = get(brandOption, 'value', '');
      const skyboxList = getSkyboxConfig(brand);
      setSkyboxList(skyboxList);
      // if (isIos()) {
      //   const fxaa = new BABYLON.FxaaPostProcess("fxaa", 1, scene.activeCamera);
      // }
      const selectedSkybox2 = !isEmpty(selectedSkybox) ? selectedSkybox : skyboxList[0]
      createEnvironment(scene, selectedSkybox2, false);
      Object.keys(assetsObject).map(assetKey => {
        if (sceneObject[assetKey]) {
          moveActiveCamera(sceneObject[assetKey], cameraMode);
        }
      })
    }, true);
    scene.onPointerObservable.add((pointerInfo) => {
      // eslint-disable-next-line default-case
      switch (pointerInfo.type) {
        case BABYLON.PointerEventTypes.POINTERTAP:
          resetCamera(scene);
          break;
      }
    })
  }

  const onBrandsChange = (e, value) => {
    setBrandOption(value);
    if (value.value !== brandOption.value) {
      setSelectedProductOption(null);
      const brand = get(value, 'value', '');
      fetchProducts(brand);
      setSceneObj({});
      setSelectedSkybox({});
      setSkyboxList([]);
      toggleEnvironment(false);
    }
  }
  const onProductsChange = (e, value) => {
    setSelectedProductOption(value);
    // const brand = get(brandOption, 'value', '');
    // fetchProducts(brand, value);
    setSceneObj({});
  }

  const onBodyTypeChange = (e, value) => {
    setSelectedBodyTypeOption(value);
    setSceneObj({});
  }


  const createGUI = (scene, selectedSkybox) => {
    if (loadedGUI) {
      loadedGUI.destroy();
    }
    const gui = new dat.GUI({ name: "datGUI" });
    gui.domElement.id = "datGUI";
    gui.domElement.style.marginTop = "0px";
    gui.domElement.style.zIndex = 1;
    gui.domElement.style.position = "absolute";
    gui.domElement.style.right = 0;
    const {
      path,
      skyboxIntensity,
      rotation,
      keyIntensity,
      fillIntensity,
      backIntensity,
      acesEnabled
    } = selectedSkybox
    var options = {
      Skybox_Intensity: skyboxIntensity,
      // Skybox_Exposure: skyboxIntensity,
      // Skybox_Contrast: skyboxIntensity,
      Key_Intensity: keyIntensity,
      Fill_Intensity: fillIntensity,
      Back_Intensity: backIntensity,
      Point1_Intensity: keyIntensity,
      Point1_X_Move: 0.2,
      Point1_Y_Move: 1,
      Point1_Z_Move: 0.2,
      Point2_Intensity: keyIntensity,
      Point2_X_Move: 0.2,
      Point2_Y_Move: 1,
      Point2_Z_Move: 0.2,
      Point3_Intensity: keyIntensity,
      Point3_X_Move: 0.2,
      Point3_Y_Move: 1,
      Point3_Z_Move: 0.2,
      Bump_Texture: 2,
      Use_ACES_ToneMap: acesEnabled
    }

    gui.add(options, "Skybox_Intensity", 0.0, 5.0).onChange(function (value) {
      scene.environmentIntensity = value;
      scene.imageProcessingConfiguration.exposure = value;
      scene.imageProcessingConfiguration.contrast = value;
    });
    // gui.add(options, "Skybox_Exposure", 0.0, 5.0).onChange(function (value) {
    //   scene.imageProcessingConfiguration.exposure = value;
    // });
    // gui.add(options, "Skybox_Contrast", 0.0, 5.0).onChange(function (value) {
    //   scene.imageProcessingConfiguration.contrast = value;
    // });
    let keyLight = scene.getLightByName("directionalKeyLight");
    let fillLight = scene.getLightByName("directionalFillLight");
    let backLight = scene.getLightByName("directionalBackLight");
    // let pointLight1 = scene.getLightByName('pointLight1');
    // let pointLight2 = scene.getLightByName('pointLight2');
    // let pointLight3 = scene.getLightByName('pointLight3');
    gui.add(options, "Key_Intensity", 0.0, 15).name("LeftLight_Intensity").onChange(function (value) {
      keyLight.intensity = value
    });
    gui.add(options, "Fill_Intensity", 0.0, 15).name("RightLight_Intensity").onChange(function (value) {
      fillLight.intensity = value
    });
    gui.add(options, "Back_Intensity", 0.0, 15).name("BackLight_Intensity").onChange(function (value) {
      backLight.intensity = value
    });
    // gui.add(options, "Point1_Intensity", 0.0, 15).name("PointLight1_Intensity").onChange(function (value) {
    //   pointLight1.intensity = value
    // });
    // gui.add(options, "Point1_X_Move", -1.0, 1.0).name("PointLight1_X_Move").onChange(function (value) {
    //   pointLight1.position.x = value
    // });
    // gui.add(options, "Point1_Y_Move", 0.0, 2.0).name("PointLight1_Y_Move").onChange(function (value) {
    //   pointLight1.position.y = value
    // });
    // gui.add(options, "Point1_Z_Move", -1.0, 1.0).name("PointLight1_Z_Move").onChange(function (value) {
    //   pointLight1.position.z = value
    // });
    // gui.add(options, "Point2_Intensity", 0.0, 15).name("PointLight2_Intensity").onChange(function (value) {
    //   pointLight2.intensity = value
    // });
    // gui.add(options, "Point2_X_Move", -1.0, 1.0).name("PointLight2_X_Move").onChange(function (value) {
    //   pointLight2.position.x = value
    // });
    // gui.add(options, "Point2_Y_Move", 0.0, 2.0).name("PointLight2_Y_Move").onChange(function (value) {
    //   pointLight2.position.y = value
    // });
    // gui.add(options, "Point2_Z_Move", -1.0, 1.0).name("PointLight2_Z_Move").onChange(function (value) {
    //   pointLight2.position.z = value
    // });
    // gui.add(options, "Point3_Intensity", 0.0, 15).name("PointLight3_Intensity").onChange(function (value) {
    //   pointLight3.intensity = value
    // });
    // gui.add(options, "Point3_X_Move", -1.0, 1.0).name("PointLight3_X_Move").onChange(function (value) {
    //   pointLight3.position.x = value
    // });
    // gui.add(options, "Point3_Y_Move", 0.0, 2.0).name("PointLight3_Y_Move").onChange(function (value) {
    //   pointLight3.position.y = value
    // });
    // gui.add(options, "Point3_Z_Move", -1.0, 1.0).name("PointLight3_Z_Move").onChange(function (value) {
    //   pointLight3.position.z = value
    // });
    // gui.add(options, "Bump_Texture", 0.0, 15).onChange(function (value) {
    //   scene.materials.forEach((material) => {
    //     if (material && material.bumpTexture)
    //       material.bumpTexture.level = value;
    //   });
    // });

    gui.add(options, "Use_ACES_ToneMap").onChange(function (value) {
      scene.imageProcessingConfiguration.toneMappingEnabled = acesEnabled;
      if (value)
        scene.imageProcessingConfiguration.toneMappingType = BABYLON.ImageProcessingConfiguration.TONEMAPPING_ACES;
      else
        scene.imageProcessingConfiguration.toneMappingType = BABYLON.ImageProcessingConfiguration.TONEMAPPING_STANDARD;
    });
    setloadedGUI(gui);
  }

  const toggleBody = () => {
    setHideBody(!hideBody);
  }


  const Select = (props) => {
    const { options, onChange, value, onInputChange, inputValue, label, className, disabled = false } = props;
    return (
      <Autocomplete
        className={className}
        id="country-select-demo"
        sx={{ width: 300 }}
        onChange={onChange}
        disabled={disabled}
        options={options}
        autoHighlight
        value={value}
        inputValue={inputValue}
        onInputChange={onInputChange}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            {option.src && <img
              loading="lazy"
              width="20"
              src={option.src}
              alt=""
            />}
            {option.label}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      />
    );
  }

  const assets = assetsObject && Object.keys(assetsObject);
  const imageSrc = get(selectedProductOption, 'src', '');
  if (notFound) {
    return <div className='Dopplr__PageNotFound'>Demo not found. Please check if the link is valid.</div>
  }
  return (
    <div className='Dopplr__AssetViewer'>
      {productsLoading && <Loader className={'Dopplr__AssetViewer__Loader'} />}
      {!productsLoading && <>
        <div className='Dopplr__AssetViewer__Left'>
          <div className='Dopplr__AssetViewer__Selections'>
            {/* <Select
          label={"Select a brand"}
          className={'Dopplr__AssetViewer__AutoComplete'}
          options={BRANDS}
          onChange={onBrandsChange}
          value={brandOption}
          onInputChange={(e, value) => setBrandInput(value)}
          inputValue={brandInput}
        /> */}
            <Select
              label={"Select a product"}
              className={'Dopplr__AssetViewer__AutoComplete'}
              options={productOptions}
              onChange={onProductsChange}
              value={selectedProductOption}
              onInputChange={(e, value) => setProductInput(value)}
              inputValue={productInput}
            />
            {/* <Select
          label={"Select body type"}
          className={'Dopplr__AssetViewer__AutoComplete'}
          options={bodyOptions}
          onChange={onBodyTypeChange}
          value={selectedBodyTypeOption}
          onInputChange={(e, value) => setBodyTypeInput(value)}
          inputValue={bodyTypeInput}
        /> */}
            {skyboxList && skyboxList.length > 0 && <img src={showEnvironments ? SkyBoxWhite : SkyBoxBlack} alt="Skybox" className={`${showEnvironments ? 'show' : ''}`} onClick={() => toggleEnvironment(!showEnvironments)} />}
            {!isEmpty(sceneObj) && <div>
              <Switch
                checked={hideBody}
                onChange={toggleBody}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              Hide Body
            </div>}
          </div>
          {imageSrc && <div className='Dopplr__AssetViewer__ProductImage'><img src={imageSrc} alt="product" /></div>}
        </div>
        <div className='Dopplr__AssetViewer__Container'>
          {loading && <Loader className={'Dopplr__AssetViewer__Loader'} />}
          {assets && assets.length > 0 && !loading && assets.map((assetKey, index) => {
            const label = assetKey.split("__")[0];
            return <div key={index} className={'Dopplr__AssetViewer__Scene'}>
              {/* <div className='Dopplr__AssetViewer__Label'>{label}</div> */}
              <SceneComponent
                onSceneReady={(scene) => createScene(scene, assetKey)}
                loader={sceneLoading}
                setLoader={setSceneLoading}
                style={{ display: 'block', width: '100%', height: '100%' }}
              />
            </div>
          })}
        </div>
        {!isEmpty(sceneObj) && <CameraActionBar
          setCameraMode={setCameraMode}
          closeActions={()=>{
            setCameraActionOpened(false);
          }}
          setCameraActionOpened={setCameraActionOpened}
          isCameraActionOpened={isCameraActionOpened}
          cameraMode={cameraMode}
          eventCategory={EVENT_CATEGORIES.ASSET_VIEWER}
        />}
        <div className={`Dopplr__SkyboxWrapperSandbox${showEnvironments ? ' show' : ''}`}>
          {skyboxList && skyboxList.length > 0 && <div className='Dopplr__SkyboxWrapperSandbox__List'>
            {skyboxList.map(skybox => {
              const { thumbnailImage, id } = skybox;
              const selected = selectedSkybox && id === selectedSkybox.id;
              return <div key={id} className={`Dopplr__SkyboxWrapperSandbox__List__Skybox__Item ${selected ? ' selected' : 'overlay'}`} onClick={() => setSelectedSkybox(skybox)}>
                <img className='Dopplr__SkyboxWrapperSandbox__List__Skybox__Thumbnail' src={thumbnailImage ? thumbnailImage : NoSkybox} alt="" />
              </div>
            })}
          </div>}
        </div>
      </>}
    </div >
  )
}

export default Sandbox;