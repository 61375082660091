export default class DopplrPlugin {
    constructor(src, containerSelector, options) {
        this.productData = options.productData;
        this.catalogList = options.productList || [];
        this.containerSelector = containerSelector;
        this.domain = options.domain;
        this.iFrame = null;
        this.src = src;
        this.isMounted = false;
        this.initialise();
    };
    recieveMessage = (event) => {
        const { message, value } = event?.data || {};
        switch (message) {
            case 'APP_MOUNTED': {
                this.isMounted = true;
                this.sendData(this.productData, this.catalogList);
                break;
            }
            case 'IFRAME_LOADED': {
                console.log("IFRAME LOADED");
                const dopplrUD = localStorage.getItem('dopplrUD') || '{}';
                this.iFrame?.contentWindow?.postMessage({ message: "USER_DATA", value: JSON.stringify(dopplrUD) }, "*");
                break;
            }
            case 'SET_USER_DATA': {
                console.log('SET_USER_DATA', value);
                if (value) {
                    localStorage.setItem('dopplrUD', JSON.stringify(value));
                } else {
                    localStorage.removeItem('dopplrUD');
                }
                break;
            }
            case 'NEW_PRODUCT_SELECTED': {
                this.productData = value;
                this.sendData(this.productData, this.catalogList);
                break;
            }
            default: break;
        }
    }

    initialise() {
        let container = document.querySelector(this.containerSelector);
        this.iFrame = document.createElement('iframe');
        this.iFrame.id = 'dopplr-plugin';
        this.iFrame.src = `${this.src}${this.domain ? this.domain : ''}`;
        this.iFrame.setAttribute("style", "height:100%;width:100%;position:relative");
        container.appendChild(this.iFrame);
        window.addEventListener("message", this.recieveMessage, false)
    };
    sendData(postData, catalogList) {
        this.productData = postData;
        this.iFrame?.contentWindow?.postMessage(
            {
                message: "SEND_PRODUCT_DATA", value: { productData: postData, catalogList, showShareOption: true }
            },
            "*"
        );
    }
    togglePluginView(val) {
        this.iFrame?.contentWindow?.postMessage({ message: val ? 'PRODUCT_OPENED': 'PRODUCT_CLOSED'}, "*");
    }
}
