import requestMaker from "lib";

export const getProducts = (params) => {
    const baseQuery = `/proxy/prv/cs/gp`;
    return requestMaker(baseQuery, { params, payload: {} });
}

export const getProductDetails = (params) => {
    const baseQuery = `/proxy/prv/cs/gpd`;
    return requestMaker(baseQuery, { params, payload: {} });
}