
import { motion } from 'framer-motion';
export const FloatingAction = (props) => {
  const { clickHandler = () => { }, children, isActive, text = '', className = '', buttonClassName = '', rotation = true, itemVariants } = props;
  const variants = {
    open: {
      rotate: rotation ? [0, 360] : [0], transition: { duration: 0.5 }
    },
    closed: { rotate: rotation ? [360, 0] : [0], transition: { duration: 0.5 } }
  }
  return (
    <motion.div
      // whileHover={{ scale: 1.05 }}
      // whileTap={{ scale: 0.97 }}
      className={className}
      // variants={itemVariants}
      onClick={clickHandler}
    >
      <motion.div
        animate={isActive ? "open" : "closed"}
        variants={variants}
        className={buttonClassName}>
        {children}
      </motion.div>
      <div className='Dopplr_PrimaryActionsV2__Text'>{text}</div>
    </motion.div>
  )
}
