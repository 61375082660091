import React, { useState, useEffect } from "react";
import IframeSectionCopy from "./IframeSectionCopy.js";
import Button from "components/Button";
import A1 from "../../assets/icons/dress1.jpg";
import A2 from "../../assets/icons/dress2.jpg";
import A3 from "../../assets/icons/dress3.jpg";
import Logo from "../../assets/icons/Logo.png";
import "./DummyProductCopy.scss";
const productList = [
  {
    id: 0,
    src: A3,
    name: "Cowl-Neck Cap Sleeve Velvet Maxi Dress with Pockets",
    labels: "After Six Style 1535  |  Lux Velvet",
    description:
      "Show off your shoulders with this sleek cowl maxi dress, featuring a low ballet back. A full a-line skirt with pockets adds a functional touch to this fabulously fashionable dress.",
    bullet_description: [
      "pockets",
      "center back zip",
      "00-30W and 00-30W extra length",
      "100% poly",
      "dry clean",
      "imported"
    ],
    price: "$210"
  },
  {
    id: 1,
    src: A1,
    name: "Cowl-Neck Cap Sleeve Velvet Maxi Dress with Pockets",
    labels: "After Six Style 1535  |  Lux Velvet",
    description:
      "Show off your shoulders with this sleek cowl maxi dress, featuring a low ballet back. A full a-line skirt with pockets adds a functional touch to this fabulously fashionable dress.",
    bullet_description: [
      "pocketsssss",
      "center back zip",
      "00-30W and 00-30W extra length",
      "100% poly",
      "dry clean",
      "imported"
    ],
    price: "$208"
  },
  {
    id: 2,
    src: A2,
    name: "Cowl-Neck Cap Sleeve Velvet Maxi Dress with Pockets",
    labels: "After Six Style 1535  |  Lux Velvet",
    description:
      "Show off your shoulders with this sleek cowl maxi dress, featuring a low ballet back. A full a-line skirt with pockets adds a functional touch to this fabulously fashionable dress.",
    bullet_description: [
      "pockets",
      "center back zip",
      "00-30W and 00-30W extra length",
      "100% poly",
      "dry clean",
      "imported"
    ],
    price: "$208"
  },
  // {
  //   id: 3,
  //   src: A2,
  //   name: "Cowl-Neck Cap Sleeve Velvet Maxi Dress with Pockets",
  //   labels: "After Six Style 1535  |  Lux Velvet",
  //   description:
  //       "Show off your shoulders with this sleek cowl maxi dress, featuring a low ballet back. A full a-line skirt with pockets adds a functional touch to this fabulously fashionable dress.",
  //   bullet_description: [
  //     "pockets",
  //     "center back zip",
  //     "00-30W and 00-30W extra length",
  //     "100% poly",
  //     "dry clean",
  //     "imported"
  //   ],
  //   price: "$208"
  // }
];
const DummyProductCopy = () => {
  const [selected, setSelectedProduct] = useState(productList[0]);
  const baseUrl = process.env.REACT_APP_DEBUG_MODE === 'true' ? window.location.origin: "https://dopplr.highstreet3d.org";
  const selectProduct = product => {
    setSelectedProduct(product);
    const frame = document.getElementById("myFrame");
    frame.contentWindow.postMessage(
      { message: "SEND_PRODUCT_DATA", value: product },
      `${baseUrl}/dopplr-avatar-old`
    );
  };
  return (
    <div className="Home">
      <div className="Header">
        <div>
          <img src={Logo} />
        </div>
      </div>
      <div className="PageWrapper">
        <div className="leftContent">
          <div className="ProductList">
            {productList.map((product, i) => {
              const isSelected = selected && selected.id === product.id;
              return (
                <div
                  key={i}
                  onClick={() => selectProduct(product)}
                  className={`ProductCard`}
                >
                  <div
                    className={`ProductCard__Image ${
                      isSelected ? "selected" : "overlay"
                    }`}
                  >
                    <img src={product.src} />
                  </div>
                  <div
                    className={`ProductCard__Description ${
                      isSelected ? "selectedText" : ""
                    }`}
                  >
                    {/* {product.name} */}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="SelectedProduct">
            <div className="SelectedProduct__Image">
              <img src={selected.src} />
            </div>
          </div>
        </div>
        <div className="SelectedProduct__Description">
          <div className="SelectedProduct__Name">{selected.name}</div>
          <div className="SelectedProduct__PriceContainer">
            <div className="SelectedProduct__Labels">{selected.labels}</div>
            <div className="SelectedProduct__Price">{selected.price}</div>
          </div>
          <div className="SelectedProduct__Action">
            <Button
              type="black-white"
              className="CreateAvatar"
              text="Add to Cart"
            />
            <Button
              type="white-black"
              className="CreateAvatar"
              text="Buy Now"
            />
          </div>
          <div className="SelectedProduct__Description">
            <div className="SelectedProduct__Label">Description</div>
            <div className="SelectedProduct__ShortDescription">
              {selected.description}
            </div>
            <ul className="SelectedProduct__BulletDescription">
              {selected.bullet_description.map(desc => {
                return <li>{desc}</li>;
              })}
            </ul>
          </div>
        </div>
      </div>
      <IframeSectionCopy productList={productList} />
    </div>
  );
};

export default DummyProductCopy;
