import React from 'react';
import PropTypes from 'prop-types';
import './Checkbox.scss';

const Checkbox = props => {
  const { isSelected, onChange } = props;
  return (
    <input
      className={`agreementCheckbox`}
      type="checkbox"
      data-testid="agreementCheckbox"
      checked={isSelected}
      onChange={onChange}
    />
  );
};

Checkbox.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

Checkbox.defaultProps = {
  isSelected: false,
  onChange: () => {},
};

export default Checkbox;
