import React from 'react';
import { motion } from 'framer-motion';

const SizeSelector = ({
    fit,
    selectedProductSize,
    recommendedVariants,
    productType,
    setSelectedProductSize,
    options = [],
    isMultiOptions = false,
    productPath,
    productCounterPart,
    showAllSizes
}) => {
    const { normalFit, tightFit, looseFit } = fit?.[productPath] || {};
    const { normalFit: counterNormalFit, tightFit: counterTightFit, looseFit: counterLooseFit } = fit?.[productCounterPart] || {};
    const sizeChangeHandler = (selectedFit, value) => {
        let variants = recommendedVariants;
        let selectedVariant = variants?.[selectedFit]?.find(variant => variant.option1 === value);
        if (selectedVariant?.available) {
            setSelectedProductSize(selectedVariant);
        }
    }
    const multipOptionSizeChangeHandler = (value, optionType, type) => {
        const productSize = selectedProductSize;
        const recommendedVariant = recommendedVariants;
        let option1 = productSize?.option1
        let option2 = productSize?.option2;
        if (optionType === 'option2') {
            option2 = value;
        } else {
            option1 = value;
        }
        const variantList = []
        Object.values(recommendedVariant).forEach(list => {
            variantList.push(...list);
        });
        const selectedVariant = variantList.find(variant => variant.option1 === option1 && variant.option2 === option2);
        if (selectedVariant?.available) {
            setSelectedProductSize(selectedVariant);
        }
    }
    const multiOptionGetClassName = (value, optionType, type) => {
        let className = '';
        const productSize = selectedProductSize;
        const recommendedVariant = recommendedVariants;
        let option1 = productSize?.option1
        let option2 = productSize?.option2;
        if (optionType === 'option2') {
            option2 = value;
        } else {
            option1 = value;
        }
        const variantList = []
        Object.values(recommendedVariant).forEach(list => {
            variantList.push(...list);
        });
        const selectedVariant = variantList.find(variant => variant.option1 === option1 && variant.option2 === option2);
        if (selectedVariant && selectedVariant.available) {
            className = selectedVariant.id === productSize.id ? 'selected' : '';
        } else {
            className = 'disabled';
        }
        return className;
    }

    const getClassName = (fitting, value) => {
        let className = '';
        const productSize = selectedProductSize;
        const recommendedVariant = recommendedVariants;

        const fittingVariants = recommendedVariant[fitting];
        const variant = fittingVariants?.find(variant => {
            return variant.option1 === value;
        });
        if (variant && variant.available) {
            className = variant.id === productSize.id ? 'selected' : '';
        } else {
            className = 'disabled';
        }
        return className;
    };
    if (isMultiOptions) {
        return <div className="Dopplr__CartSection__Card__SizeSection">
            <div className="Dopplr__CartSection__Card__SizeSection__Title">Select size</div>
            {options?.[0] && <div className="Dopplr__CartSection__Card__SizeSection__Label">{options[0]}</div>}
            <div className='Dopplr__CartSection__Card__Sizes'>
                {tightFit && <motion.div
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.97 }}
                    className='Dopplr_CartSize__FloatingAction'
                    onClick={() => multipOptionSizeChangeHandler(tightFit, 'option1', productType)}>
                    <motion.div
                        className={`Dopplr_CartSize__FloatingButton ${multiOptionGetClassName(tightFit, 'option1', productType)}`}>
                        {tightFit}
                    </motion.div>
                </motion.div>}
                {normalFit && <motion.div
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.97 }}
                    className='Dopplr_CartSize__FloatingAction'
                    onClick={() => multipOptionSizeChangeHandler(normalFit, 'option1', productType)}>
                    <motion.div
                        className={`Dopplr_CartSize__FloatingButton ${multiOptionGetClassName(normalFit, 'option1', productType)}`}>
                        {normalFit}
                    </motion.div>
                </motion.div>}
                {looseFit && <motion.div
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.97 }}
                    className='Dopplr_CartSize__FloatingAction'
                    onClick={() => multipOptionSizeChangeHandler(looseFit, 'option1', productType)}>
                    <motion.div
                        className={`Dopplr_CartSize__FloatingButton ${multiOptionGetClassName(looseFit, 'option1', productType)}`}>
                        {looseFit}
                    </motion.div>
                </motion.div>}
            </div>
            <div className='Dopplr__CartSection__Card__SizeSection__RecommendedSize'>Recommended Size: <span>{normalFit}</span></div>
            {options?.[1] && <div className="Dopplr__CartSection__Card__SizeSection__Label">{options[1]}</div>}
            <div className='Dopplr__CartSection__Card__Sizes'>
                {counterTightFit && <motion.div
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.97 }}
                    className='Dopplr_CartSize__FloatingAction'
                    onClick={() => multipOptionSizeChangeHandler(counterTightFit, 'option2', productType)}>
                    <motion.div
                        className={`Dopplr_CartSize__FloatingButton ${multiOptionGetClassName(counterTightFit, 'option2', productType)}`}>
                        {counterTightFit}
                    </motion.div>
                </motion.div>}
                {counterNormalFit && <motion.div
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.97 }}
                    className='Dopplr_CartSize__FloatingAction'
                    onClick={() => multipOptionSizeChangeHandler(counterNormalFit, 'option2', productType)}>
                    <motion.div
                        className={`Dopplr_CartSize__FloatingButton ${multiOptionGetClassName(counterNormalFit, 'option2', productType)}`}>
                        {counterNormalFit}
                    </motion.div>
                </motion.div>}
                {counterLooseFit && <motion.div
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.97 }}
                    className='Dopplr_CartSize__FloatingAction'
                    onClick={() => multipOptionSizeChangeHandler(counterLooseFit, 'option2', productType)}>
                    <motion.div
                        className={`Dopplr_CartSize__FloatingButton ${multiOptionGetClassName(counterLooseFit, 'option2', productType)}`}>
                        {counterLooseFit}
                    </motion.div>
                </motion.div>}
            </div>
            <div className='Dopplr__CartSection__Card__SizeSection__RecommendedSize'>Recommended Size: <span>{counterNormalFit}</span></div>
        </div>
    }
    return (
        <div className="Dopplr__CartSection__Card__SizeSection">
            <div className="Dopplr__CartSection__Card__SizeSection__Title">Select a size</div>
            {
                showAllSizes ?
                    <div className='Dopplr__CartSection__Card__Sizes'>
                        {
                            recommendedVariants?.normalFit.map(variant => {
                                return <motion.div
                                    whileHover={{ scale: 1.02 }}
                                    whileTap={{ scale: 0.97 }}
                                    className='Dopplr_CartSize__FloatingAction'
                                    onClick={() => sizeChangeHandler('normalFit', variant.option1)}>
                                    <motion.div
                                        className={`Dopplr_CartSize__FloatingButton ${getClassName('normalFit', variant.option1)}`}>
                                        {variant.option1}
                                    </motion.div>
                                </motion.div>
                            })}
                    </div>
                    : <div className='Dopplr__CartSection__Card__Sizes'>
                        {tightFit && <motion.div
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.97 }}
                            className='Dopplr_CartSize__FloatingAction'
                            onClick={() => sizeChangeHandler('tightFit', tightFit)}>
                            <motion.div
                                className={`Dopplr_CartSize__FloatingButton ${getClassName('tightFit', tightFit)}`}>
                                {tightFit}
                            </motion.div>
                        </motion.div>}
                        {normalFit && <motion.div
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.97 }}
                            className='Dopplr_CartSize__FloatingAction'
                            onClick={() => sizeChangeHandler('normalFit', normalFit)}>
                            <motion.div
                                className={`Dopplr_CartSize__FloatingButton ${getClassName('normalFit', normalFit)}`}>
                                {normalFit}
                            </motion.div>
                        </motion.div>}
                        {looseFit && <motion.div
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.97 }}
                            className='Dopplr_CartSize__FloatingAction'
                            onClick={() => sizeChangeHandler('looseFit', looseFit)}>
                            <motion.div
                                className={`Dopplr_CartSize__FloatingButton ${getClassName('looseFit', looseFit)}`}>
                                {looseFit}
                            </motion.div>
                        </motion.div>}
                    </div>}
        </div>
    )
}

export default SizeSelector
