import React from 'react'
import { useAuth } from "contexts/AuthContext";
import { useSelector, useDispatch } from "react-redux";
import { actionsCreator } from "redux/actions/actionsCreator";
import { LoginIcon, LogoutIcon } from '@heroicons/react/outline';
import { motion } from 'framer-motion';
import get from 'lodash/get';

const mapStateToProps = ({ avatarReducer = {} }) => ({
    faceUrl: get(avatarReducer, "userProfile.userAvatar.faceUrl", ""),
    userProfile: get(avatarReducer, "userProfile", {}),
    isAnonymous: get(avatarReducer, 'isAnonymous', true),
    oldUserId: get(avatarReducer, 'oldUserId', ''),
    token: get(avatarReducer, 'token', false),
});
function LoginAction({ className, setReplaceAvatarLoader }) {
    const {
        faceUrl,
        userProfile = {},
        isAnonymous = true,
    } = useSelector(
        mapStateToProps
    );
    const { signInWithGoogle, logout } = useAuth();
    const isLoggedIn = !isAnonymous;
    const dispatch = useDispatch();
    const signout = async (e) => {
        e.preventDefault();
        try {
            const response = await logout();
            if(window?.localStorage !== undefined) {
                localStorage.removeItem('dopplrUD');
            }
            dispatch(actionsCreator.SET_IS_ANONYMOUS(true));
        } catch (e) {
            console.log(e);
        }
    }
    const loginHandler = (e) => {
        if (isLoggedIn) {
            signout(e);
        } else {
            googleSignInHandler(e);
            // setShowSignInForm(!showSignInForm);
        }
    }
    const googleSignInHandler = async (e) => {
        e.preventDefault();
        try {
            const { id, defaultAvatar } = userProfile;
            const oldUserId = (!defaultAvatar || faceUrl) && isAnonymous && id;
            if (oldUserId) {
                dispatch(actionsCreator.SET_OLD_USER_ID(oldUserId));
            }
            const response = await signInWithGoogle();
            // setReplaceAvatarLoader(!!oldUserId);
        } catch (e) {
            console.log(e);
        }
    }
    return (
        <motion.div
            className={className}
            onClick={loginHandler}
        >
            {isLoggedIn ?
                <LogoutIcon className='Dopplr_PrimaryActionsV2__FloatingIcon' /> :
                <LoginIcon className='Dopplr_PrimaryActionsV2__FloatingIcon' />
            }
        </motion.div>
    )
}

export default LoginAction;