import React, { useState, useEffect } from "react";
import "./IframeSection.scss";

const IframeSectionCopy = () => {
  const [showPlugin, togglePluginView] = useState(undefined);
  const baseUrl = process.env.REACT_APP_DEBUG_MODE === 'true' ? window.location.origin: "https://dopplr.highstreet3d.org";
  return (
    <div
      className={`IframeSection ${
        showPlugin && showPlugin === true
          ? "show"
          : showPlugin === undefined
          ? ""
          : "hide"
      }`}
    >
      <iframe
        src={`${baseUrl}/dopplr-avatar-old`}
        style={{ width: "100%", height: "100%" }}
        id="myFrame"
      />
      <button
        className="DopplrPluginButton"
        onClick={() => togglePluginView(!showPlugin)}
      >
        <div style={{ position: "relative", top: "-10px" }}>
          {showPlugin ? "Hide" : "Show"}
        </div>
      </button>
    </div>
  );
};

export default IframeSectionCopy;
