import React from 'react';
import Tooltip from 'components/Tooltip';
import Zoom from '@mui/material/Zoom';
import HeatmapImageBottom from "assets/images/ComfortSignal.png";
import "./ComfortSignal.scss";
import { EVENT_ACTIONS, EVENT_CATEGORIES } from 'appAnalytics/eventCategories';
import MixPanel from 'appAnalytics/mixPanel';

const ComfortSignal = ({ selectedHeatmapSize, toggleHeatMapInfo, showHeatmapInfo, fitIndicators, changeHeatmapSize, plotRange, selectedTab }) => {
    const HTMLRecommendationToolTip = () => {
        return <div className="Dopplr_RecommendedTooltip">
            <div className='Dopplr_RecommendedTooltip__Title'>FIT MAP</div>
            <div className='Dopplr_RecommendedTooltip__Content'>Use the fit indicator to express if your chosen size is too loose, too tight, or just right!</div>
        </div>
    }
    const slim = Number(plotRange?.slim || 0);
    const regular = Number(plotRange?.regular || 0);
    const loose = Number(plotRange?.loose || 0);
    return (<>
        <div
            // initial={{ opacity: 0, scale: 0.5, y: '200px', transition: { duration: 0.3 } }}
            // animate={{ opacity: 1, scale: 1, y: '0', transition: { duration: 0.3 } }}
            className={`Dopplr__HeatmapLegendContainer`}>
            <Tooltip
                arrow
                TransitionComponent={Zoom}
                enterDelay={1000}
                leaveDelay={200}
                // title={TOOLTIP_TEXT.CheckFit}
                title={HTMLRecommendationToolTip()}
                placement='top-end'
                open={showHeatmapInfo}
            // onClose={() => showHeatmapInfo()}
            // onOpen={() => showHeatmapInfo('CheckFit')}
            >
                <div className={`Dopplr__HeatmapLegendContainer__InformationIcon ${showHeatmapInfo ? 'selected' : ''}`} onClick={() => toggleHeatMapInfo(!showHeatmapInfo)}>i</div>
            </Tooltip>
            <div className='Dopplr__HeatmapLegend'>
                {/* {normalisedfitIndicationValue && <div className='Dopplr__HeatmapLegend__IndicatorTop' style={{ left: `${normalisedfitIndicationValue}%` }} >{selectedHeatmapSize}</div>} */}
                {/* {normalisedfitIndicationValueBottom && <img src={FitIndicatorBottomImage} className='Dopplr__HeatmapLegend__IndicatorBottom' style={{ top: `${normalisedfitIndicationValueBottom}%`, width: '30px' }} />} */}
                {plotRange ? <div className='Dopplr__HeatmapLegend__Image'>
                    <div className='Dopplr__HeatmapLegend__Image__OutOfRangeLeft' />
                    <div style={{ position: 'relative', width: '100%', display: 'flex' }}>
                        {Object.keys(fitIndicators).map((indicatorKey, index) => {
                            const { size, value } = fitIndicators[indicatorKey];
                            console.log(`${size}_${value}_${indicatorKey}`);
                            if (value > 100) {
                                return null;
                            }
                            return <div
                                key={`${index}_${size}_${value}_${indicatorKey}`}
                                className={`Dopplr__HeatmapLegend__IndicatorTop ${size === selectedHeatmapSize ? 'selected' : ''}`}
                                style={{ left: `${value}%` }}
                                onClick={() => {
                                    MixPanel.buttonClicked(EVENT_CATEGORIES.HEATMAP, EVENT_ACTIONS.HEATMAP_SIZE_CHANGED, { size, selectedTab });
                                    changeHeatmapSize(indicatorKey)
                                }}
                            >
                                {size}
                            </div>
                        })}
                        <div className='Dopplr__HeatmapLegend__Image__Slim' style={{ width: `${slim}%` }}>
                            <div className='Dopplr__HeatmapLegend__Image__Text'>Skinny</div>
                        </div>
                        <div className='Dopplr__HeatmapLegend__Image__Regular' style={{ width: `${regular}%` }}>
                            <div className='Dopplr__HeatmapLegend__Image__Text'>Regular</div>
                        </div>
                        <div className='Dopplr__HeatmapLegend__Image__Loose' style={{ width: `${loose}%` }}>
                            <div className='Dopplr__HeatmapLegend__Image__Text'>Loose</div>
                        </div>
                    </div>
                    <div className='Dopplr__HeatmapLegend__Image__OutOfRangeRight' />
                </div> :
                    <>
                        {Object.keys(fitIndicators).map((indicatorKey, index) => {
                            const { size, value } = fitIndicators[indicatorKey];
                            return <div
                                key={`${index}_${size}_${value}_${indicatorKey}`}
                                className={`Dopplr__HeatmapLegend__IndicatorTop ${size === selectedHeatmapSize ? 'selected' : ''}`}
                                style={{ left: `${value}%` }}
                                onClick={() => {
                                    MixPanel.buttonClicked(EVENT_CATEGORIES.HEATMAP, EVENT_ACTIONS.HEATMAP_SIZE_CHANGED, { size });
                                    changeHeatmapSize(indicatorKey)
                                }}
                            >
                                {size}
                            </div>
                        })}
                        <div className='Dopplr__HeatmapLegend__Loose'>Tight</div>
                        <div className='Dopplr__HeatmapLegend__Tight'>Loose</div>
                        <img src={HeatmapImageBottom} alt="Heatmap legend" />
                    </>
                }
            </div>
        </div>
    </>
    )
}

export default ComfortSignal