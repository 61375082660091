import React, { useEffect, useState } from 'react';
import BackButton from 'components/BackButton';
import Button from 'components/Button';
import FloatingButtonBar from 'components/FloatingButtonBar';
import { ACTION_TYPES, GENDER, MEASUREMENTS_TYPE } from 'utils/constants';
import InvertedTriangleMale from 'assets/images/maleBody/InvertedTriangle.svg';
import TriangleMale from 'assets/images/maleBody/Triangle.svg';
import OvalMale from 'assets/images/maleBody/Oval.svg';
import TrapezoidMale from 'assets/images/maleBody/Trapezoid.svg';
import RectangleMale from 'assets/images/maleBody/Rectangle.svg';
import AppleFemale from 'assets/images/femaleBody/Apple.svg';
import HourglassFemale from 'assets/images/femaleBody/Hourglass.svg';
import InvertedTriangleFemale from 'assets/images/femaleBody/InvertedTriangle.svg';
import PearFemale from 'assets/images/femaleBody/Pear.svg';
import RectangleFemale from 'assets/images/femaleBody/Rectangle.svg';
import { BODY_TYPES } from 'configs/RendererConfig';
import MixPanel from "appAnalytics/mixPanel";
import { EVENT_ACTIONS, EVENT_CATEGORIES } from 'appAnalytics/eventCategories';

const BODY_SHAPES = {
    [GENDER.MALE]: [
        {
            shape: BODY_TYPES.HOURGLASS,
            src: TrapezoidMale,
            label: "Trapezoid",
        }, {
            shape: BODY_TYPES.RECTANGLE,
            src: RectangleMale,
            label: "Rectangle",
        }, {
            shape: BODY_TYPES.SPOON,
            src: OvalMale,
            label: "Oval",
        }, {
            shape: BODY_TYPES.TRIANGLE,
            src: TriangleMale,
            label: "Triangle",
        }, {
            shape: BODY_TYPES.INV_TRIANGLE,
            src: InvertedTriangleMale,
            label: "Inverted Triangle",
        }
    ],
    [GENDER.FEMALE]: [
        {
            shape: BODY_TYPES.TRIANGLE,
            src: AppleFemale,
            label: "Apple",
        }, {
            shape: BODY_TYPES.HOURGLASS,
            src: HourglassFemale,
            label: "Hourglass",
        }, {
            shape: BODY_TYPES.SPOON,
            src: PearFemale,
            label: "Oval",
        }, {
            shape: BODY_TYPES.INV_TRIANGLE,
            src: InvertedTriangleFemale,
            label: "Inverted Triangle",
        }, {
            shape: BODY_TYPES.RECTANGLE,
            src: RectangleFemale,
            label: "Rectangle",
        }
    ]
}

const TuneAvatarMeasurement = (props) => {
    const { backHandler, gender, measurementPayload, predictedMeasurement, loader = false, actionType = ACTION_TYPES.NONE } = props;
    const [selectedShape, setSelectedShape] = useState(null);
    const shapes = BODY_SHAPES[gender];

    const nextHandler = () => {
        const { height, weight } = measurementPayload;
        const tuneAvataPayload = {
            ...predictedMeasurement,
            height,
            weight,
            gender: gender,
            bodyType: selectedShape?.shape,
            measurementType: MEASUREMENTS_TYPE.SHAPE_SELECTION,
        }
        MixPanel.buttonClicked(EVENT_CATEGORIES.SHAPE_SELECTION, EVENT_ACTIONS.TUNE_AVATAR_MEASUREMENTS, { measurements: {...tuneAvataPayload, measurementType: EVENT_ACTIONS.TUNE_AVATAR_MEASUREMENTS}, actionType });
        props.nextHandler(tuneAvataPayload, MEASUREMENTS_TYPE.SHAPE_SELECTION);
    }

    return (<>
        <div className='TuneAvatar'>
            <div className='TuneAvatar__HeadingContainer'>
                <div style={{ padding: '0.5em 1em', position: 'absolute', zIndex: 10 }}>
                    <BackButton onClick={()=> {
                        MixPanel.buttonClicked(EVENT_CATEGORIES.SHAPE_SELECTION, EVENT_ACTIONS.BACK_BUTTON, { actionType });
                        backHandler();
                    }} />
                </div>
                <div className='Dopplr_Customize__Heading'>What's your</div>
                <div className='Dopplr_Customize__Heading'> body shape?</div>
            </div>
            <div className='TuneAvatar__ShapeContainer'>
                {shapes.map(shape => {
                    return <div className='TuneAvatar__ShapeContainer__Shape' key={shape.label} onClick={() => {
                        MixPanel.buttonClicked(EVENT_CATEGORIES.SHAPE_SELECTION, EVENT_ACTIONS.SELECT_SHAPE, { selectedShape: shape.label, actionType });
                        setSelectedShape(shape)
                    }}>
                        <div className={`TuneAvatar__ShapeContainer__Img ${shape?.shape === selectedShape?.shape ? 'selected' : ''}`}>
                            <img src={shape.src} alt={shape.label} />
                        </div>
                        <div>{shape.label}</div>
                    </div>
                })}
            </div>
        </div>
        <FloatingButtonBar style={{ background: 'none' }}>
            <Button type='black-white' onClick={nextHandler} disabled={!selectedShape} isLoading={loader}>
                Next
            </Button>
        </FloatingButtonBar>
    </>
    )
}

export default TuneAvatarMeasurement
