import React from 'react';
import PropTypes from 'prop-types';
import './CircularLoader.scss';

const CircularLoader = props => {
    const { style, type } = props;
    return (
        <div style={style} className={`CircularLoader ${type}`}><span></span></div>
    );
};


export default CircularLoader;
