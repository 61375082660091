import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import './CloseButton.scss';

const CloseButton = ({ className, onClick }) => {
  const history = useHistory();
  const handleBack = e => {
    e.stopPropagation();
    if (typeof onClick === 'function') {
      onClick(e);
    } else {
      history.goBack();
    }
  };

  return (
    <div className={`CloseButton ${className}`} onClick={handleBack}>
      <div className="CloseButton__Icon">&#10005;</div>
    </div>
  );
};

CloseButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default CloseButton;
