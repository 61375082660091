// 1. Install dependencies DONE
// 2. Import dependencies DONE
// 3. Setup webcam and canvas DONE
// 4. Define references to those DONE
// 5. Load posenet DONE
// 6. Detect function DONE
// 7. Drawing utilities from tensorflow DONE
// 8. Draw functions DONE

// Face Mesh - https://github.com/tensorflow/tfjs-models/tree/master/facemesh

import React, { useRef, useEffect, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { actionsCreator } from "redux/actions/actionsCreator";
import OrientationInfo from "../deviceOrientation"
import Webcam from "react-webcam";
import * as tf from "@tensorflow/tfjs";
import * as faceMesh from '@mediapipe/face_mesh';
import * as faceLandmarksDetection from "@tensorflow-models/face-landmarks-detection";
import { drawMesh, drawPath } from "./utilities";
import { isTouchFriendly } from "utils";
import CloseButton from "components/CloseButton";
import Layout from "components/Layout";
import CircularLoader from "components/CircularLoader";
import Header from "components/Header";
import BackButton from "components/BackButton";
import Title from "components/Header/Title";
import CaptureIcon from "assets/icons/clickIcon.png";
import './SelfieModule.scss';
import { SELFIE_STATUS } from "utils/constants";

const MESSAGES = {
    FACE_DETECTED: 'Face detected. Keep your face aligned in the highlighted region and also keep it straight to conitnue.',
    FACE_NOT_DETECTED: 'Detecting your face . . . ',
    MULTIPLE_FACE_DETECTED: 'Multiple faces detected. Please make sure that only one face is present in front of the camera.',
    VALID_SELFIE: 'Face is aligned. Please make sure your face is evenly lit and your hairs are not covering your face. Tap on capture to click a picture.'
}

const SelfieModule1 = (props) => {
    let intervalId = useRef(null)
    const webcamRef = useRef(null);
    const webcamHiddenRef = useRef(null);
    const canvasRef = useRef(null);
    const selfieModuleRef = useRef(null);
    const overlayRef = useRef(null);
    const msgRef = useRef(null);
    const dispatch = useDispatch();
    const setAvatarImage = payload =>
        dispatch(actionsCreator.SET_AVATAR_IMAGE(payload));
    const setSelfieFile = payload =>
        dispatch(actionsCreator.SET_SELFIE_FILE(payload));

    const [imageSrc, setImageSrc] = useState('');
    const [status, setStatus] = useState(SELFIE_STATUS.ORIENTATION_PERMISSION_DENIED);
    const [webcamHeight, setWebcamHeight] = useState('');
    const [webcamWidth, setWebcamWidth] = useState('');
    const [aspectRatio, setAspectRatio] = useState('');
    const [showOrientationPermission, setShowOrientationPermission] = useState(true);
    //  Load posenet
    const flag = useRef(false);
    const handleFlagChange = (newValue) => {
        flag.current = (newValue);
        if (newValue) {
            console.log('hello');
        }
    };
    const detect = async (net) => {
        try {
            if (

                typeof webcamRef.current !== "undefined" &&
                webcamRef.current !== null &&
                canvasRef.current &&
                webcamRef.current.video &&
                webcamRef.current.video instanceof HTMLVideoElement &&
                webcamRef.current.video.readyState === 4 &&
                !showOrientationPermission
            ) {
                // Get Video Properties
                // const selfieModuleBoundingRec = selfieModuleRef.current.getBoundingClientRect();
                const video = webcamRef.current.video;
                const videoWidth = webcamHiddenRef.current.video.videoWidth;
                const videoHeight = webcamHiddenRef.current.video.videoHeight;
                webcamHiddenRef.current.video.width = videoWidth;
                webcamHiddenRef.current.video.height = videoHeight;
                webcamRef.current.video.width = webcamWidth;
                webcamRef.current.video.height = webcamHeight;
                const webcamRefBoundingRec = webcamRef.current.video.getBoundingClientRect()
                const overlayRefBoundingRec = overlayRef.current.getBoundingClientRect();
                const { width, height } = overlayRefBoundingRec
                // overlayRef.current.style.borderLeft = `${width / 20}px solid rgba(0,0,0,0.5)`;
                // overlayRef.current.style.borderRight = `${width / 20}px solid rgba(0,0,0,0.5)`;
                // overlayRef.current.style.borderTop = `${height / 25}px solid rgba(0,0,0,0.5)`;
                // overlayRef.current.style.borderBottom = `${height / 5}px solid rgba(0,0,0,0.5)`;
                const estimationConfig = { flipHorizontal: true, returnTensors: false };

                const face = await net.estimateFaces(video, estimationConfig);
                // Set canvas width
                if (canvasRef && canvasRef.current) {
                    canvasRef.current.width = webcamWidth;
                    canvasRef.current.height = webcamHeight;
                    // Get canvas context
                    const ctx = canvasRef.current.getContext("2d");
                    ctx.fillStyle = 'rgba(0,0,0,0.5)';
                    ctx.fillRect(0, 0, webcamWidth, webcamHeight);
                    ctx.strokeStyle = 'red';
                    ctx.lineWidth = 3;
                    let status = '';
                    requestAnimationFrame(() => {
                            status = drawMesh(face, ctx, webcamRefBoundingRec, videoWidth, videoHeight, flag.current);
                            setStatus(status);
                    });
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    const capture = useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            setAvatarImage(imageSrc);
            console.log(imageSrc);
            const file = dataURLtoFile(imageSrc, 'selfie.png');
            setSelfieFile(file);
            // props.onClose();
            setImageSrc(imageSrc);
        },
        [webcamRef]
    );

    const onCloseClick = () => {
        setImageSrc('');
    }


    useEffect(() => {
        if (selfieModuleRef.current) {
            setWebcamWidth(selfieModuleRef.current.clientWidth);
            setWebcamHeight(selfieModuleRef.current.clientHeight);
        }
        return () => {
            console.log("unsubscribe =>", intervalId.current)
            clearInterval(intervalId.current);
        }
    }, [selfieModuleRef]);

    const getUserMedia = async () => {
        try {
            // let display = await navigator.mediaDevices.getUserMedia({
            //     video: { height: 99999, width: 1 }
            // });
            // let settings = display.getVideoTracks()[0].getSettings();
            // const { width, height, facingMode, aspectRatio: ratio } = settings;
            // const aspectRatio = ratio < 1 && facingMode === 'user' ? webcamWidth / webcamHeight : webcamHeight / webcamWidth;
            // const aspectRatio = webcamWidth / webcamHeight;
            // console.log(webcamHeight, webcamWidth, aspectRatio);
            // setAspectRatio(aspectRatio);
            //const net = await facemesh.load(facemesh.SupportedPackages.mediapipeFacemesh);
            console.log("getUserMedia");
            const model = faceLandmarksDetection.SupportedModels.MediaPipeFaceMesh;
            const detectorConfig = {
                runtime: "mediapipe",
                solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh@${faceMesh.VERSION}`,
                maxFaces: 1,
                refineLandmarks: true,
            };
            const detector = await faceLandmarksDetection.createDetector(
                model,
                detectorConfig
            );
            intervalId.current = setInterval(() => {
                detect(detector);
            }, 10);
            // setShowOrientationInfo(true);
        } catch (e) {
            // const aspectRatio = isTouchFriendly ? webcamHeight / webcamWidth : webcamWidth / webcamHeight;
            // const aspectRatio = webcamHeight / webcamWidth;
            // setAspectRatio(aspectRatio);
            // const net = await facemesh.load(facemesh.SupportedPackages.mediapipeFacemesh);
            // intervalId.current = setInterval(() => {
            //     detect(net);
            // }, 10);
        }

    }

    useEffect(() => {
        if (webcamHeight && webcamWidth && !showOrientationPermission) {
            getUserMedia();
        }
    }, [webcamHeight, webcamWidth, showOrientationPermission]);

    const handleUserMediaError = (e) => {
        console.log(e);
        setShowOrientationPermission(true);
        setStatus(SELFIE_STATUS.VIDEO_PERMISSION_DENIED);
    }

    const loadWebcam = webcamHeight && webcamWidth;
    let ratio = aspectRatio ? aspectRatio : (isTouchFriendly ? webcamHeight / webcamWidth : webcamWidth / webcamHeight);
    const style = {
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        boxSizing: 'border-box',
        inset: 0,
        margin: 0,
        padding: 0,
    }
    return (
        <Layout style={{ zIndex: 4 }}>
            <div className="SelfieModuleContainer" ref={selfieModuleRef}>
                <OrientationInfo
                    onFlagChange={handleFlagChange}
                    setShowOrientationPermission={setShowOrientationPermission}
                    showOrientationPermission={showOrientationPermission}
                    onClose={() => setShowOrientationPermission(false)}
                    status={status}
                    getUserMedia={getUserMedia}
                />
                {loadWebcam && <>
                    <Webcam
                        audio={false}
                        screenshotFormat="image/png"
                        screenshotQuality={1}
                        mirrored={true}
                        ref={webcamRef}
                        height={webcamHeight}
                        width={webcamWidth}
                        videoConstraints={{
                            aspectRatio: ratio,
                            facingMode: 'user',
                        }}
                        style={{ ...style }}
                    />
                    <Webcam
                        ref={webcamHiddenRef}
                        audio={false}
                        screenshotFormat="image/jpeg"
                        forceScreenshotSourceSize="true"
                        mirrored={true}
                        height={webcamHeight}
                        width={webcamWidth}
                        videoConstraints={{
                            aspectRatio: ratio,
                            facingMode: 'user',
                        }}
                        onUserMediaError={handleUserMediaError}
                        style={{
                            ...style,
                            visibility: 'hidden'
                        }}
                    //onLoadedData={handleVideoLoad}
                    />
                </>}
                {loadWebcam && <>
                    <canvas
                        id="canvas1"
                        ref={canvasRef}
                        style={{
                            ...style
                        }}
                    />
                    <div className="overlay" ref={overlayRef} style={{
                        ...style,
                        zIndex: 1,
                    }}></div>
                </>}
                <div className="SelfieModuleContainer__Actions">
                    <div className="SelfieModuleContainer__Actions__Text" ref={msgRef}>{MESSAGES[status]}</div>
                    <div className="SelfieModuleContainer__Actions__Capture">
                        {status === SELFIE_STATUS.VALID_SELFIE && flag.current === true ?
                            <img src={CaptureIcon} alt="capture" onClick={capture} /> :
                            <CircularLoader style={{ zIndex: 10 }} type="loader-4" />}
                        <div className="SelfieModuleContainer__Actions__Capture__Text">{status === SELFIE_STATUS.VALID_SELFIE ? 'Capture' : ''}</div>
                    </div>
                </div>
                {imageSrc && <div className="SelfieModuleContainer__ImagePreview">
                    <img src={imageSrc} alt="Selfie" />
                    <CloseButton className={"SelfieModuleContainer__ImagePreview__Close"} onClick={onCloseClick} />
                </div>}
            </div >
        </Layout>
    );
}

export default SelfieModule1;
