import React from 'react';
import PropTypes from 'prop-types';
import './Header.scss';

const Header = ({ children }) => {
  return <div className="Dopplr-Header">{children}</div>;
};

Header.propTypes = {
  children: PropTypes.node,
};

Header.defaultProps = {
  children: null,
};

export default Header;
