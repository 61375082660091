import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import ArrowLeft from 'assets/icons/arrow-left.svg';
import './BackButton.scss';

const BackButton = ({ className, onClick, type }) => {
  const history = useHistory();
  const handleBack = e => {
    e.stopPropagation();
    if (typeof onClick === 'function') {
      onClick(e);
    } else {
      history.goBack();
    }
  };
  const renderButton = () => {
    switch (type) {
      case 'Arrow': {
        return (
          <div className="searchBack">
            <img src={ArrowLeft} onClick={handleBack} />
          </div>
        );
      }
      default: {
        return (
          <div className={`BackButton ${className}`} onClick={handleBack}>
            <img src={ArrowLeft} className="BackButton__Icon" alt="" />
          </div>
        );
      }
    }
  };

  return renderButton();
};

BackButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default BackButton;
