export const IFRAME_ACTIONS = {
    PRODUCT_OPENED: 'PRODUCT_OPENED',
    PRODUCT_CLOSED: 'PRODUCT_CLOSED',
    CART_DETAILS: 'CART_DETAILS',
    RESET_CART_LOADER: 'RESET_CART_LOADER',
    SEND_PRODUCT_DATA: 'SEND_PRODUCT_DATA',
    APP_MOUNTED: 'APP_MOUNTED',
    SET_USER_DATA: 'SET_USER_DATA',
    IFRAME_LOADED: 'IFRAME_LOADED',
    CLOSE_IFRAME: 'CLOSE_IFRAME',
    OPEN_PRODUCT: 'OPEN_PRODUCT',
    NEW_PRODUCT_SELECTED: 'NEW_PRODUCT_SELECTED',
    ADD_ALL_ITEMS: 'ADD_ALL_ITEMS',
    ADD_CART_ITEM: 'ADD_CART_ITEM',
}