
export const FETCH_AVATAR = 'FETCH_AVATAR';
export const SET_AVATAR_DATA = 'SET_AVATAR_DATA';
export const SET_PRODUCT_DATA = 'SET_PRODUCT_DATA';
export const SET_AVATAR_IMAGE = 'SET_AVATAR_IMAGE';
export const SET_SELFIE_FILE = 'SET_SELFIE_FILE';
export const UPLOAD_LOADER = 'UPLOAD_LOADER';
export const SET_AVATAR_LOADER = 'SET_AVATAR_LOADER';
export const SET_LOADED_SCENE = 'SET_LOADED_SCENE';
export const SET_AVATAR_ASSETS = 'SET_AVATAR_ASSETS';
export const SET_IS_ANONYMOUS = 'SET_IS_ANONYMOUS';
export const SET_OLD_USER_ID = 'SET_OLD_USER_ID';
export const SET_DEFAULT_AVATAR_DATA = 'SET_DEFAULT_AVATAR_DATA';
export const IS_DEFAULT_AVATAR = 'IS_DEFAULT_AVATAR';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_UID = 'SET_UID';
export const SET_SESSION = 'SET_SESSION';
export const SET_DEFAULT_SELFIE = 'SET_DEFAULT_SELFIE';
export const SET_API_IMAGE = 'SET_API_IMAGE';
export const SET_INSTRUCTION_LIST = 'SET_INSTRUCTION_LIST';
export const SET_SIZE_RECOMMENDATIONS = 'SET_SIZE_RECOMMENDATIONS';
export const SET_FIRST_TIME_CREATION = 'SET_FIRST_TIME_CREATION';
export const RESET_AVATAR_STATE = 'RESET_AVATAR_STATE';
export const ENABLE_ANALYTICS = 'ENABLE_ANALYTICS';
export const SET_CART_DETAILS = 'SET_CART_DETAILS';
export const SET_RELATED_BRANDS = 'SET_RELATED_BRANDS';
export const SET_CATALOG_LIST = 'SET_CATALOG_LIST';
export const SHARE_AVATAR_IMAGE = 'SHARE_AVATAR_IMAGE';
