import React from 'react'
import HalfCard from "components/HalfCard";
import Button from "components/Button";
import "./ReplaceAvatar.scss";

const ReplaceAvatar = ({ showReplaceAvatar, discardHandler, confirmHandler }) => {
    return (
        <HalfCard key={"confirm"}
            visible={showReplaceAvatar}
            bodyClassName={"Dopplr__ReplaceAvatar__Halfcard"}
            onClose={discardHandler}
            name="Replace avatar"
            showHeader={true}
            zIndex={11}
            showCloseButton={true}
            title={"Action Needed !"}
        >
            <div className='Dopplr__ReplaceAvatar'>
                <div className='Dopplr__ReplaceAvatar__Content'>Seems like you have personalised an avatar without signing in. Please confirm if you want to replace avatar linked to your account with newly personalised one.</div>
                <div className='Dopplr__ReplaceAvatar__Actions'>
                    <Button
                        type="white-black"
                        onClick={discardHandler}
                    >
                        Discard (Keep current one)
                    </Button>
                    <Button
                        type="black-white"
                        onClick={confirmHandler}
                    >
                        Confirm
                    </Button>
                </div>
            </div>
        </HalfCard>
    )
}

export default ReplaceAvatar