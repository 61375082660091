import { createStore, applyMiddleware } from 'redux';

import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../redux/sagas';
import rootReducer from '../redux/reducers';
//Redux persist imports


const composeEnhancers = composeWithDevTools({});
const sagaMiddleware = createSagaMiddleware();

const isDebugMode = process.env.REACT_APP_DEBUG_MODE === 'true';
export default function configureStore() {
  let store = createStore(
    rootReducer,
    isDebugMode
      ? composeEnhancers(applyMiddleware(sagaMiddleware))
      : applyMiddleware(sagaMiddleware)
  );
  sagaMiddleware.run(rootSaga);

  return { store, sagaMiddleware };
}
