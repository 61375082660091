import React from 'react'
import { motion } from 'framer-motion';

const BottomBar = ({ keyName, className, style, ...props }) => {
    return (
        <motion.div
            key={keyName}
            className={className}
            initial={{ y: '100%' }}
            animate={{ y: '0', transition: { duration: 0.4, type: 'spring', delay: 0.2 } }}
            style={style}
        >
            {props.children}
        </motion.div>
    )
}

export default BottomBar
