import React, { Component, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Img } from 'react-image';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import './CustomCarousel.scss';
import { isTouchFriendly } from 'utils';
import { data } from '@tensorflow/tfjs';
const Slider = lazy(() => import('./ReactSlick'));

class CustomCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playVideo: false,
    };
  }
  componentDidMount = () => {
    const { data = [] } = this.props;
    let playVideo = false;
    if (data && data.length > 0 && get(data, '0.type', '').includes('video')) {
      playVideo = true;
    }
    this.setState({ playVideo });
  };

  onClickImage = data => {
    this.props.handleClickOnBannerAction(data);
  };
  afterChangeHandler = index => {
    const type = get(this.props.data, `${index}.type`);
    let playVideo = true;
    if (!type.includes('video')) {
      playVideo = false;
    }
    this.setState({ playVideo });
    if(this.props.data?.[index]) {
      this.onClickImage(this.props.data[index]);
    }
  };

  myComponent = (url, index) => (<img
    key={index}
    className={`BannerImage ${index}`}
    src={url}
    alt="Img"
  // loader={<Skeleton key={index} className='BannerImageSkeleton' />}
  // unloader={<Skeleton key={index} className='BannerImageSkeleton' />}
  />)

  renderCarousel = () => {
    const { data = [], sliderSettings: sliderProps = {}, carouselDefaultBanner, selectedItem } = this.props;
    const sliderSettings = {
      dots: false,
      infinite: true,
      slidesToShow: isTouchFriendly ? 3 : 3,
      swipeToSlide: true,
      centerMode: true,
      // slidesToScroll: 2,
      autoplay: false,
      focusOnSelect: true,
      
      ...sliderProps,
    };
    const showDefaultBanner = isEmpty(data);
    return (
      <div className="CarouselContainer">
        <Suspense fallback={null}>
          <Slider {...sliderSettings} afterChange={this.afterChangeHandler}>
            {showDefaultBanner && (
              <div key={'fixedBanner'}>
                <img
                  alt=""
                  className="BannerImage"
                  src={carouselDefaultBanner}
                />
              </div>
            )}
            {data &&
              data.length &&
              data.map((item, index) => {
                const { url = '', type = '' } = item;
                const isSelected = get(item, 'url', '') === get(selectedItem, 'url', '');
                return (
                  <div key={index} onClick={() => this.onClickImage(item)}>
                    {/* <Skeleton className="BannerImage" /> */}
                    {this.myComponent(url, index)}
                  </div>
                );
              })}
          </Slider>
        </Suspense>
      </div>
    );
  };

  render() {
    return this.renderCarousel();
  }
}
CustomCarousel.propTypes = {
  handleClickOnBannerAction: PropTypes.func,
  data: PropTypes.array,
  carouselDefaultBanner: PropTypes.string,
};
CustomCarousel.defaultProps = {
  handleClickOnBannerAction: () => { },
  data: [],
  carouselDefaultBanner: '',
};

export default CustomCarousel;
