import React, { useRef, useEffect } from "react";
import Header from "components/Header";
import Title from "components/Header/Title";
import BackButton from "components/BackButton";
import Layout from "components/Layout";
import ChooseAvatar from "./ChooseAvatar/ChooseAvatar";
import BasicInfo from "./BasicInfo";
import BodyStats from "./BodyStats";
import UploadImage from "containers/uploadImage";
import "./Personalise.scss";

const Personalise = props => {
  const ref = useRef(null);
  // const [state, setstate] = useState(initialState)
  const makeMyAvatarHandler = () => {
    if (ref && ref.current) {
      ref.current.click();
    }

  };
  // const getAllAvatars = () => {
  //   const response = [
  //     { name: "Suji", gender: "Female", bodyStats: {} },
  //     { name: "David", gender: "Male", bodyStats: {} }
  //   ];
  // };
  // useEffect(() => {
  //   this.getAllAvatars();
  //   return () => {
  //     cleanup;
  //   };
  // }, [input]);
  return (
    <Layout>
      <Header>
        <BackButton />
        <Title title="Personalise Avatar" />
      </Header>
      <div className="PersonaliseContainer">
        <ChooseAvatar clickHandler={makeMyAvatarHandler} />
        <BasicInfo name={"Suji"}  gender={"Female"}/>
        <BodyStats/>
        <UploadImage ref={ref} />
      </div>
    </Layout>
  );
};

export default Personalise;
