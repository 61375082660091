import React from 'react';
import PropTypes from 'prop-types';
import './Title.scss';

const Title = props => {
  const { title, className = '' } = props;
  return <div className={`Dopplr_Title ${className}`}>{title || props.children}</div>;
};

Title.defaultProps = { title: '' };

Title.propTypes = {
  title: PropTypes.string,
};
export default Title;
