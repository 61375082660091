/* eslint-disable react/no-unknown-property */
import { h } from 'preact';

const SVGPointer = (props) => (
  <svg
    className={props.className}
    width="30px"
    height="14px"
    viewBox="0 0 30 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>pointer</title>
    <desc>Created with Sketch.</desc>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-7.000000, -219.000000)" fill="#FFFFFF">
        <g transform="translate(7.000000, 219.000000)">
          <path
            d="M30,1.10188411 L30,12.8981159 C30,13.1742583 29.7761424,13.3981159 29.5,13.3981159 C29.3788884,13.3981159 29.2618934,13.3541568 29.1707477,13.2744042 L22.8600877,7.75257669 C22.444451,7.38889464 22.4023335,6.75713202 22.7660156,6.34149539 C22.79525,6.30808462 22.8266769,6.27665773 22.8600877,6.24742331 L29.1707477,0.725595766 C29.378566,0.54375474 29.6944473,0.564813494 29.8762883,0.772631809 C29.9560409,0.863777557 30,0.98077254 30,1.10188411 Z"
          />
          <path
            d="M8,1.10188411 L8,12.8981159 C8,13.1742583 7.77614237,13.3981159 7.5,13.3981159 C7.37888843,13.3981159 7.26189344,13.3541568 7.1707477,13.2744042 L0.860087651,7.75257669 C0.44445102,7.38889464 0.402333512,6.75713202 0.766015564,6.34149539 C0.795249989,6.30808462 0.82667688,6.27665773 0.860087651,6.24742331 L7.1707477,0.725595766 C7.37856601,0.54375474 7.69444732,0.564813494 7.87628835,0.772631809 C7.95604088,0.863777557 8,0.98077254 8,1.10188411 Z"
            transform="translate(4.000000, 7.000000) scale(-1, 1) translate(-4.000000, -7.000000) "
          />
        </g>
      </g>
    </g>
  </svg>
);
/* eslint-enable react/no-unknown-property */

export default SVGPointer;
