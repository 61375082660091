
import mixpanel from 'mixpanel-browser';
import { store } from 'App';
const reactEnv = process.env.REACT_APP_ENV;
class MixPanel {
    constructor() {
        if (reactEnv === 'qa') {
            mixpanel.init('6b0d5e7f83792a6b205c9d3785138b70');
        } else {
            mixpanel.init('70f6bcaae08aebfda94a12371112a0a9');
        }
    }

    getDataFromStore() {
        const { avatarReducer } = store.getState();
        const { uid, product, enableAnalytics, session } = avatarReducer || {};
        return {
            userId: uid,
            brand: product?.brand || '',
            productId: product?.product_path || '',
            productSlug: product?.productSlug || '',
            session,
            // enableAnalytics: enableAnalytics || false,
            enableAnalytics: true,
        }
    }

    sessionStarted(id) {
        const { userId, productId, brand, productSlug, enableAnalytics } = this.getDataFromStore();
        enableAnalytics && mixpanel.track('session_started', { distinct_id: userId, id, userId, brand, productId, productSlug });
    };

    sessionEnded(id) {
        const { userId, productId, brand, productSlug, enableAnalytics } = this.getDataFromStore();
        enableAnalytics && mixpanel.track('session_ended', { distinct_id: userId, id, userId, brand, productId, productSlug });
    };

    buttonClicked(category, buttonName, data = {}, forceSendAnalytics) {
        const { userId, productId, brand, productSlug, session, enableAnalytics } = this.getDataFromStore();
        (enableAnalytics || forceSendAnalytics) && mixpanel.track('button_click', { distinct_id: userId, category, name: buttonName, userId, session, brand, productId, productSlug, ...data });
    };

    pageViewed(category, pageName, data = {}) {
        const { userId, productId, brand, productSlug, enableAnalytics } = this.getDataFromStore();
        enableAnalytics && mixpanel.track('page_viewed', { distinct_id: userId, category, name: pageName, pageName, userId, brand, productId, productSlug, ...data });
    };
}

export default new MixPanel();
