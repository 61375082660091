import React from 'react';
import { ArrowCircleLeftIcon, ArrowCircleRightIcon } from '@heroicons/react/solid';
import HalfCard from "components/HalfCard";
import InstructionImages from 'components/InstructionImages';
import FloatingButtonBar from 'components/FloatingButtonBar';
import Button from 'components/Button';
import { isTouchFriendly } from 'utils';
import './Instructions.scss';

function Instructions({ showInstructions, setShowInstructions, setInvokeInstructionHandler }) {
    return (
        <HalfCard
            visible={!!showInstructions}
            key={'instructions'}
            onClose={() => setShowInstructions('')}
            showCloseButton={true}
            title="Instructions"
            closeOnBackgroundClick={true}
        >
            <div className="Dopplr_Instructions">
                <div className='Dopplr_Instructions__Heading'>Instructions</div>
                <div className='Dopplr_Instructions__Description'>
                    For the best results, keep your <b>head straight, hair back, look into the camera</b> and make sure you are in a <b>well-lit</b> setting.
                </div>
                <InstructionImages />
                <FloatingButtonBar customClass="Dopplr__ActionWrapper">
                    <div className="Dopplr__ActionWrapper__Actions" style={{ width: isTouchFriendly ? '95%' : '80%' }}>
                        <Button type='black-white' className={`FloatingButton Left`} onClick={() => setShowInstructions('')}>
                            <div className='FloatingButton__Content'>
                                <ArrowCircleLeftIcon className="FloatingButton__Icon" /><span>Back</span>
                            </div>
                        </Button>
                        <Button type='black-white' className={`FloatingButton`} onClick={() => setInvokeInstructionHandler(true)}>
                            <div className='FloatingButton__Content'>
                                <span>Proceed</span>
                                <ArrowCircleRightIcon className="FloatingButton__Icon" />
                            </div>
                        </Button>
                    </div>
                </FloatingButtonBar>
            </div>
        </HalfCard>
    )
}

export default Instructions;
