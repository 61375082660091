import React, {Component} from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container);
Bugsnag.start({
  apiKey: '39377656e64f4d12e04f1462408dfec1',
  plugins: [new BugsnagPluginReact()]
})
// export class TrackJSErrorBoundary extends Component {
//     componentDidCatch(error, errorInfo) {
//         if (errorInfo && errorInfo.componentStack) {
//             // The component stack is sometimes useful in development mode
//             // In production it can be somewhat obfuscated, so feel free to omit this line.
//             console.log(errorInfo.componentStack);
//         }

//         TrackJS.track(error);
//         this.setState({ error });
//     }

//     render() {
//         // Whatever rendering of error messages or children components you would like
//     }
// }
const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)

root.render(<ErrorBoundary><App /></ErrorBoundary>);

// Bugsnag.notify(new Error('Test error'))
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
