import axios from 'axios';
import get from 'lodash/get';
import { getDeviceId, getChannelId } from 'utils';
import { auth } from "../firebase";
import { store } from 'App';
import { proxyAPI } from 'api';
import errorHandler from './errorHandler';
import { actionsCreator } from 'redux/actions/actionsCreator';

const requestMaker = (
  baseQuery,
  requestData,
  additionalHeaders = {},
  progressCallback
) => {
  let Authorization = '';
  // try {
  //   Authorization = localStorage && localStorage.getItem('Authorization');
  // } catch (error) {
  //   console.warn('No en-token found : ', error);
  // }
  if (!navigator.onLine) {
    throw new Error('No internet connection');
  }
  const headers = {
    ...additionalHeaders,
    'Content-type': 'application/json',
    // 'x-device-id': getDeviceId(),
    // 'x-channel-id': getChannelId(),
  };
  if (!additionalHeaders.Authorization) {
    const { avatarReducer } = store.getState();
    const token = get(avatarReducer, 'token', '');
    headers['Authorization'] = Authorization ? Authorization : token ? token : undefined;
  };
  const HEADER_CONFIG = {
    headers,
  };
  if (progressCallback) {
    HEADER_CONFIG.onUploadProgress = progressEvent => {
      progressCallback(progressEvent);
    };
  }
  if (progressCallback) {
    HEADER_CONFIG.onDownloadProgress = progressEvent => {
      progressCallback(progressEvent);
    };
  }
  let baseurl = process.env.REACT_APP_HOST_PATH || '/';
  baseurl = baseurl.slice(0, baseurl.length - 1);
  baseQuery = `${baseurl}${baseQuery}`;
  let apiCall = axios.post(baseQuery, requestData, HEADER_CONFIG);
  return apiCall
    .then(response => {
      if (response.data && response.data['Authorization']) {
        localStorage && localStorage.setItem('Authorization', response.data['Authorization']);
      }
      return response;
    })
    .catch(async error => {
      const pe = errorHandler(error);
      const isUnauthorised = get(error, 'message', '').includes('401');
      if (isUnauthorised || pe.status === 401) {
        try {
          const { avatarReducer } = store.getState();
          let token = get(avatarReducer, 'token', '');
          let uid = get(avatarReducer, 'uid', '');
          const currentURL = window.location.pathname;
          const lastRoute = currentURL.split("/") || [];
          if (auth.currentUser) {
            token = await auth.currentUser.getIdToken(true);
            store.dispatch(actionsCreator.SET_TOKEN(token));
          } else {
            const brand = lastRoute.pop();
            let providerId = 'login';
            if (!uid || (uid && uid.includes('DopplrGuest'))) {
              providerId = 'anonymous';
            };
            const response = await proxyAPI.createToken({ uid, brand, providerId });
            if (response.data) {
              token = response.data;
              await auth.signInWithCustomToken(response.data);
              return;
              // const user = userResponse?.user;
              // const accessToken = await user?.getIdToken(true);
              // token = accessToken;
              // store.dispatch(actionsCreator.SET_TOKEN(token));
            }
          }
          return axios.post(baseQuery, requestData, { ...HEADER_CONFIG, 'Authorization': `Bearer ${token}` }).then(response => {
            // if (response.data) {
            //   localStorage && localStorage.setItem('Authorization', `Bearer ${token}`);
            // }
            return response;
          })
            .catch(error => {
              const pe = errorHandler(error);
              console.error('Error in retrying api :: ', error, pe);
              throw error;
            });
        } catch (e) {
          console.error('Error in getting refresh token :: ', error, pe);
          throw error;
        }
      } else {
        console.error('Error in NH-WC :: ', error, pe);
        throw error;
      }
    });
};

export default requestMaker;
