import React, { useState } from "react";
import "./InputField.scss";
const InputField = ({
  onChange,
  value,
  label,
  className,
  showToggle = false,
  type = 'text',
  ...props
}) => {
  const [inputType, setType] = useState(type);
  const toggleField = () => {
    if (inputType === 'password') {
      setType('text');
    } else {
      setType('password');
    }
  }
  return (
    <div className={`InputWrapper ${className}`}>
      <input
        type={inputType}
        className="inputField"
        onChange={onChange}
        value={value}
        {...props}
      />
      {showToggle && <div className="toggleField" onClick={toggleField}>{inputType == 'password' ? "Show" : "Hide"}</div>}
    </div>
  );
};
export default InputField;
