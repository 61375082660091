import * as BABYLON from "@babylonjs/core/Legacy/legacy";
import avatarData from './AvatarData';
import { getMidPoint, getZCoordinate, getXCoordinate, getYCoordinate } from "utils/babylonUtils";

let bodyNeckPosition = new BABYLON.Vector3(0, 0, 0);
let headSpecificVertexPositions = {
    female: {
        forehead: {
            id: 7672,
            x: -0.001289,
            y: 1.61859,
            z: 0.081587
        },
        tophead: {
            id: 8968,
            x: -0.001706,
            y: 1.65404,
            z: 0.001414
        }
    },
    male: {
        forehead: {
            id: 7672,
            x: 0.003729,
            y: 1.7366,
            z: -0.012696
        },
        tophead: {
            id: 8968,
            x: 0.004009,
            y: 1.77664,
            z: 0.012696
        },
        backhead: {
            id: 11433,
            x: 0.001851,
            y: 1.67879,
            z: -0.081489
        },
        leftEar: {
            id: 11140,
            x: -0.069225,
            y: 1.65731,
            z: 0.028932
        },
        rightEar: {
            id: 11217,
            x: 0.076198,
            y: 1.65573,
            z: 0.033025
        }
    }
};
const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

const snapHeadToBodyNewPose = (bodyMesh, scene, product, isDefaultAvatar = false) => {
    // console.log("snap head to body");
    const faceMesh = scene.getMeshByName(`face_${product.gender}_${product.avatar}`);
    const hairMesh = scene.getMeshByName(`hair_${product.gender}_${product.avatar}`);
    const facePositionData = faceMesh && faceMesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
    const bodyPositionData = bodyMesh && bodyMesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
    let poseData = avatarData[product.gender][product.avatar];
    if (isDefaultAvatar && product.brand === 'puma') {
        poseData = avatarData[product.gender][`${product.avatar}_default`];
    }
    if (poseData === undefined || poseData === null) {
        poseData = avatarData[product.gender]['default'];
    }
    let pivotForRotation = new BABYLON.Vector3(0, 0, 0)
    let angleOfHeadRotationZ = poseData.headRotationZAxis;
    let angleOfHeadRotationX = poseData.headRotationXAxis;
    BABYLON.Mesh.prototype.rotateAroundPivot = function (pivotPoint, axis, angle) {
        if (!this._rotationQuaternion) {
            this._rq = BABYLON.Quaternion.RotationYawPitchRoll(this.rotation.y, this.rotation.x, this.rotation.z);
        }
        if (!this._rq) {
            this._rq = BABYLON.Quaternion.FromEulerAngles(0, 0, 0);
        }
        var _p = new BABYLON.Quaternion(this.position.x - pivotPoint.x, this.position.y - pivotPoint.y, this.position.z - pivotPoint.z, 0);
        axis.normalize();
        var _q = BABYLON.Quaternion.RotationAxis(axis, angle);  //form quaternion rotation		
        var _qinv = BABYLON.Quaternion.Inverse(_q);
        var _pdash = _q.multiply(_p).multiply(_qinv);
        this.position = new BABYLON.Vector3(pivotPoint.x + _pdash.x, pivotPoint.y + _pdash.y, pivotPoint.z + _pdash.z);
        this.rotationQuaternion = this._rq.multiply(_q);
        this._rq = this.rotationQuaternion;
    }
    function calculateDifferenceInForeheadPositionFromDefaultHead(concernedVertex) {
        const concernedVertexPosition = headSpecificVertexPositions[product.gender][concernedVertex];

        const headVertexID = concernedVertexPosition.id;
        const xPos = facePositionData[headVertexID * 3];
        const yPos = facePositionData[headVertexID * 3 + 1];
        const zPos = facePositionData[headVertexID * 3 + 2];

        const xDiff = xPos - concernedVertexPosition.x;
        const yDiff = yPos - concernedVertexPosition.y;
        const zDiff = zPos - concernedVertexPosition.z;

        return { xDiff, yDiff, zDiff };
    }
    function rotateHead() {
        //if (angleOfHeadRotationX === 0.0) { return }
        const headMesh = faceMesh;
        let bbMin = headMesh.getBoundingInfo().boundingBox.minimum;
        let bbMax = headMesh.getBoundingInfo().boundingBox.maximum;
        pivotForRotation = new BABYLON.Vector3((bbMin.x + bbMax.x) / 2, (bbMin.y + bbMin.y) / 2, (bbMin.z + bbMax.z) / 2)   // Base of head bounding box 

        // Z Axis rotation first
        if (angleOfHeadRotationZ !== 0.0) {
            headMesh.rotateAroundPivot(pivotForRotation, BABYLON.Axis.Z, angleOfHeadRotationZ)
        }

        // X Axis rotation second
        if (angleOfHeadRotationX !== 0.0) {
            headMesh.rotateAroundPivot(pivotForRotation, BABYLON.Axis.X, angleOfHeadRotationX)
        }

        if (hairMesh) {
            if (angleOfHeadRotationZ !== 0.0) {
                hairMesh.rotateAroundPivot(pivotForRotation, BABYLON.Axis.Z, angleOfHeadRotationZ)
            }
            if (angleOfHeadRotationX !== 0.0) {
                hairMesh.rotateAroundPivot(pivotForRotation, BABYLON.Axis.X, angleOfHeadRotationX)
            }
        }
    }
    function deleteHeadVertices() {
        const headMesh = faceMesh;
        const headPositionData = facePositionData;

        const verticesToDelete = poseData.headVerticesToDelete;
        for (let i = 0; i < verticesToDelete.length; i++) {
            let vertexID = verticesToDelete[i]
            let x = headPositionData[vertexID * 3]
            let y = headPositionData[vertexID * 3 + 1]
            let z = headPositionData[vertexID * 3 + 2]

            headPositionData[vertexID * 3] = x / verticesToDelete.length
            headPositionData[vertexID * 3 + 1] = y
            headPositionData[vertexID * 3 + 2] = z / verticesToDelete.length
        }

        headMesh.setVerticesData(BABYLON.VertexBuffer.PositionKind, headPositionData);
    }
    function moveHeadCloseToBody() {
        const faceVertices = poseData.faceNeckVerticesForSnap;
        const bodyVertices = poseData.bodyNeckVerticesForSnap;

        const facePositionData = faceMesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
        let midPointNeckBase = new BABYLON.Vector3(0, 0, 0);
        let midPointHeadBase = new BABYLON.Vector3(0, 0, 0);
        let bodyNeckDistance = 0;
        let headNeckDistance = 0;

        for (var i = 0; i < faceVertices.length; i++) {
            // Get Neck part of the head
            let vertexID = faceVertices[i]
            let x = facePositionData[vertexID * 3]
            let y = facePositionData[vertexID * 3 + 1]
            let z = facePositionData[vertexID * 3 + 2]

            midPointHeadBase.x += x
            midPointHeadBase.y += y
            midPointHeadBase.z += z

            const currFaceVertex = new BABYLON.Vector3(-x, y, z)
            // makeSphere(currFaceVertex, i)
            if (i < faceVertices.length - 1) {
                const nextFaceVertex = new BABYLON.Vector3(-facePositionData[faceVertices[i + 1] * 3], facePositionData[faceVertices[i + 1] * 3 + 1], facePositionData[faceVertices[i + 1] * 3 + 2])
                headNeckDistance += BABYLON.Vector3.Distance(currFaceVertex, nextFaceVertex)
            }
            else {
                const nextFaceVertex = new BABYLON.Vector3(-facePositionData[faceVertices[0] * 3], facePositionData[faceVertices[0] * 3 + 1], facePositionData[faceVertices[0] * 3 + 2])
                headNeckDistance += BABYLON.Vector3.Distance(currFaceVertex, nextFaceVertex)
            }
        }
        for (var i = 0; i < bodyVertices.length; i++) {
            // Get Neck part of the body
            let vertexID = bodyVertices[i]
            let x = bodyPositionData[vertexID * 3]
            let y = bodyPositionData[vertexID * 3 + 1]
            let z = bodyPositionData[vertexID * 3 + 2]

            midPointNeckBase.x += x
            midPointNeckBase.y += y
            midPointNeckBase.z += z

            const currBodyVertex = new BABYLON.Vector3(-x, y, z)
            // makeSphere(currBodyVertex, i)
            if (i < bodyVertices.length - 1) {
                const nextBodyVertex = new BABYLON.Vector3(-bodyPositionData[bodyVertices[i + 1] * 3], bodyPositionData[bodyVertices[i + 1] * 3 + 1], bodyPositionData[bodyVertices[i + 1] * 3 + 2])
                bodyNeckDistance += BABYLON.Vector3.Distance(currBodyVertex, nextBodyVertex)
            }
            else {
                const nextBodyVertex = new BABYLON.Vector3(-bodyPositionData[bodyVertices[0] * 3], bodyPositionData[bodyVertices[0] * 3 + 1], bodyPositionData[bodyVertices[0] * 3 + 2])
                bodyNeckDistance += BABYLON.Vector3.Distance(currBodyVertex, nextBodyVertex)
            }
        }

        const bodyToHeadRatio = poseData.bodyToHeadRatioValue ? poseData.bodyToHeadRatioValue : clamp((bodyNeckDistance / headNeckDistance), 0.95, 1.05)
        // console.log("bodyNeckDistance -> ", bodyNeckDistance)
        // console.log("headNeckDistance -> ", headNeckDistance)
        // console.log("bodyToHeadRatio -> ", bodyToHeadRatio)

        // Scaling the head and hair to match the body neck circumference (Avoids neck distortion issue)
        faceMesh._scaling.x = -bodyToHeadRatio    // multiplying -1 because of weird issue
        faceMesh._scaling.z = bodyToHeadRatio
        faceMesh._scaling.y = bodyToHeadRatio

        if (hairMesh) {
            hairMesh._scaling.x = -bodyToHeadRatio    // multiplying -1 because of weird issue
            hairMesh._scaling.z = bodyToHeadRatio
            hairMesh._scaling.y = bodyToHeadRatio
        }

        // Get the corresponding midpoints
        midPointHeadBase.x = midPointHeadBase.x / faceVertices.length;
        midPointHeadBase.y = midPointHeadBase.y / faceVertices.length;
        midPointHeadBase.z = midPointHeadBase.z / faceVertices.length;

        midPointNeckBase.x = midPointNeckBase.x / bodyVertices.length;
        midPointNeckBase.y = midPointNeckBase.y / bodyVertices.length;
        midPointNeckBase.z = midPointNeckBase.z / bodyVertices.length;

        // Get the difference to get the distance to move head by
        const xShift = poseData.headTranslationBeforeSnapXAxis ? poseData.headTranslationBeforeSnapXAxis : 0.0;
        const zShift = poseData.headTranslationBeforeSnapZAxis ? poseData.headTranslationBeforeSnapZAxis : 0.0;
        const xDiff = midPointNeckBase.x - (midPointHeadBase.x * bodyToHeadRatio) + xShift;
        const yDiff = midPointNeckBase.y - (midPointHeadBase.y * bodyToHeadRatio);
        const zDiff = midPointNeckBase.z - midPointHeadBase.z + zShift;

        bodyNeckPosition = midPointNeckBase;      // This is important for clipHair height

        // Move face and hair mesh by above amounts
        if (faceMesh) {
            faceMesh.position.x -= xDiff;    // multiplying -1 because of weird issue
            faceMesh.position.y += yDiff;
            faceMesh.position.z += zDiff;

            faceMesh.bakeCurrentTransformIntoVertices();
        }

        if (hairMesh) {
            hairMesh.position.x -= xDiff;    // multiplying -1 because of weird issue
            hairMesh.position.y += yDiff;
            hairMesh.position.z += zDiff;

            hairMesh.bakeCurrentTransformIntoVertices();
        }
        return { xDif: xDiff, yDif: yDiff, zDif: zDiff, bodyToHeadRati: bodyToHeadRatio };
    }
    function moveHeadCloseToBodyPuma() {
        const facePositionData = faceMesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
        let midPointNeckBase = new BABYLON.Vector3(0, 0, 0);
        let midPointHeadBase = new BABYLON.Vector3(0, 0, 0);

        //const body = [6297, 6274, 6265, 6283];
        let body = [6297, 6274, 6264, 6284];
        if (product.gender === 'female') {
            body = [6297, 6274, 6265, 6283];
        }
        if ((product.avatar == "v14" && product.gender === 'male') || (product.avatar == "v18" && product.gender === 'female')) {
            body = [5163, 5201, 5174, 5152]
        }
        if ((product.avatar == "v15" && product.gender === 'male') || ((product.avatar == "v19" || product.avatar == "v20" || product.avatar == "v21") && product.gender === 'female')) {
            const bodyNeckVerticesForSnap = poseData.bodyNeckVerticesForSnap;
            body = [bodyNeckVerticesForSnap[0], bodyNeckVerticesForSnap[22], bodyNeckVerticesForSnap[11], bodyNeckVerticesForSnap[33]];
            // body = [5163, 5201, 5174, 5152]
        }
        // const face = [2477, 12366, 11827, 11562];
        const face = [12335, 12312, 12323, 12301];

        const bodyToHeadRatio = poseData.bodyToHeadRatioValue ? poseData.bodyToHeadRatioValue : 1.05
        // console.log("bodyNeckDistance -> ", bodyNeckDistance)
        // console.log("headNeckDistance -> ", headNeckDistance)
        // console.log("bodyToHeadRatio -> ", bodyToHeadRatio)

        // Scaling the head and hair to match the body neck circumference (Avoids neck distortion issue)
        const bodyFrontPointZVertex = bodyPositionData[getZCoordinate(body[0])];
        const faceFrontPointZVertex = facePositionData[getZCoordinate(face[0])];
        const bodyBackPointZVertex = bodyPositionData[getZCoordinate(body[1])];
        const faceBackPointZVertex = facePositionData[getZCoordinate(face[1])];
        let zAxisScallingFactor = Math.abs(bodyBackPointZVertex - bodyFrontPointZVertex) / Math.abs(faceBackPointZVertex - faceFrontPointZVertex);

        const bodyLeftPointXVertex = bodyPositionData[getXCoordinate(body[2])];
        const faceLeftPointXVertex = facePositionData[getXCoordinate(face[2])];
        const bodyRightPointXVertex = bodyPositionData[getXCoordinate(body[3])];
        const faceRightPointXVertex = facePositionData[getXCoordinate(face[3])];
        let xAxisScallingFactor = Math.abs(bodyLeftPointXVertex - bodyRightPointXVertex) / Math.abs(faceLeftPointXVertex - faceRightPointXVertex);


        faceMesh._scaling.z = zAxisScallingFactor;
        faceMesh._scaling.x = -1 * xAxisScallingFactor; // multiplying -1 because of weird issue
        faceMesh._scaling.y = xAxisScallingFactor;

        console.log("scale factor z:", zAxisScallingFactor);
        console.log("scale factor x:", xAxisScallingFactor);

        if (hairMesh) {
            hairMesh._scaling.x = -1 * xAxisScallingFactor;   // multiplying -1 because of weird issue
            hairMesh._scaling.z = zAxisScallingFactor;
            hairMesh._scaling.y = xAxisScallingFactor;
        }

        // Get the corresponding midpoints
        midPointHeadBase = getMidPoint(face, facePositionData);

        midPointNeckBase = getMidPoint(body, bodyPositionData);

        // Get the difference to get the distance to move head by
        const xShift = poseData.headTranslationBeforeSnapXAxis ? poseData.headTranslationBeforeSnapXAxis : 0.0;
        const zShift = poseData.headTranslationBeforeSnapZAxis ? poseData.headTranslationBeforeSnapZAxis : 0.0;
        const xDiff = midPointNeckBase.x - (midPointHeadBase.x * xAxisScallingFactor) + xShift;
        const yDiff = midPointNeckBase.y - (midPointHeadBase.y * xAxisScallingFactor);
        const zDiff = midPointNeckBase.z - (midPointHeadBase.z * zAxisScallingFactor) + zShift;

        bodyNeckPosition = midPointNeckBase;      // This is important for clipHair height

        // Move face and hair mesh by above amounts
        if (faceMesh) {
            faceMesh.position.x -= xDiff;    // multiplying -1 because of weird issue
            faceMesh.position.y += yDiff;
            faceMesh.position.z += zDiff;

            faceMesh.bakeCurrentTransformIntoVertices();
        }

        if (hairMesh) {
            hairMesh.position.x -= xDiff;    // multiplying -1 because of weird issue
            hairMesh.position.y += yDiff;
            hairMesh.position.z += zDiff;

            hairMesh.bakeCurrentTransformIntoVertices();
        }
        return { xDif: xDiff, yDif: yDiff, zDif: zDiff, bodyToHeadRati: bodyToHeadRatio, xAxisScallingFactor, zAxisScallingFactor };

    }

    function rotateHeadUsingBone() {
        const bones = scene.skeletons[0].bones;
        const headBone = bones.find(x => x.name === 'Head');
        if (headBone) {
            headBone.getTransformNode().rotate(BABYLON.Axis.X, 0.20944);
        }
    }

    function stickTheNeckJoints() {
        const faceVertices = poseData.faceNeckVerticesForSnap;
        const bodyVertices = poseData.bodyNeckVerticesForSnap;

        const faceNewPositionData = facePositionData //faceMesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
        for (var i = 0; i <= faceVertices.length; i++) {
            const j = i
            faceNewPositionData[faceVertices[j] * 3] = bodyPositionData[bodyVertices[i] * 3];
            faceNewPositionData[faceVertices[j] * 3 + 1] = bodyPositionData[bodyVertices[i] * 3 + 1];
            faceNewPositionData[faceVertices[j] * 3 + 2] = bodyPositionData[bodyVertices[i] * 3 + 2];
        }
        faceMesh.setVerticesData(BABYLON.VertexBuffer.PositionKind, faceNewPositionData);
    }


    const vertexIDList = headSpecificVertexPositions[product.gender];
    const headVertexDiffs = {};
    for (let i = 0; i < Object.keys(vertexIDList).length; i++) {
        const concernedVertex = Object.keys(vertexIDList)[i];
        headVertexDiffs[concernedVertex] = calculateDifferenceInForeheadPositionFromDefaultHead(concernedVertex);
    }
    // rotateHeadUsingBone();
    rotateHead();
    deleteHeadVertices();
    let bodyToHeadRatio, xDiff, yDiff, zDiff, xAxisScallingFactor, zAxisScallingFactor;
    const { gender, avatar } = product;
    if (((avatar === 'v17' && gender === 'female') || (avatar === 'v12' && gender === 'male') || (avatar === 'v14' && gender === 'male') || (avatar === 'v15' && gender === 'male') || (avatar === 'v18' && gender === 'female') || ((avatar === 'v19' || avatar === 'v20'|| avatar === 'v21') && gender === 'female')) && !isDefaultAvatar) {
        const { bodyToHeadRati, xDif, yDif, zDif, xAxisScallingFactor: xScalingFactor, zAxisScallingFactor: zScalingFactor } = moveHeadCloseToBodyPuma();
        bodyToHeadRatio = bodyToHeadRati;
        xDiff = xDif;
        yDiff = yDif;
        zDiff = zDif;
        xAxisScallingFactor = xScalingFactor;
        zAxisScallingFactor = zScalingFactor;
    }
    else {
        const { bodyToHeadRati, xDif, yDif, zDif } = moveHeadCloseToBody();
        bodyToHeadRatio = bodyToHeadRati;
        xDiff = xDif;
        yDiff = yDif;
        zDiff = zDif;
    }
    stickTheNeckJoints();
    const averageNormalsWithoutMerge = () => {
        const newHeadVertices = [18655, 18654, 18652, 18651, 18650, 18649, 18648, 18647, 18646, 18645, 18644, 18643, 18642, 18641, 18640, 18639, 18638, 18637, 18636, 18635, 18634, 18633, 18632, 18631, 18630, 18629, 18628, 18627, 18626, 18625, 18624, 18623, 18622, 18621, 18620, 18619, 18618, 18617, 18616, 18615, 18614, 18673, 18675, 18657, 18658]
        const testBodyNeckVerticesStraightPose = [5163, 5164, 5165, 5166, 5167, 5168, 5169, 5170, 5171, 5172, 5173, 5174, 5175, 5176, 5177, 5178, 5179, 5180, 5181, 5183, 5184, 5185, 5201, 5201, 5197, 5195, 5194, 5193, 5189, 5187, 5186, 5362, 5148, 5150, 5152, 5153, 5154, 5155, 5156, 5157, 5158, 5159, 5160, 5161, 5162];
        faceMesh.removeVerticesData(BABYLON.VertexBuffer.MatricesIndicesKind);
        faceMesh.removeVerticesData(BABYLON.VertexBuffer.MatricesWeightsKind);

       // var mesh = BABYLON.Mesh.MergeMeshes([bodyMesh, faceMesh], true, true, undefined, false, false);

        const normal = faceMesh.getVerticesData(BABYLON.VertexBuffer.NormalKind)
        const normalb = bodyMesh.getVerticesData(BABYLON.VertexBuffer.NormalKind)

        //BABYLON.VertexData.ComputeNormals(headMesh.getPositionData(), headMesh.getIndices(), normal, {useRightHandedSystem: true});

        for (let i = 0; i < poseData.bodyNeckVerticesForSnap.length; i++) {
            const bodyIndex = poseData.bodyNeckVerticesForSnap[i];
            const faceIndex = poseData.faceNeckVerticesForSnap[i];
            const avgX = normalb[getXCoordinate(bodyIndex)];
            const avgY = normalb[getYCoordinate(bodyIndex)];
            const avgZ = normalb[getZCoordinate(bodyIndex)];
            normal[getXCoordinate(faceIndex)] = avgX;
            normal[getYCoordinate(faceIndex)] = avgY;
            normal[getZCoordinate(faceIndex)] = avgZ;

        }

        faceMesh.setVerticesData(BABYLON.VertexBuffer.NormalKind, normal);
        //BABYLON.VertexData.ComputeNormals(mesh.getPositionData(), mesh.getIndices(), normal, {useRightHandedSystem: true});

        return faceMesh;
    }
    const averageNormals = () => {
        const newHeadVertices = [18655, 18654, 18652, 18651, 18650, 18649, 18648, 18647, 18646, 18645, 18644, 18643, 18642, 18641, 18640, 18639, 18638, 18637, 18636, 18635, 18634, 18633, 18632, 18631, 18630, 18629, 18628, 18627, 18626, 18625, 18624, 18623, 18622, 18621, 18620, 18619, 18618, 18617, 18616, 18615, 18614, 18673, 18675, 18657, 18658]
        const testBodyNeckVerticesStraightPose = [5163, 5164, 5165, 5166, 5167, 5168, 5169, 5170, 5171, 5172, 5173, 5174, 5175, 5176, 5177, 5178, 5179, 5180, 5181, 5183, 5184, 5185, 5201, 5201, 5197, 5195, 5194, 5193, 5189, 5187, 5186, 5362, 5148, 5150, 5152, 5153, 5154, 5155, 5156, 5157, 5158, 5159, 5160, 5161, 5162];
        faceMesh.removeVerticesData(BABYLON.VertexBuffer.MatricesIndicesKind);
        faceMesh.removeVerticesData(BABYLON.VertexBuffer.MatricesWeightsKind);

        var mesh = BABYLON.Mesh.MergeMeshes([bodyMesh, faceMesh], true, true, undefined, false, false);

        const normal = mesh.getVerticesData(BABYLON.VertexBuffer.NormalKind)

        //BABYLON.VertexData.ComputeNormals(headMesh.getPositionData(), headMesh.getIndices(), normal, {useRightHandedSystem: true});

        for (let i = 0; i < newHeadVertices.length; i++) {
            const bodyIndex = poseData.bodyNeckVerticesForSnap[i];
            const faceIndex = newHeadVertices[i];
            const avgX = normal[getXCoordinate(bodyIndex)];
            const avgY = normal[getYCoordinate(bodyIndex)];
            const avgZ = normal[getZCoordinate(bodyIndex)];
            normal[getXCoordinate(faceIndex)] = avgX;
            normal[getYCoordinate(faceIndex)] = avgY;
            normal[getZCoordinate(faceIndex)] = avgZ;

        }

        mesh.setVerticesData(BABYLON.VertexBuffer.NormalKind, normal);
        //BABYLON.VertexData.ComputeNormals(mesh.getPositionData(), mesh.getIndices(), normal, {useRightHandedSystem: true});

        return mesh;
    }
    
    if((avatar === 'v14' && gender === 'male') || (avatar === 'v18' && gender === 'female') || ((avatar === 'v19' || avatar === 'v20' || avatar === 'v21') && gender === 'female') || (avatar === 'v15' && gender === 'male')){
        averageNormalsWithoutMerge();
    }

    const hairZDiffEnd = poseData.hairZDiffEnd;
    return {
        pivotForRotation,
        angleOfHeadRotationX,
        angleOfHeadRotationZ,
        bodyNeckPosition,
        bodyToHeadRatio,
        headVertexDiffs,
        xDiff,
        yDiff,
        zDiff,
        hairZDiffEnd,
        xAxisScallingFactor,
        zAxisScallingFactor,
    };
}

export default snapHeadToBodyNewPose;