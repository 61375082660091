import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import { store } from 'App';
import get from 'lodash/get';

const GA_TRACKING_ID = 'UA-248550925-1';
const GA4_TRACKING_ID = 'G-14BX6P0JT2';
const env = process.env.REACT_APP_DEBUG_MODE !== 'true' && "PROD";

class GoogleAnalytics {
  constructor() {
    if (env === 'PROD') {
      ReactGA.initialize(GA_TRACKING_ID);
      ReactGA4.initialize(GA4_TRACKING_ID);
    }
  }

  buttonClicked(category, btnName, label, value) {   
    const { avatarReducer } = store.getState()
    const userID = get(avatarReducer, 'userProfile.id', '')
    const labelText = userID ? `${userID} - ${label}` : label
    if (env === 'PROD') {
      ReactGA.event({
        category: category,
        action: btnName,
        label: labelText,
        value: value || 0
      });

      ReactGA4.event({
        category: category,
        action: btnName,
        label: labelText,
        value: value || 0
      });
    }
  }

  pageViewed(pageURL) {
    if (env === 'PROD') {
      ReactGA.pageview(pageURL);
    }
  }
}

export default new GoogleAnalytics();