import requestMaker from 'lib';

export const getFirebaseConfig = () => {
  const baseQuery = `/proxy/cust/px/gfc`;
  const params = {};
  const payload = {};
  return requestMaker(baseQuery, { params, payload });
};

export const setAuthCookie = (payload = {}) => {
  const baseQuery = '/proxy/cust/scks';
  const params = {};
  return requestMaker(baseQuery, { params, payload });
};

export const getAvatarUrl = (payload = {}) => {
  const baseQuery = '/proxy/cust/gau';
  const params = {};
  return requestMaker(baseQuery, { params, payload });
}

export const getSizeRecommendations = (payload = {}) => {
  const baseQuery = '/proxy/cust/gsr';
  const params = {};
  return requestMaker(baseQuery, { params, payload });
}

export const getClothUrl = (payload = {}) => {
  const baseQuery = '/proxy/cust/gcu';
  const params = {};
  return requestMaker(baseQuery, { params, payload });
}

export const getAllAssets = (payload = {}) => {
  const baseQuery = '/proxy/cust/gaa';
  const params = {};
  return requestMaker(baseQuery, { params, payload });
}

export const createToken = (data) => {
  const { uid, ...rest } = data;
  const payload = {
    uid: `${uid}`,
    ...rest
  }
  const baseQuery = '/proxy/cust/cct';
  const params = {};
  return requestMaker(baseQuery, { params, payload });
}

export const sendAnalytics = (payload = {}) => {
  const baseQuery = '/proxy/cust/sa';
  const params = {};
  return requestMaker(baseQuery, { params, payload });
}