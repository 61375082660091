import React, { useRef, useState } from "react";
import { useAuth } from "contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import Layout from "components/Layout";
import Loader from "components/Loader";
import Button from "components/Button";
import Header from "components/Header";
import InputField from "components/InputField";
import Title from "components/Header/Title";
import Checkbox from "components/Checkbox";
import LoginIcon from "assets/icons/dopplrLogo.png";
import GoogleIcon from "assets/icons/googleRound.png";
import get from 'lodash/get';
import "./Login.scss";

export default function Login(props) {
  const defaultFormData = {
    email: "",
    password: ""
  };
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login, signInWithGoogle, signInWithFacebook, signup, resetPassword } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(defaultFormData);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [checked, setChecked] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [info, setInfo] = useState('');

  const facebookSignInHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await signInWithFacebook();
      console.log(response);
      if (response && response.user && !response.user.isAnonymous) {
        props.onSuccess(response.user);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  const googleSignInHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await signInWithGoogle();
      if (response && response.user && !response.user.isAnonymous) {
        props.onSuccess(response.user);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  const onChangeHandler = (e, key) => {
    const updatedFormData = { ...formData };
    updatedFormData[key] = e.target.value;
    setFormData(updatedFormData);
    setError("");
  };

  const forgotPasswordHandler = async () => {
    try {
      const { email } = formData;
      setError('');
      const response = await resetPassword(email);
      setInfo(`Password reset mail has been sent to ${email}.`);
      setTimeout(() => {
        setInfo('');
        setShowForgotPassword(false);
      }, 3000);
    } catch (e) {
      console.log(e);
      const error = get(e, 'message', "Failed to sent email reset link. Please try again later.");
      setError(error);
    }
  };

  const createAccountHandler = async e => {
    try {
      const { email, password } = formData;
      setLoading(true);
      const response = await signup(email, password);
      setLoading(false);
    } catch (e) {
      console.log(e);
      const error = get(e, 'message', "Failed to create account.");
      setError(error);
      setLoading(false);
    }
  };

  const loginHandler = async e => {
    try {
      const { email, password } = formData;
      setLoading(true);
      const response = await login(email, password);
      setLoading(false);
    } catch (e) {
      console.log(e);
      const error = get(e, 'message', "Failed to create account.");
      setError(error);
      setLoading(false);
    }
  }

  const toggleSignUp = () => {
    setShowSignup(!showSignup);
  }

  const toggleForgotPassword = () => {
    setShowForgotPassword(!showForgotPassword);
  }

  const title = showForgotPassword ? "Forgot Password" : showSignup ? "SIGN UP" : "LOGIN"
  const bottomText = showForgotPassword ? "Go back to " : showSignup ? "Already a user ?" : "Need an account ?"
  return (
    <div className="Dopplr_LoginWrapper">
    <Layout className="LoginLayout" style={{ boxShadow: 'none', background: 'transparent' }}>
      {/* <Header>
        <BackButton />
        <Title title="Sign in with your gmail account to proceed" />
      </Header> */}
      {loading && <Loader className={"LoginContainer__Loader"} />}
      <div className="LoginContainer">
        <div className="DooplrIconContainer">
          <img src={LoginIcon} alt="Login" />
        </div>
        <div className="LoginForm">
          <div className="LoginForm__SignupOther">
            <div className="LoginForm__Title">{title}</div>
            {error && <div style={{ color: 'red', fontSize: '0.8em', textAlign: 'center' }}>{error}</div>}
            {info && <div style={{ color: 'green', fontSize: '0.8em', textAlign: 'center' }}>{info}</div>}
            {/* <div className="LoginContainer__LoginOptions">
              <Button
                type="grey-white"
                className="googleButton"
                onClick={signInWithGoogle}
              >
                Google
              </Button>
              <Button type="grey-white" className="facebookButton">
                Facebook
              </Button>
              <Button type="grey-white" className="twitterButton">
                Twitter
              </Button>
            </div> */}
            <div className="LoginContainer__LoginForm">
              <div>Email</div>
              <InputField
                className="LoginContainer__InputWrapper"
                onChange={(e) => onChangeHandler(e, 'email')}
                placeholder={"Email address"}
              />
              {!showForgotPassword && <>
                <div>Password</div>
                <InputField
                  type="password"
                  className="LoginContainer__InputWrapper"
                  onChange={(e) => onChangeHandler(e, 'password')}
                  placeholder={"Password"}
                  showToggle={true}
                />
                <div className="LoginContainer__ForgotPasswordWrapper">
                  {!showSignup && <div
                    className="LoginContainer__ForgotPasswordWrapper__ForgotPassword"
                    onClick={toggleForgotPassword}
                  >
                    Forgot password ?
                  </div>}
                </div>
              </>}
              {/* <div className="LoginContainer__Terms">
                <Checkbox
                  label={""}
                  isSelected={checked}
                  className="createProfileDeclaration"
                  onChange={() => {
                    console.log("checked", checked);
                    setChecked(!checked)
                  }}
                />
                <div>I have read and agree to the <span>Terms of service</span> and the <span>Privacy policy</span></div>
              </div> */}
            </div>
            {showForgotPassword && <Button
              type="black-white"
              text={"Send Password Reset Email"}
              onClick={forgotPasswordHandler}
            />}
            {!showForgotPassword && <>
              <Button
                type="black-white"
                text={showSignup ? "Sign Up" : "Sign In"}
                onClick={showSignup ? createAccountHandler : loginHandler}
              />
              <div className="LoginContainer__Text">
                <div>Or</div>
              </div>
              <Button
                type="google"
                onClick={googleSignInHandler}
              >
                <img src={GoogleIcon} alt="google icon" style={{ height: '1.2em', marginRight: '1em' }} />
                <span>Sign in with google</span>
              </Button>
              {/* <Button
                type="google"
                onClick={facebookSignInHandler}
              >
                <img src={GoogleIcon} alt="google icon" style={{ height: '1.2em', marginRight: '1em' }} />
                <span>Sign in with facebook</span>
              </Button> */}
            </>}
          </div>
          <div className="LoginContainer__BottomText">{bottomText}
            <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={showForgotPassword ? toggleForgotPassword : toggleSignUp}>
              {(showSignup || showForgotPassword) ? "LOGIN" : "SIGN UP"}
            </span>
          </div>
        </div>
      </div>
    </Layout >
    </div>
  );
}