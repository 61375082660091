import firebase from "firebase/app";
import "firebase/auth";

const prodConfig = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.AUTH_DOMAIN,
    projectId: process.env.PROJECT_ID,
    storageBucket: process.env.STORAGE_BUCKET,
    messagingSenderId: process.env.MESSAGING_SENDER_ID,
    appId: process.env.APP_ID,
    measurementId: process.env.MEASUREMENT_ID
};
const devConfig = {
    apiKey: "AIzaSyCcq8IRkwU9KfW26ypIPBWQ1PzfgWA_dV8",
    authDomain: "modeon-82814037.firebaseapp.com",
    projectId: "modeon-82814037",
    storageBucket: "modeon-82814037.appspot.com",
    messagingSenderId: "1092866982638",
    appId: "1:1092866982638:web:9cbe3438f399e4086f29ff",
    measurementId: "G-RWR0W7TSR4"
}
const isDebugMode = process.env.REACT_APP_DEBUG_MODE === 'true';
const config = isDebugMode ? devConfig : prodConfig;
const app = firebase.initializeApp(devConfig);


export const auth = app.auth();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.setCustomParameters({
    prompt: 'select_account'
});
export const facebookProvider = new firebase.auth.FacebookAuthProvider();
facebookProvider.addScope('email');
facebookProvider.setCustomParameters({ 'display': 'popup' });
export const twitterProvider = new firebase.auth.TwitterAuthProvider();
export default app;
