import React from "react";
import "./RangeSlider.scss";
import { styled } from '@mui/material/styles';
import Slider from "@mui/material/Slider";
import ToggleButton from "@mui/material/ToggleButton";
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    // margin: theme.spacing(0.5),
    lineHeight: 1,
    width: 40,
    textTransform: 'none',
    border: 'none',
    // border: 0,
    // borderLeft: '1px solid #000',
    padding: '0.2em',
    '&:first-of-type': {
      borderRight: '1px solid #000',
    },
    '&.Mui-selected': {
      fontWeight: 'bold',
      color: '#171717',
      background: 'none',
      '&:hover': {
        background: 'none',
      }
    }
    // '&.Mui-disabled': {
    //   border: 0,
    // }
  },
}));


const RangeSlider = ({
  min,
  max,
  value = '',
  name,
  unit,
  onChangeHandler,
  changeUnitType,
  Type1Unit,
  Type2Unit
}) => {
  const validateNumber = (e) => {
    let val = e.target.value;
    if (val >= max) {
      val = max;
    }
    onChangeHandler(val);
  }
  return (
    <div className="RangeSlider">
      <div className="RangeSlider__Label">
        <div>{name}</div>
        {/* <StyledToggleButtonGroup
            value={unit}
            onChange={changeUnitType}
            aria-label="text alignment"
            size="medium"
          >
            <ToggleButton value={Type1Unit}>
              <div>{Type1Unit}</div>
            </ToggleButton>
            <ToggleButton value={Type2Unit}>
              <div>{Type2Unit}</div>
            </ToggleButton>
          </StyledToggleButtonGroup> */}
      </div>
      <div className="RangeSlider__Input">
        <div className="RangeSlider__RangeWrapper">
          <Slider
            size="medium"
            defaultValue={min}
            aria-label="Medium"
            value={value}
            min={min}
            max={max}
            onChange={validateNumber}
            valueLabelDisplay="auto"
            sx={{
              color: "#171717",
            }}
          />
          <div className="RangeSlider__RangeWrapper__MinValue">{min}</div>
          <div className="RangeSlider__RangeWrapper__MaxValue">{max}</div>
        </div>
        {/* <div className="RangeSlider__InputWrapper"> */}
        {/* <div className="RangeSlider__Unit">{unit}</div> */}
        <TextField
          sx={{
            margin: '0 0.5em 0 0.5em',
            width: '5em',
            '& .MuiInputBase-input': {
              paddingLeft: '0.5em',
            },
            '& .MuiInputBase-root': {
              paddingRight: '0.5em',
              fontSize: '0.8em',
            },
          }}
          type="number"
          size={'small'}
          value={value || ''}
          onChange={validateNumber}
          InputProps={{
            endAdornment: <InputAdornment position="end" sx={{
              '& .MuiTypography-root': {
                fontSize: '1em'
              }
            }}>{unit}</InputAdornment>,
          }}
        />
        {/* <input
            type="number"
            className="RangeSlider__MeasurementInput"
            value={value || ''}
            onChange={validateNumber}
            max={max}
            min={min}
          /> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default RangeSlider;
