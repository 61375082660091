import React, { useCallback } from 'react';
import { styled } from '@mui/material/styles';
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import ComfortSignal from 'components/ComfortSignal';
import { toTitleCase } from 'utils';
import './HeatmapActions.scss';
import { getFitIndicatorValue, getFitIndicatorValueBH } from 'utils/heatmapUtils';
import { XIcon } from '@heroicons/react/outline';
import BottomBar from 'components/BottomBar/BottomBar';
import MixPanel from 'appAnalytics/mixPanel';
import { EVENT_ACTIONS, EVENT_CATEGORIES } from 'appAnalytics/eventCategories';

const HeatmapActions = ({
    showBoth,
    hideProductCounterPart,
    selectedProductPart,
    setSelectedProductPart,
    changeHeatmapSize,
    product_path,
    product_counter_part,
    garment_type,
    otherGarmentType,
    fit,
    fitInsights,
    userData,
    showHeatmap,
    toggleHeatMap,
    brand,
    ...comfortSignalProps }) => {
    const rootStyles = getComputedStyle(document.documentElement);
    const primaryColor = rootStyles.getPropertyValue('--primaryColor').trim();
    const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
        '&.MuiToggleButtonGroup-root': {
            boxShadow: '0px 4px 4px rgba(0,0,0,0.25)',
            borderRadius: '25px',
            background: '#EEEEEE',
        },
        '& .MuiToggleButtonGroup-grouped': {
            // margin: theme.spacing(0.5),
            lineHeight: 1,
            textTransform: 'none',
            borderRadius: '25px',
            // border: '1px solid',
            // border: 0,
            // borderLeft: '1px solid #000',
            padding: '0.5em',
            fontSize: '0.8em',
            background: '#EEEEEE',
            // '&:first-of-type': {
            //   borderRight: '1px solid #000',
            // },
            '&:hover': {
                // background: '#2C2C2C',
                background: '#EEEEEE',
                color: '#000'
            },

            '&.MuiButtonBase-root': {
                width: '6em',
                borderRight: '0',
            },
            '&.Mui-selected': {
                color: '#FFF',
                background: primaryColor,
                borderRadius: '25px',
                position: 'relative',
                zIndex: 1,
                '&:hover': {
                    // background: '#2C2C2C',
                    background: primaryColor,
                    color: '#FFF'
                }
            },
            // '&.Mui-disabled': {
            //   border: 0,
            // }
        },
    }));

    const { textColorStyle, showHeatmapInfo, selectedHeatmapSize, toggleHeatMapInfo } = comfortSignalProps;
    const getSelectedHeatmapSize = (productId, fit, heatmapSize) => {
        const fitting = fit[productId] || {};
        switch (heatmapSize) {
            case 'minus1': {
                return fitting['tightFit'];
            }
            case 'plus1': {
                return fitting['looseFit'];
            }
            default: {
                return fitting['normalFit'];
            }
        }
    }

    const getFitIndicatorValues = (fitInsights, userData, selectedProductPart, fit, brand) => {
        let fitIndicators = {};
        let garmentType = product_path === selectedProductPart ? garment_type : otherGarmentType;
        ['', 'plus1', 'minus1'].map(heatmapSize => {
            let value = 0;
            if (brand === "beinghuman" || brand === "beinghumans" || brand == "rarerabbit" || brand == "burgerbae" || brand == "superkicks" || brand == "fef" || brand == "fashor") {
                const plotShare = fit[selectedProductPart]?.plotShare || 1;
                value = getFitIndicatorValueBH(fitInsights, userData, garmentType, heatmapSize, plotShare);
            } else {
                let fitIndicationValue = getFitIndicatorValue(fitInsights, userData, garmentType, heatmapSize);
                value = ((fitIndicationValue + 1) / 2) * 100;
            }
            const size = getSelectedHeatmapSize(selectedProductPart, fit, heatmapSize);
            if (size) {
                fitIndicators[heatmapSize] = {
                    value: value,
                    size,
                };
            }
        });
        return fitIndicators;
    }
    const fitIndicators = getFitIndicatorValues(fitInsights, userData, selectedProductPart, fit, brand);
    return (<>
        {showHeatmapInfo && <div className={`Dopplr__HeatmapLegendContainer__InformationOverlay`} onClick={() => toggleHeatMapInfo(!showHeatmapInfo)}></div>}
        <BottomBar keyName={'heatmap'} className='Dopplr_HeatmapActions'>
            <div className='Dopplr_HeatmapActions__ToggleButton' onClick={() => toggleHeatMap()}>
                <XIcon className={`${showHeatmap ? 'show' : ''}`} style={{ width: '60%' }} />
            </div>
            <div className='Dopplr_HeatmapActions__Title'>This is how different sizes will feel</div>
            {showBoth && !hideProductCounterPart && <div className="Dopplr_ToggleProductPart">
                <StyledToggleButtonGroup
                    exclusive
                    onChange={(e, value) => {
                        if (value && value !== selectedProductPart) {
                            MixPanel.buttonClicked(EVENT_CATEGORIES.HEATMAP, EVENT_ACTIONS.HEATMAP_PRODUCT_TOGGLE, { selectedTab: value === product_path ? toTitleCase(garment_type) : toTitleCase(otherGarmentType) });
                            setSelectedProductPart(value);
                            changeHeatmapSize('');
                        }
                    }}
                    aria-label="text alignment"
                    size="medium"
                    value={selectedProductPart}
                >
                    <ToggleButton value={product_path}>
                        <div>{toTitleCase(garment_type)}</div>
                    </ToggleButton>
                    <ToggleButton
                        value={product_counter_part}>
                        <div>{toTitleCase(otherGarmentType)}</div>
                    </ToggleButton>
                </StyledToggleButtonGroup>
            </div>}
            <ComfortSignal
                // normalisedfitIndicationValue={normalisedFitIndicationValue}
                fitIndicators={fitIndicators}
                textColorStyle={textColorStyle}
                selectedProductPart={selectedProductPart}
                selectedHeatmapSize={selectedHeatmapSize}
                showHeatmapInfo={showHeatmapInfo}
                toggleHeatMapInfo={toggleHeatMapInfo}
                changeHeatmapSize={changeHeatmapSize}
                selectedTab={selectedProductPart === product_path ? toTitleCase(garment_type) : toTitleCase(otherGarmentType)}
                plotRange={fit?.[selectedProductPart]?.plotRange}
            />
        </BottomBar></>
    )
}

export default HeatmapActions;
