import React from 'react';
import CloseButton from 'components/CloseButton';
import PropTypes from 'prop-types';
import './HalfCard.scss';
import { XIcon } from '@heroicons/react/outline';

const stopPropagation = e => e.stopPropagation();

const HalfCard = props => {
  const {
    showHeader,
    title,
    name,
    showCloseButton,
    visible,
    whiteBackground,
    zIndex,
    wrapperClassName,
    children,
    closeOnBackgroundClick,
    onClose,
    headerClassName,
    headerTitleClassName,
    bodyClassName,
    fullHeight = false,
  } = props;

  const handlePopupClose = (e) => {
    e.stopPropagation();
    if (typeof onClose !== 'function') {
      return;
    }
    if (closeOnBackgroundClick) {
      onClose(e);
    }
  };
  return (
    <div
      className={`HalfCard__Container ${visible ? 'show' : 'hidden'}`}
      style={{ zIndex }}
      onClick={handlePopupClose}
    >
      <div className={`HalfCard__Container__Wrapper ${fullHeight ? '' : 'animate'} ${whiteBackground ? 'white-bg' : ''} ${wrapperClassName}`} onClick={stopPropagation}>
      {showCloseButton && (
        <div className='HalfCard__Container__ToggleButton' onClick={onClose}>
          <XIcon className='show' style={{ width: '60%' }} />
        </div>
      )}
        {showHeader && (
          <div className={`HalfCard__Container__Wrapper__Header ${headerClassName}`}>
            <div className={`HalfCard__Container__Wrapper__Header__Title ${headerTitleClassName}`}>
              {title}
            </div>
          </div>
        )}
        <div
          className={`HalfCard__Container__Wrapper__Body ${bodyClassName ? bodyClassName : ''}`}
          style={{
            height: showHeader ? `calc(100%-60px)` : '100%',
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

HalfCard.propTypes = {
  showHeader: PropTypes.bool,
  title: PropTypes.string,
  showCloseButton: PropTypes.bool,
  visible: PropTypes.bool,
  whiteBackground: PropTypes.bool,
  children: PropTypes.node,
  closeOnBackgroundClick: PropTypes.bool,
  onClose: PropTypes.func,
  headerClassName: PropTypes.string,
  headerTitleClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
};

HalfCard.defaultProps = {
  showHeader: false,
  title: '',
  showCloseButton: false,
  visible: false,
  whiteBackground: false,
  children: null,
  closeOnBackgroundClick: false,
  onClose: () => { },
  headerClassName: '',
  headerTitleClassName: '',
  bodyClassName: '',
};

export default HalfCard;
