import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import get from "lodash/get";
import { actionsCreator } from "redux/actions/actionsCreator";
import { getFileExtension } from "utils";
import { userServiceAPI } from "api";
import Header from "components/Header";
import Title from "components/Header/Title";
import BackButton from "components/BackButton";
import Layout from "components/Layout";
import CloseButton from "components/CloseButton";
import Loader from "components/Loader";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import "./AvatarForm.scss";
import Button from "components/Button";
import HalfCard from "components/HalfCard";
import RangeSlider from "components/RangeSlider";
import UploadImage from "containers/uploadImage/UploadImage";
import Camera from "assets/icons/camera.png";
import Edit from "assets/icons/edit.png";
import { getURLParams } from "utils";

const mapStateToProps = state => {
  const imageUrl = get(state, "avatarReducer.imageUrl", "");
  const selfie = get(state, "avatarReducer.selfie", "");
  return {
    imageUrl,
    selfie,
    userProfile: get(state, "avatarReducer.userProfile", {})
  };
};

const AvatarForm = (props) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const { imageUrl = "", selfie, userProfile } = useSelector(mapStateToProps);
  const { gender, name = "", height, weight, userAvatar = {} } = userProfile;
  const { chest, hip, waist } = userAvatar || {};
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const defaultBasicInfo = {
    name: name,
    gender: gender || "male"
  };
  const [basicInfo, setBasicInfo] = useState(defaultBasicInfo);
  const UNITS_MAP = {
    //weight - kg, rest - cm
    metric: {
      weight: {
        unit: "kg",
        min: 38,
        max: 200,
        name: "Weight"
      },
      height: {
        unit: "cm",
        min: 150,
        max: 200,
        name: "Height"
      },
      hip: {
        unit: "cm",
        min: 60,
        max: 130,
        name: "Hips"
      },
      waist: {
        unit: "cm",
        min: 60,
        max: 130,
        name: "Waist"
      },
      chest: {
        unit: "cm",
        min: 60,
        max: 130,
        name: "Chest"
      }
    },
    //weight - lbs, rest - in
    imperial: {
      weight: {
        unit: "lbs",
        min: 84,
        max: 441,
        name: "Weight"
      },
      height: {
        unit: "in",
        min: 59,
        max: 79,
        name: "Height"
      },
      hip: {
        unit: "in",
        min: 24,
        max: 51,
        name: "Hips"
      },
      waist: {
        unit: "in",
        min: 24,
        max: 51,
        name: "Waist"
      },
      chest: {
        unit: "in",
        min: 24,
        max: 51,
        name: "Chest"
      }
    }
  };
  const [showMeasurementModal, toggleMeasurementModal] = useState(false);
  const [measurementType, setMeasurementType] = useState("metric");
  const defaultMeasurements = {
    chest: {
      ...UNITS_MAP[measurementType].chest,
      value: chest || 76
    },
    waist: {
      ...UNITS_MAP[measurementType].waist,
      value: waist || 73
    },
    hip: {
      ...UNITS_MAP[measurementType].hip,
      value: hip || 96
    },
    weight: {
      ...UNITS_MAP[measurementType].weight,
      value: weight || 38
    },
    height: {
      ...UNITS_MAP[measurementType].height,
      value: height || 150
    }
  };
  const [measurements, setMeasurements] = useState(defaultMeasurements);

  const onCloseClick = e => {
    dispatch(actionsCreator.SET_AVATAR_IMAGE(""));
    dispatch(actionsCreator.SET_SELFIE_FILE(""));
  };

  const onChangeHandler = (val, key) => {
    setErrorMessage('');
    let updatedMeasurements = { ...measurements };
    updatedMeasurements[key].value = Number(val);
    setMeasurements(updatedMeasurements);
  };

  const onBasicInfoChangeHandler = (e, key) => {
    const updatedBasicInfo = { ...basicInfo };
    updatedBasicInfo[key] = e.target.value;
    setBasicInfo(updatedBasicInfo);
  };

  const uploadImage = () => {
    if (ref && ref.current) {
      ref.current.click();
    }
  };
  const getJobStatus = async jobId => {
    try {
      setLoader(true);
      let response = await userServiceAPI.getJobStatus({ jobId });
      let status = get(response, "data.job.jobState");
      while (status === "INPROGRESS") {
        const timer = ms => new Promise(res => setTimeout(res, ms));
        await timer(5000);
        response = await userServiceAPI.getJobStatus({ jobId });
        status = get(response, "data.job.jobState");
      }
      if (status === "COMPLETED") {
        const success = get(response, "data.job.result.success", false);
        if (success) {
          dispatch(actionsCreator.FETCH_AVATAR());
          setLoader(true);
          const params = getURLParams();
          const redirectionUrl =
            params && params.old ? "/dopplr-avatar-old" : "/dopplr-avatar";
          try {
            let response = await userServiceAPI.getAvatarData();
            let userData = response.data;
            let imageUrl = get(userData, 'userProfile.userAvatar.imageUrl', '');
            dispatch(actionsCreator.SET_LOADED_SCENE(null));
            dispatch(actionsCreator.SET_AVATAR_DATA(userData));
            dispatch(actionsCreator.SET_AVATAR_IMAGE(imageUrl));
            props.onSuccess(get(userData, 'userProfile', {}));
            setLoader(false);
          } catch (e) {
            setLoader(false);
          }
        } else {
          const failureMessage = get(
            response,
            "data.job.result.failureMessage",
            "Something went wrong"
          );
          console.error(failureMessage);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };
  const switchMeasurementType = () => {
    const switchedMeasurement = measurementType === "metric" ? "imperial" : "metric";
    let updatedMeasurements = { ...measurements };
    Object.keys(updatedMeasurements).map(measurementParam => {
      const { unit, min, max } = UNITS_MAP[switchedMeasurement][
        measurementParam
      ];
      const { value } = updatedMeasurements[measurementParam];
      let mf = switchedMeasurement === "metric" ? 2.54 : 1 / 2.54; //inch <-> cm;
      if (measurementParam === "weight") {
        mf = switchedMeasurement === "metric" ? 1 / 2.20462 : 2.20462; //kg <-> pounds
      }
      updatedMeasurements[measurementParam] = {
        ...updatedMeasurements[measurementParam],
        unit,
        min,
        max,
        value: Math.round(value * mf)
      };
    });
    setMeasurementType(switchedMeasurement);
    setMeasurements(updatedMeasurements);
  };
  const createAvatarHandler = async () => {
    const { name = "", gender } = basicInfo;
    let { weight, height, hip, chest, waist } = measurements;
    const invalidKey = Object.keys(measurements).find(measurement => measurements[measurement].value < measurements[measurement].min || measurements[measurement].value > measurements[measurement].max);
    if (invalidKey) {
      setErrorMessage(`${invalidKey} value should be between ${measurements[invalidKey].min}${measurements[invalidKey].unit} and ${measurements[invalidKey].max}${measurements[invalidKey].unit}`)
    } else if (!imageUrl) {
      setErrorMessage("Please upload your selfie to proceed.")
    } else {
      try {
        let weightVal = weight.value;
        let heightVal = height.value;
        let hipVal = hip.value;
        let chestVal = chest.value;
        let waistVal = waist.value;
        if (measurementType === "imperial") {
          weightVal = Math.round(weightVal / 2.20462);
          heightVal = Math.round(heightVal * 2.54);
          hipVal = Math.round(hipVal * 2.54);
          chestVal = Math.round(chestVal * 2.54);
          waistVal = Math.round(waistVal * 2.54);
        }
        const postData = new FormData();
        postData.append("selfie", selfie);
        postData.append("weight", weightVal);
        postData.append("height", heightVal);
        postData.append("hip", hipVal);
        postData.append("chest", chestVal);
        postData.append("waist", waistVal);
        postData.append("gender", gender);
        postData.append("name", name);
        postData.append("env", "qaa");
        const response = await userServiceAPI.createAvatar(postData);
        const jobId = get(response, "data.jobId");
        if (jobId) {
          getJobStatus(jobId);
        }
      } catch (e) {
        setErrorMessage('Something went wrong. Please try again later.')
      }
    }
    // const fileExt = getFileExtension(file.name);
    // const filePath = `UserUploadedPrescription/${defaultProfileId}/`;
    // const fileName = `${filePath + "defaultProfileId"}-${Date.now()}${fileExt}`;

  };
  return (
    <Layout className={"avatarFormWrapper"}>
      <Header>
        <BackButton onClick={props.backHandler} />
        <Title title="Personalise Avatar" />
      </Header>
      {loader && (
        <div
          style={{
            background: "rgba(0,0,0,0.5)",
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: "10",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0
          }}
        >
          <Loader />
        </div>
      )}
      <div className="AvatarForm">
        <div className="AvatarForm__Title">Basic Information</div>
        <div className="uploadedImageContainer">
          {imageUrl ? (
            <div className="uploadedImage">
              <img src={imageUrl} alt="Selfie" className="uploaded-image" />
              <CloseButton className={"removeIcon"} onClick={onCloseClick} />
            </div>
          ) : (
            <div className="AvatarForm__ImageUpload" onClick={uploadImage}>
              <img src={Camera} alt="Upload" />
            </div>
          )}
        </div>
        {/* <div className="AvatarForm__InputWrapper">
          <input
            type="text"
            value={basicInfo.name}
            placeholder="Enter your name*"
            onChange={e => onBasicInfoChangeHandler(e, "name")}
          />
        </div> */}
        <div className="AvatarForm__InputWrapper">
          <TextField
            required
            fullWidth
            id="outlined-required"
            label="Name"
            value={basicInfo.name}
            size="small"
            onChange={e => onBasicInfoChangeHandler(e, "name")}
            sx={{
              padding: "0",
              "& label.Mui-focused": {
                color: "#171717"
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#171717"
              },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#171717"
                }
              }
            }}
          />
        </div>
        <FormControl sx={{ margin: "1em 0" }}>
          <FormLabel
            id="gender-row-radio-buttons-group-label"
            sx={{
              padding: "0 1em",
              color: "#000",
              "&.Mui-focused": {
                color: "#000"
              }
            }}
          >
            Gender
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="gender-row-radio-buttons-group-label"
            name="gender-row-radio-buttons-group"
            value={basicInfo.gender}
            onChange={e => onBasicInfoChangeHandler(e, "gender")}
            sx={{ padding: "0 1em" }}
          >
            <FormControlLabel
              value="female"
              control={
                <Radio
                  sx={{
                    // color: "#171717",
                    "&.Mui-checked": {
                      color: "#171717"
                    }
                  }}
                />
              }
              label="Female"
            />
            <FormControlLabel
              value="male"
              control={
                <Radio
                  sx={{
                    // color: "#171717",
                    "&.Mui-checked": {
                      color: "#171717"
                    }
                  }}
                />
              }
              label="Male"
            />
          </RadioGroup>
        </FormControl>
        <div className="AvatarForm__Actions">
          {/* <div>Choose how you want to create your avatar</div> */}
          <div className="AvatarForm__BodyStatistics">
            <div
              className="AvatarForm__BodyStatistics__Button"
              onClick={() => {
                setErrorMessage('');
                toggleMeasurementModal(!showMeasurementModal)
              }}
            >
              <img src={Edit} alt="edit" />
              <span>Add Body Statistics</span>
              {/* <button onClick={onclick}>Pay</button> */}
            </div>
          </div>
        </div>
        <HalfCard
          key={"measurements"}
          visible={showMeasurementModal}
          bodyClassName={"MeasurementsModal"}
          onClose={() => toggleMeasurementModal(!showMeasurementModal)}
          name="MeasurementsModal"
          showHeader={true}
          showCloseButton={true}
          title={"Body Measurements"}
        >
          <div className="BodyMeasurements">
            {Object.keys(measurements).map(measurement => {
              const stat = measurements[measurement];
              return (
                <RangeSlider
                  key={measurement}
                  {...stat}
                  changeUnitType={switchMeasurementType}
                  metricUnit={UNITS_MAP["metric"][measurement].unit}
                  imperialUnit={UNITS_MAP["imperial"][measurement].unit}
                  measurement={measurement}
                  onChangeHandler={val => onChangeHandler(val, measurement)}
                />
              );
            })}
            <div className="BodyMeasurements__Error">{errorMessage}</div>
            <Button
              type="yellow-black"
              className="CreateAvatar"
              text="Create Avatar"
              onClick={createAvatarHandler}
            />
          </div>
        </HalfCard>
      </div>
      <UploadImage ref={ref} />
    </Layout>
  );
};

export default AvatarForm;
