import React from "react";
import "./BodyStats.scss";

export const BodyStats = () => {
  return (
    <div className="BodyStats">
      <div className="BodyStats__Title">Body Statistics</div>
      <div className="BodyStats__Description">
        <div className="BodyStats__Section__Title">Top <span>(Measurements in inches)</span></div>
        <div className="BodyStats__Description__Row">
          <div className="BodyStats__Description__Label">Shoulder</div>
          <div className="BodyStats__Description__Value">13"</div>
        </div>
        <div className="BodyStats__Description__Row">
          <div className="BodyStats__Description__Label">Chest</div>
          <div className="BodyStats__Description__Value">34"</div>
        </div>
        <div className="BodyStats__Section__Title">Bottom <span>(Measurements in inches)</span></div>
        <div className="BodyStats__Description__Row">
          <div className="BodyStats__Description__Label">Hip</div>
          <div className="BodyStats__Description__Value">13"</div>
        </div>
        <div className="BodyStats__Description__Row">
          <div className="BodyStats__Description__Label">Waist</div>
          <div className="BodyStats__Description__Value">34"</div>
        </div>
        <div className="BodyStats__Description__Row">
          <div className="BodyStats__Description__Label">Length</div>
          <div className="BodyStats__Description__Value">34"</div>
        </div>
      </div>
    </div>
  );
};

export default BodyStats;
