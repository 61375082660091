import React from 'react'
import HalfCard from "components/HalfCard";
import Button from "components/Button";
import "./ShareAvatar.scss";

const ShareAvatar = ({ showShareAvatar, setShareAvatar, shareAvatarImage = '', productTitle }) => {

    const confirmHandler = async () => {
        const blob = await (await fetch(shareAvatarImage)).blob();
        const file = new File([blob], 'dopplr.png', { type: blob.type });
        navigator.share({
            title: 'Hello',
            text: 'Check out this image!',
            files: [file],
        })
    }
    const downloadBase64File = () => {
        const linkSource = `${shareAvatarImage}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = "dopplr.png";
        downloadLink.click();
    }
    return (
        <HalfCard key={"confirm"}
            visible={showShareAvatar}
            bodyClassName={"Dopplr__ShareAvatar__Halfcard"}
            onClose={() => setShareAvatar(!showShareAvatar)}
            name="Share Avatar"
            showHeader={false}
            whiteBackground={true}
            zIndex={1500}
            showCloseButton={true}
            title={""}
        >
            <div className='Dopplr__ShareAvatar'>
                {shareAvatarImage && <div className='Dopplr__ShareAvatar__Content'>
                    <div className='Dopplr__ShareAvatar__ImageWrapper'>
                        <img src={shareAvatarImage} alt="share avatar" />
                    </div>
                </div>}
                <div className='Dopplr__ShareAvatar__Actions'>
                    <Button
                        type="white-black"
                        onClick={downloadBase64File}
                    >
                        Save Image
                    </Button>
                    <Button
                        type="black-white"
                        onClick={confirmHandler}
                    >
                        Share
                    </Button>
                </div>
            </div>
        </HalfCard>
    )
}

export default ShareAvatar;