import React from 'react';
import PropTypes from 'prop-types';
import './Layout.scss';
import { isTouchFriendly } from 'utils';

const Layout = ({ children, className, onClick, setRef, style }) => {
  return (
    <div style={style} id='dopplr-container' className={`Dopplr_Layout ${isTouchFriendly  ? 'mobile': 'desktop'} ${className ? className : ''}`} ref={setRef} onClick={onClick}>
      {children}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

Layout.defaultProps = {
  children: null,
};

export default Layout;
