import React from 'react'
import Button from 'components/Button';
import Avatar from '../Avatar';
import './ChooseAvatar.scss';

const ChooseAvatar = ({clickHandler}) => {
    return (
        <div className="ChooseAvatar">
            <div className="ChooseAvatar__Title">
                Choose your avatar
            </div>
            <div className="ChooseAvatar__AvatarContainer">
                <Avatar />
                <Avatar />
            </div>
            <div className="ChooseAvatar__ActionContainer">
            <Button type="yellow-black" className="MakeMyAvatar" text="Make my avatar" onClick={clickHandler} />
            </div>
        </div>
    )
}

export default ChooseAvatar
