import React, { useState } from 'react'
import { motion } from 'framer-motion';
import './CameraActionBar.scss';
import { CAMERA_MODE, CAMERA_TYPE } from 'configs/RendererConfig';
import { VideoCameraIcon, XIcon } from '@heroicons/react/outline';
import { VideoCameraIcon as VFill } from '@heroicons/react/solid';
import Top from 'assets/icons/Top.svg';
import Bottom from 'assets/icons/Bottom.svg';
import Shoes from 'assets/icons/Shoes.svg';
import CameraMode from 'assets/icons/CameraModeDark.svg';
import CameraModeSelected from 'assets/icons/CameraModeLight.svg';
import Body from 'assets/icons/Body.svg';
import { EVENT_ACTIONS, EVENT_CATEGORIES } from 'appAnalytics/eventCategories';
import MixPanel from 'appAnalytics/mixPanel';

const CAMERAS = [
  {
    name: 'Top',
    icon: Top,
    iconFill: Top,
    tooltip: 'Top View',
    type: CAMERA_TYPE.TOP,
  },
  {
    name: 'Bottom',
    icon: Bottom,
    iconFill: Bottom,
    tooltip: 'Bottom View',
    type: CAMERA_TYPE.BOTTOM,
  },
  {
    name: 'Body',
    icon: Body,
    iconFill: Body,
    tooltip: 'Body View',
    type: CAMERA_TYPE.BODY,
  },
  {
    name: 'Feet',
    icon: Shoes,
    iconFill: Shoes,
    tooltip: 'Feet View',
    type: CAMERA_TYPE.SHOES,
  },
];
const CameraActionBar = (props) => {
  const { setCameraMode, cameraMode, isCameraActionOpened = false, setCameraActionOpened, viewMode, eventCategory } = props;
  return (
    <>
      <div className={`Dopplr_CameraActionBar ${viewMode ? 'hide' : ''}`}>
        <motion.div
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.97 }}
          animate={isCameraActionOpened ? "open" : "closed"}
          transition={{
            type: "spring",
            bounce: 0,
            duration: 0.5
          }}
          onClick={() => {
            if (!isCameraActionOpened) {
              props.closeActions();
              MixPanel.buttonClicked(eventCategory, EVENT_ACTIONS.SHOW_CAMERA_MODE);
            } else {
              MixPanel.buttonClicked(eventCategory, EVENT_ACTIONS.HIDE_CAMERA_MODE);
            }
            setCameraActionOpened(!isCameraActionOpened)
          }}
          className={`Dopplr_CameraActionBar__PrimaryAction ${isCameraActionOpened ? 'selected' : ''}`}
        >
          {/* {isCameraActionOpened ? <VFill className={`mainIcon`} /> : <VideoCameraIcon className='mainIcon' />} */}
          <img alt="Camera mode" src={isCameraActionOpened ? CameraModeSelected : CameraMode} className={`mainIcon`} />
        </motion.div>
      </div>
      <div className={`Dopplr__CameraListWrapper ${isCameraActionOpened ? ' show' : ''}`}>
        <div className='Dopplr__CameraListWrapper__ToggleButton' onClick={() => {
          MixPanel.buttonClicked(eventCategory, EVENT_ACTIONS.HIDE_CAMERA_MODE);
          setCameraActionOpened(!isCameraActionOpened)}
        }>
          <XIcon className={`${isCameraActionOpened ? 'show' : ''}`} style={{ width: '60%' }} />
        </div>
        <div className='Dopplr__CameraListWrapper__Title'>Look from different angles</div>
        <div className='Dopplr__CameraListWrapper__List'>
          {CAMERAS.map(cameraObj => {
            const { icon, iconFill, type } = cameraObj;
            const selected = cameraMode?.type === type;
            return <div key={type} className={`Dopplr__CameraListWrapper__List__Skybox__Item ${selected ? ' selected' : 'overlay'}`} onClick={() => {
              MixPanel.buttonClicked(eventCategory, EVENT_ACTIONS.CAMERA_MODE_CHANGE, { cameraType: type} );
              setCameraMode(CAMERA_MODE[type])
            }}>
              <img className='Dopplr__CameraListWrapper__List__Skybox__Thumbnail' src={selected ? iconFill : icon} alt="" />
            </div>
          })}
        </div>
      </div>
    </>
  );
}
export default CameraActionBar;