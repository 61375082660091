import { combineReducers } from 'redux';
import avatarReducer from './avatarReducer';
import loaderReducer from './loaderReducer';
// import { firebaseReducer } from 'react-redux-firebase';

export default combineReducers({
  avatarReducer,
  loaderReducer
  // firebaseReducer
});
